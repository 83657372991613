
// export default Home;
import React,{useRef} from "react";
import { MDBBtn } from 'mdb-react-ui-kit';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBRipple,
} from "mdb-react-ui-kit";
 import smartreportBanner from "../img/technologies/Infographic/patient eligibility verification bg remove.png";
 import smartreportBanner1 from "../img/technologies/Infographic/Patient eligibility verificaition.png"
 import comunicate from "../img/in network out of network.svg";
 import digitalpayment from "../img/procedure pre authorization.svg";
 import textreminder from "../img/patient financial.svg";
 import   "../css/splitsetnew1.css";
 import "../css/servicesfont.css";
const Home = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick1 = () => {
    ref1.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick2 = () => {
    ref2.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <MDBContainer fluid className="bg-light p-0">
      <div className="container py-5">

<div className="button-overlay2 mt-4 mb-4 d-none d-lg-block" style={{paddingLeft:'120px'}}>
<img
                className=""
                src={smartreportBanner}
                alt="Patient Eligibility Insurance Verification"
                
                style={{ borderRadius: "10px",width:'100%'}}
              />
 <MDBBtn color='link' rippleColor='dark' onClick={handleClick} style={{ fontSize: '14px',color:'white',fontFamily:'Alegreya Sans',fontWeight:'bold', backgroundColor: 'transparent'}} className="button-overlay2 button">
      Patient eligiblity and Benefit Verification
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick1} style={{ fontSize: '14px',color:'white',width:'365px',marginTop:'315px',height:'85px',left:'85%',fontFamily:'Alegreya Sans',fontWeight:'bold', backgroundColor: 'transparent'}}>
     Procedure pre-Authorization
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick2} style={{ fontSize: '14px',color:'white',marginTop:'635px',width:'365px',left:'85%',height:'85px',fontFamily:'Alegreya Sans',fontWeight:'bold', backgroundColor: 'transparent'}}>
     patient financial verification
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' style={{ fontSize: '14px',color:'white',marginTop:'305px',width:'200px',left:'31%',height:'65px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}}>
     patient Eligibility verification
    </MDBBtn>
</div>

<div className="button-overlay2 mt-4 mb-4 d-lg-none">
<img
                className=""
                src={smartreportBanner1}
                alt="Patient Eligibility Insurance Verification"
                style={{ borderRadius: "10px",width:'100%'}}
              />
</div>

      <div ref={ref} className="card mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5 p-4 d-md-none">
            <div className="row">
            <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2"  style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>In-Network/Out of Network Patient Eligibility and Benefits Verification</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Most of the patients are not entitled to out of network advantages. This leads to a major loss for the provider even after rendering the standard service. AcerHealth overcomes this drawback by often pursuing benefits verification for all the patients. We complete the patient benefits verification 2 days prior to the date of service. Some of the vital information we gather includes type of plan, covered services, out-of-pocket services, copays and coordination of benefits.
                </p>
              </div>
              <div class="col-md-4 text-center mt-4">
              <img src={comunicate} className="img-fluid" width={350} height={250} alt="Patient Eligibility"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 p-4 d-none d-md-block">
            <div className="row">
              <div class="col-md-4 text-center mt-4">
              <img src={comunicate} className="img-fluid" width={350} height={250} alt="Patient Eligibility"/>
              </div>
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2"  style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>In-Network/Out of Network Patient Eligibility and Benefits Verification</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Most of the patients are not entitled to out of network advantages. This leads to a major loss for the provider even after rendering the standard service. AcerHealth overcomes this drawback by often pursuing benefits verification for all the patients. We complete the patient benefits verification 2 days prior to the date of service. Some of the vital information we gather includes type of plan, covered services, out-of-pocket services, copays and coordination of benefits.
                </p>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div ref={ref1} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2"  style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Procedure Pre-Authorization</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                The tremendous amount of your time and resources endowed in persuading insurance to cover medicine or procedure is an expensive and annoying process. It usually causes a detraction in rendering the service to the patient. To overcome this drawback we provide the task in-house for you. We scale back the burden on you by finishing procedure authorizations for all patients and reporting the authorization details to you 5 days prior to the date of service.

                </p>
              </div>
              <div class="col-md-4 text-center ">
              <img src={digitalpayment} className="img-fluid" width={350} height={250} alt="Procedure pre Authorization"/>
              </div>
            </div>
          </div>
          </div>
   </div>
     
      <div ref={ref2} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5 d-md-none">
            <div className="row">
            <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2"  style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Patient Financial Responsibility Verification</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                We assist you to control your patient receivables by generating and sharing patient liability reports often. 
                This methodology helps us track and study outstanding balances and helps your center push for unfinished balances 
                from the patients at the time of the next appointment. This practice controls the amount the patient owed and keeps the account clean.
                </p>
              </div>
            <div class="col-md-4 text-center ">
              <img src={textreminder} className="img-fluid" width={350} height={250} alt="patient financial Responsibilty verification"/>
              </div>
              
            </div>
          </div>
          <div className="col-lg-12 mb-5 d-none d-md-block">
            <div className="row">
            <div class="col-md-4 text-center ">
              <img src={textreminder} className="img-fluid" width={350} height={250} alt="patient financial Responsibilty verification"/>
              </div>
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2"  style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Patient Financial Responsibility Verification</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                We assist you to control your patient receivables by generating and sharing patient liability reports often. 
                This methodology helps us track and study outstanding balances and helps your center push for unfinished balances 
                from the patients at the time of the next appointment. This practice controls the amount the patient owed and keeps the account clean.
                </p>
              </div>
              
            </div>
          </div>
          </div>
      </div>
    </div>
    </MDBContainer>
  );
};

export default Home;
