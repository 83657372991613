import React, { useEffect }  from 'react';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText,
  MDBCardOverlay,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import Specialities from '../components/home_specialities';
import Parallax from '../components/home_parallax';
import RCMServices from '../components/home_rcmservices';
import Contact from '../components/home_contact';
import Reviews from '../components/home_testimonials';
import CaseStudy from '../components/home_casestudy';
import Banner from '../components/home_banner';
import Statistics from '../components/home_statistics';
import Service from '../components/home_services';
import Band1 from '../components/home_band2';
import Certificate from '../components/certificate';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');


import { Helmet } from 'react-helmet';
const Home = () => {
  
  // change title of page
  useEffect(() => {
    document.title = 'AcerHealth Medical Billing | RCM | Services USA';

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);
  return (
    <>
     <Helmet>
        <link rel="canonical" href="https://acerhealth.com/" />
        <meta name="description" content="Experience our top noch medical billing experts are specific, mesurable and aligned with your practice's financial goals. Shedule your appoinment today!" />
        <meta name="keywords" content="Medical Billing" />
      </Helmet>
      <Banner />
      <Service />
      <RCMServices />
     
      {/* <CaseStudy /> */}
      
      <Parallax />
      <Statistics />
      {/* <Products /> */}
      <Specialities />
      <Reviews />
      <Band1 />
      <Contact />
      <Certificate />
    </>
  );
};

export default Home;