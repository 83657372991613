import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/breadcrumbs";
import Banner from "../../components/img_bannerblogs";
import RCMcy from "../../img/Blogbanners1/test/detailsaboutpatientsareprotectedbyacer1.jpg";
import BlogImg6 from "../../img/Blogbanners1/how to accelerate claims processing1.jpg";
import BlogImg13 from "../../img/Blogbanners1/medical billing trends to watch 20231.jpg";
import BlogImg8 from "../../img/Blogbanners1/techniques to increase patient engagement1.jpg";
import BlogImg9 from "../../img/Blogbanners1/how to prevent medical billing rejections1.jpg";
import Blogcontent from "../../img/Blogcontent1/blog content images  13.jpg";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Helmet } from 'react-helmet';


import DownContact from "../../components/downcontact";

const About = () => {
  useEffect(() => {
    // change title of page
    document.title = "Details about Patients is Protected by AcerHealth";
  }, []);

  // Set Banner Title
  // const [BannerTitle, setBannerTitle] = useState(
  //   "What’s the Difference Between Medical Billing and Credentialing?", img:`url(${RCMcy})`});
  // );
  const [BannerTitle, setBannerTitle] = useState({title: "Details about Patients is Protected by AcerHealth", subtitle: "", img:`url(${RCMcy})`});

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({
    title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });

  return (
    <>
      <Banner BannerTitle={BannerTitle} />
      <MDBContainer fluid className="p-0 bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 mb-5">
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
              The HIPPA act, passed by the US Congress to address security issues in the healthcare and electronic fields, is defined as the set of standards that establish minimum requirements for network security and streamline electronic transactions.  {" "}
              </p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>The HIPPA act was created to increase the healthcare systems efficiency and effectiveness in the areas of electronic data for the designated administrative and financial operations while safeguarding the security and confidentiality of that information. Implementing HIPAA and maintaining its policies is crucial for any healthcare provider.</p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>The HIPAA privacy rule's main objective is to safeguard and protect sensitive patient data without impeding the flow of health information that healthcare providers need to do their jobs. Because patient health records frequently include personal information or financial information, it is crucial to safeguard patient confidentiality.</p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>The patient's medical history, insurance details, and the results of any medical testing may all be included in the patient data. Health care workers utilize this information to analyse and improve patient care.</p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>Patient information is the information individuals give to their healthcare professionals when they go to medical institutions to receive treatment. The fact that this information is documented helps physicists in providing their patients the best care possible.</p>  
              <div className="mb-3">
                <img
                  src={Blogcontent}
                  alt="AcerHealth"
                  className="img-fluid"
                  width={500}
                  height={500}
                />
              </div>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}><b>Patient Information Types</b></p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>The two forms of patient data that medical professionals typically gather fall under - </p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>1. Confidential Health Information.</p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>2. Health data that has been deidentified, commonly known as consumer health data</p>
              <h4 style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500',fontWeight:'bold' }}>Confidential Health Information</h4>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>Protected health information (PHI) encompasses the most delicate type of patient data. Billing information, information exchanged by email from the doctor's office, and the results of blood tests are a few examples of sensitive information. This newly created information may be utilized or revealed in the course of rendering medical services, such as a diagnosis or treatment.</p>
              <h4 style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500',fontWeight:'bold' }}>De-identified Health Information</h4>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>De-identified health information is defined as health information that is less safeguarded and cannot be used to identify patients. Information like a patient's basic information and heart rate readings that are not protected health information are included under this section.</p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>Patient loyalty and satisfaction are positively impacted by AcerHealth collection tactics. Through our convenient, secure and HIPPA compliant payment technology, the AcerHealth payment site in-built software assists patients in managing their medical bill payments.</p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>AcerHealth's in-built software conforms with HIPAA regulations due offering end-to-end data encryption. It also offers a straightforward, seamless, and secure method of paying for healthcare services. This is done by utilizing modern technology. Anytime, anywhere, your patients can safely pay all of their healthcare expenses.</p>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>Experts working with rejections resulting from authorizations, eligibility, and patient demographics are available at AcerHealth. The processing of your claims and front-end billing are two of the services we offer. For demographics, card issue details, and eligibility verification, we offer technology tools and training to your medical facility. </p>
              <h3 style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontWeight:'bold' }}>Conclusion </h3>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>AcerHealth recognizes the importance of protecting patients' details and upholds strict confidentiality practices to ensure the privacy and security of patient information. By implementing robust data protection measures and adhering to legal and ethical guidelines, AcerHealth aims to build trust with patients and maintain their confidence in the healthcare system. Safeguarding patient data not only preserves their rights but also contributes to the overall integrity of healthcare practices. AcerHealth's commitment to patient privacy underscores its dedication to delivering high-quality care while prioritizing patient-centricity and ethical practices.</p>
            <br></br>
            <a href="/" className="text-reset" style={{color:'red'}}>
                 <button style={{background:'#3B0075',color:'white'}}>Learn more</button>
                </a>
          </div>
          </div>
          <hr className="style-eight" />
          <MDBRow className="row-cols-1 row-cols-md-4 g-4">
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg6}
                  alt=" How to Accelerate Claims Processing Technology"
                  position="top"
                  // style={{height:'220px'}}
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="how_to_accelerate_claims_processing_technology">
                    How to Accelerate Claims Processing Technology
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-4">
                    <small className="text-muted">
                      AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
            <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg13}
                  alt="Medical Billing Trends to Watch in 2023"
                  position="top"
                  // style={{height:'222px'}}
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="medical_billing_trends_to_watch_in_2023_24">
                    Medical Billing Trends to Watch in 2024
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-4">
                    <small className="text-muted">
                      AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg8}
                  alt="Techniques to Increase Patient Engagement"
                  position="top"
                  // style={{height:'225px'}}
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="Techniques_to_Increase_Patient_Engagement">
                    Techniques to Increase Patient Engagement
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-4">
                    <small className="text-muted">
                      AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg9}
                  alt="How to Prevent Medical Billing Rejections"
                  position="top"
                  // style={{height:'225px'}}
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="how_to_prevent_medical_billing_rejections">
                    How to Prevent Medical Billing Rejections
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-4">
                    <small className="text-muted">
                      AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
      {/* <DownContact DownContactTitle={DownContactTitle} /> */}
    </>
  );
};

export default About;
