import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBBtn
} from 'mdb-react-ui-kit';
export default function Banner({BannerTitle}) {
return (
  <MDBContainer fluid className='p-0'>
  <header>
      <div
          className='p-5 text-center bg-image'
          style={{ backgroundImage: BannerTitle.img,
          minHeight: "500px",
          backgroundPosition: "top",
          backgroundRepeat: "repeat",
           backgroundPositionX:'75%',
           backgroundPositionY:'50%',
          
       }}
      >              
      </div>
      </header>
</MDBContainer>
  );
}