import React, { useEffect , useState } from 'react';
import {MDBContainer,} from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/banner';
import Info from '../components/contact_contactusinfo';
import ContactMap from '../components/contact_contactusmaps';
import RCMcy from '../img/Homepagebanners/contact.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const Contact = () => {
  useEffect(() => {
    // change title of page
    document.title = 'One Step Contact | Medical Billing Service | AcerHealth';

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Contact Us');

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({title: "", subtitle: "", img:`url(${RCMcy})`});


 return (
    <>
     <Helmet>
        <link rel="canonical" href="https://acerhealth.com/contact-us" />
        <meta name="description" content="Enhance your personalized healthcare billing with our internal  skilled team. Email us at info@acerhealth.com for any questions." />
        <meta name="keywords" content="Medical Billing" />
      </Helmet>
        {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
        <Banner BannerTitle ={BannerTitle}/>
        <Info />
        <ContactMap />
    </>
  );
};
  
export default Contact;