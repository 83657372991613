
import React,{useRef} from "react";
import { MDBBtn } from 'mdb-react-ui-kit';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBRipple,
} from "mdb-react-ui-kit";
import   "../css/verify.css";
 import smartreportBanner from "../img/technologies/Infographic/patient balance estimator bg remove.png";
 import smartreportBanner1 from "../img/technologies/Infographic/final patient balance estimator.png";
 import comunicate from "../img/out of pocket calculator.svg";
 import digitalpayment from "../img/shedding cost.svg";
 import textreminder from "../img/patient provider.svg";
 import textreminder1 from "../img/increase collection.svg";
 import "../css/servicesfont.css";
const Home = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick1 = () => {
    ref1.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick2 = () => {
    ref2.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick3 = () => {
    ref3.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <MDBContainer fluid className="bg-light p-0">
      <div className="container py-5">

<div class="button-overlay3 mt-4 mb-4 d-none d-lg-block">
<img
                className=""
                src={smartreportBanner}
                
                style={{ borderRadius: "10px",height:'600px',paddingLeft:'320px'}}
                alt="Patient Balance estimator"
              />
            <MDBRow>
 <MDBBtn color='link' rippleColor='dark' onClick={handleClick} style={{ fontSize: '12px',color:'white',fontFamily:'Alegreya Sans', backgroundColor: 'transparent',height:'260px'}} className="button-overlay3">
       out of pocket calculator
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick1} style={{ fontSize: '12px',color:'white',width:'150px',
    left: '60%',fontFamily:'Alegreya Sans', backgroundColor: 'transparent',height:'260px'}}>
    Shedding cost on statements
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick2} style={{ fontSize: '12px',color:'white',width:'180px',left: '37%',height:'260px',top:'480px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}}>
     Patient-provider relationship
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick3} style={{ fontSize: '12px',color:'white',width:'150px',left: '59%',height:'260px',top:'490px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}}>
     Increase Collection
    </MDBBtn>
    </MDBRow>
</div>

<div class="button-overlay3 mt-4 mb-4 d-lg-none">
<img
                className=""
                src={smartreportBanner1}
                style={{ borderRadius: "10px",width:'100%'}}
                alt="Patient Balance estimator"
              />
</div>

      <div ref={ref} className="card mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5 p-4 d-md-none">
            <div className="row">
            <div class="col-md-8 " style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Out Of Pocket Calculator</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500'}}>
                MDBill calculates the out-of-pocket cost for individual patients based on their individual insurance contracts. You can auto-calculate your contract allowable rates across all CPT and HCPCS codes. With accurate estimates at the time of visit, you can provide your patients with the cost transparency they now demand. It’s much easier to request payment when patients can see what they owe. Collecting patients out of pocket expenses at the time of visit helps decrease the risk of bad debt and increase in cash flow.
                </p>
              </div>
              <div class="col-md-4 text-center mt-4">
              <img src={comunicate} className="img-fluid" width={350} height={250} alt="Best medical billing solution"/>
              </div>
              
            </div>
          </div>
          <div className="col-lg-12 mb-5 p-4 d-none d-md-block">
            <div className="row">
              <div class="col-md-4 text-center mt-4 ">
              <img src={comunicate} className="img-fluid" width={350} height={250} alt="Best medical billing solution"/>
              </div>

              <div class="col-md-8 " style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Out Of Pocket Calculator</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500'}}>
                MDBill calculates the out-of-pocket cost for individual patients based on their individual insurance contracts. You can auto-calculate your contract allowable rates across all CPT and HCPCS codes. With accurate estimates at the time of visit, you can provide your patients with the cost transparency they now demand. It’s much easier to request payment when patients can see what they owe. Collecting patients out of pocket expenses at the time of visit helps decrease the risk of bad debt and increase in cash flow.
                </p>
              </div>
              
            </div>
          </div>
          </div>
      </div>
      <div ref={ref1} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Shedding Costs on Statements</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500'}}>
                Improve your cash flow by shedding costs involved in patient payment – no paper statements, collection calls and commission to third party agency. Produce customized patient letters containing the patient-specific estimate and which can also include current and past balances. You can reduce the statement costs, increase efficiency and cut the time delay in collection.
                </p>
              </div>
              <div class="col-md-4 text-center ">
              <img src={digitalpayment} className="img-fluid" width={350} height={250} alt="Shedding cost"/>
              </div>
            </div>
          </div>
          </div>
   </div>
     
      <div ref={ref2} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5 d-md-none">
            <div className="row">
            <div class="col-md-4 text-center ">
            <div class="col-md-8 " style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Patient-Provider Relationship</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500'}}>
                Patient satisfaction is the crux of the healthcare business and our goal is to narrow the bridge by providing transparency in this process, 
                which will further strengthen the patient-provider relationship. Apart from enhancing the revenue stream, 
                Patient Balance Estimator provides the pricing transparency that the patients demand. Hence, it increases patient satisfaction.
                </p>
              </div>
              <img src={textreminder} className="img-fluid" width={350} height={250} alt="Patient healthcare consulting service"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 d-none d-md-block">
            <div className="row">
            <div class="col-md-4 text-center ">
              <img src={textreminder} className="img-fluid" width={350} height={250} alt="Patient healthcare consulting service"/>
              </div>
              <div class="col-md-8 " style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Patient-Provider Relationship</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500'}}>
                Patient satisfaction is the crux of the healthcare business and our goal is to narrow the bridge by providing transparency in this process, 
                which will further strengthen the patient-provider relationship. Apart from enhancing the revenue stream, 
                Patient Balance Estimator provides the pricing transparency that the patients demand. Hence, it increases patient satisfaction.
                </p>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div ref={ref3} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
            
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Increase Collection</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500'}}>
                Showing patients what they owe not only sets payment expectations, it also creates an immediate opportunity to collect the payment
                 when the patient is in the office. Avoid chasing balances later by starting to close them at the front desk.
                </p>
              </div>
              <div class="col-md-4 text-center ">
              <img src={textreminder1} className="img-fluid" width={350} height={250} alt="Increase collection"/>
              </div>
              
            </div>
          </div>
          </div>
      </div>
    </div>
    </MDBContainer>
  );
};

export default Home;
