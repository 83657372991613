import React from "react";
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import rcm from '../img/Conseltancyservices/rcm.jpg';
import merger from '../img/Conseltancyservices/merger and acquisitions.jpg'
import meaningful from '../img/Conseltancyservices/MIPS.jpg'
import negotiation from '../img/Conseltancyservices/negotiations and agreements.jpg'
import Information from '../img/Conseltancyservices/information technology.jpg'
import icd from '../img/Conseltancyservices/icd-10 consultancy.jpg'
import "../css/servicesfont.css";

export default function ConsultancyDesc() {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div className="col-md-4 text-center">
              <img src={rcm} className="img-fluid" width={350} height={250} alt="Revenue Cycle Management"/>
              </div>
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Revenue Cycle Management
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight: '500' }}>
                We bring our hands-on experience in revenue cycle management to help your practice improve performance and strengthen its financial health and profitability, whether it's claims processing, streamlining operations in surgery centers, or generating revenue for solo specialists.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div className="col-md-4  text-center">
              <img src={merger} className="img-fluid" style={{width:'350px',height:'250px'}} alt="Medical billing software solutions"/>
              </div>
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Mergers and Acquisitions
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight: '500' }}>
                Are you looking to merge or acquire another practice or surgery center? You're at the right place. We can help you with the proper advisory recommendations so that it's seamless for both parties. Our team can help you avoid billing disruption by analyzing financial and technological timelines such as studying patient volume reports, procedure diversity, payment models, EHR and practice management setups, receiving common submitter IDs from clearinghouses, setting up ERAs/EFTs and establishing connections between totally different software systems if necessary.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div className="col-md-4 text-center">
              <img src={meaningful} className="img-fluid" width={350} height={250} alt="MIPS"/>
              </div>
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>MIPS</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight: '500' }}>
                The Merit-based Incentive Payment System (MIPS) is one of two tracks under the Quality Payment Program, which moves Medicare Part B providers to a performance-based payment system. MIPS determines how much money Medicare pays your practice for its services. It gives your practice a composite performance score that affects how much Medicare pays you. We at AcerHealth assist practices in MIPS attestation and thereby avoiding Medicare penalties and potentially also getting bonus payments.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div className="col-md-4 text-center">
              <img src={negotiation} className="img-fluid" width={350} height={250} alt="Contractor compliance requirements"/>
              </div>
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Audit Consultancy Services
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight: '500' }}>
                A small practice is often a busy place. This means that there just aren’t enough hours in the day or on weekends to attend to the task of a medical billing audit. To overcome this problem, we work with all sizes of healthcare organizations by developing quality protocols and also undertaking internal audits. In case your practice is audited, we will assist you through the audit process and help you meet regulatory requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div className="col-md-4 text-center">
              <img src={Information} className="img-fluid" width={350} height={250} alt="Information Technology"/>

              </div>
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Information Technology
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight: '500' }}>
                Each individual will have their own set of requirements. For instance, if one consumer wanted us to replicate an 
                entire patient demographics list from one practice management system to other, another customer wanted a 
                bridge between an electronic health record system and a practice management system. If you are just 
                starting out or need enhancements, we can assist you in streamlining your IT and software infrastructure 
                without having to hire a full-time employee. Our deep understanding of practical and technical experience 
                permits us to provide solutions that add value to businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
}
