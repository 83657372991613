
// export default Joinacer;
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBCheckbox,
  MDBTextArea,
  MDBFile,
  MDBSpinner,
} from "mdb-react-ui-kit";
import {useState, useRef } from "react";
import axios from "axios";

const Joinacer = () => {
const [selectedFile, setSelectedFile] = useState(null);
const [Selectedfirstname, setSelectedfirstname] = useState("");
const [selectedlastname, setselectedlastname] = useState("");
const [selectedemail, setselectedemail] = useState("");
const [selectededucation, setselectededucation] = useState("");
const [selectedphone, setselectedphone] = useState("");
const [selectedskills, setselectedskills] = useState("");
const [selectedmessage, setselectedmessage] = useState("");
const [successMessage, setSuccessMessage] = useState("");
const [isLoading, setIsLoading] = useState(false);
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const usPhoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
const fileInputRef = useRef(null);

const handleFileChange = (e) => {
   setSelectedFile(e.target.files[0]);
};

const handlefirstnamechange = (event) => {
  setSelectedfirstname(event.target.value);
};

//Update the password state when the value in the pasword input field changes
const handlelastnameChange = (event) => {
  setselectedlastname(event.target.value);
};

// Update the username state when the value in the username input field changes
const handleemailidChange = (event) => {
  setselectedemail(event.target.value);
};

//Update the password state when the value in the pasword input field changes
const handleducationChange = (event) => {
  setselectededucation(event.target.value);
};


const handlephonenoChange = (event) => {
  setselectedphone(event.target.value);
};

const handleskillsChange = (event) => {
  setselectedskills(event.target.value);
};


const handlemessageChange = (event) => {
  setselectedmessage(event.target.value);
};

const clearSuccessMessage = () => {
  setSuccessMessage("");
};

const [errors, setErrors] = useState({
  firstname: '',
  lastname: '',
  emailid: '',
  education: '',
  phoneno: '',
  skills: '',
  message: '',
  file: '',
});

const validateForm = () => {
  let valid = true;
  const newErrors = { ...errors };

  if (!Selectedfirstname.trim()) {
    newErrors.firstname = 'First Name is required';
    valid = false;
  } else {
    newErrors.firstname = '';
  }
  if (!selectedlastname.trim()) {
    newErrors.lastname = 'Last Name is required';
    valid = false;
  } else {
    newErrors.lastname = '';
  }
  if (!selectedemail.trim()) {
    newErrors.emailid = 'Email is required';
    valid = false;
  } else if (!emailRegex.test(selectedemail)) {
    newErrors.emailid = 'Invalid email address';
    valid = false;
  } else {
    newErrors.emailid = '';
  }
  if (!selectededucation) {
    newErrors.education = 'Education is required';
    valid = false;
  } else {
    newErrors.education = '';
  }
  if (!selectedphone) {
    newErrors.phoneno = 'Phone number is required';
    valid = false;
  } else if (!usPhoneRegex.test(selectedphone)) {
    newErrors.phoneno = 'Invalid US phone number';
    valid = false;
  } else {
    newErrors.phoneno = '';
  }
  if (!selectedskills.trim()) {
    newErrors.skills = 'Skills are required';
    valid = false;
  } else {
    newErrors.skills = '';
  }
  if (!selectedmessage.trim()) {
    newErrors.message = 'Message is required';
    valid = false;
  } else {
    newErrors.message = '';
  }
  if (!selectedFile) {
    newErrors.file = 'Please select file';
    valid = false;
  } else {
    newErrors.file = '';
  }
  // You can add more validation rules for email, phone, etc.

  setErrors(newErrors);
  return valid;
};

const handleInsertion = () => {
  if (validateForm()) {
    setIsLoading(true);
    const formData = new FormData();
    // Add other form fields to formData
    formData.append("file", selectedFile);
    formData.append("firstname", Selectedfirstname);
    formData.append("lastname", selectedlastname);
    formData.append("email", selectedemail);
    formData.append("education", selectededucation);
    formData.append("phone", selectedphone);
    formData.append("skills", selectedskills);
    formData.append("message", selectedmessage);

    console.log(formData);
    // Make a POST request to your PHP insertion API
    axios
      .post("https://mdbill.io/websiteemail/testfile.php", formData)
      .then((response) => {
        console.log(response);
       
      })
      .catch((error) => {
        setSelectedFile(null);
        setSelectedfirstname("");
setselectedlastname("");
setselectedemail("");
setselectededucation("");
setselectedphone("");
setselectedskills("");
setselectedmessage("");
setSuccessMessage("Form submitted successfully");
        setTimeout(clearSuccessMessage, 3000);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setIsLoading(false);
        console.error(error);
        
      });
    }
};
return (
  <MDBContainer fluid className="p-0 bg-light">
    <div className="container py-5">

    <MDBCol className="col-md-12">
            <MDBCard>
              <MDBCardBody>
              <div style={{ fontWeight: 'bold',fontFamily: "Alegreya Sans",fontSize:'22px' }} className='AcerhealthTextPurple display-9 text-center mb-3'>AcerHealth is Expanding - Join Us to Mold the Future</div>
              <br></br>
          
          {/* <MDBCol md="3" className="mt-4"> */}
            <br></br>
            <MDBRow>
              <MDBCol>
                <MDBRow>
                  <MDBCol size="md" className="mb-3">
                  {errors.firstname && <div className="error" style={{color:'red'}}>{errors.firstname}</div>}
              <MDBInput label='First Name' id="firstname" name="firstname" type='text' autocomplete="off" onChange={handlefirstnamechange} value={Selectedfirstname}/>
              </MDBCol>
              <MDBCol size="md" className="mb-3">
              {errors.lastname && <div className="error" style={{color:'red'}}>{errors.lastname}</div>}
              <MDBInput label='Last Name' id="lastname" name="lastname" type='text' autocomplete="off" onChange={handlelastnameChange} value={selectedlastname}/>
              </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol size="md" className="mb-3">
            {errors.emailid && <div className="error" style={{color:'red'}}>{errors.emailid}</div>}
            <MDBInput label='Email ID' id="emailid" name="emailid" type='text'  autocomplete="off" onChange={handleemailidChange} value={selectedemail}/>
            </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="md" className="mb-3">
              {errors.education && <div className="error" style={{color:'red'}}>{errors.education}</div>}
              <MDBInput label='Education Qualification' id="education" name="education" type='text' autocomplete="off" onChange={handleducationChange} value={selectededucation}/>
            </MDBCol>
            <MDBCol size="md" className="mb-3">
            {errors.phoneno && <div className="error" style={{color:'red'}}>{errors.phoneno}</div>}
            <MDBInput label='Phone No.' id="phoneno" name="phoneno" type='text' autocomplete="off" onChange={handlephonenoChange} value={selectedphone}/>
            </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="md" className="mb-3">
              {errors.skills && <div className="error" style={{color:'red'}}>{errors.skills}</div>}
              <MDBInput label='Your Key Skills' id="skills" name="skills" type='text' autocomplete="off" onChange={handleskillsChange} value={selectedskills}/>
            </MDBCol>
            <MDBCol size="md" className="mb-3">
            {errors.message && <div className="error" style={{color:'red'}}>{errors.message}</div>}
            <MDBTextArea label='Message' id="message" name="message" type='text' autocomplete="off" onChange={handlemessageChange} value={selectedmessage}/>
            </MDBCol>
            </MDBRow>
            <MDBRow className="col">
              <MDBCol size="md">
              {errors.file && <div className="error" style={{color:'red'}}>{errors.file}</div>}
              <MDBFile
                label="Upload Your Resume"
                type="file"
                id="customFile"
                className="text-start"
                onChange={handleFileChange}
                ref={fileInputRef}
                name={`customFile_${Math.random()}`}
                autoComplete="off"
              />
            </MDBCol>
            </MDBRow>
            <br></br>
            <MDBRow>
              <MDBCol className="text-center">
              <MDBBtn
                      type="submit"
                      className="mb-4 d-grid gap-2 col-5 mx-auto"
                      style={{ backgroundColor: "#3B0075" }}
                      onClick={handleInsertion}
                    >
                      {isLoading ? (
              <div className="d-flex align-items-center">
                <strong>Sending...</strong>
                <MDBSpinner className="ms-auto" size='sm' role="status" />
              </div>
            ) : (
              "Submit"
            )}
                    </MDBBtn>
            </MDBCol>
            </MDBRow>

          </MDBCardBody>
          </MDBCard>
          </MDBCol>
          {successMessage && (
  <div className="alert alert-success">{successMessage}</div>
)}
    </div>
  </MDBContainer>
);
};

export default Joinacer;

