import React from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText,
  MDBCardOverlay,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import Background from "../img/parallax13.svg";
import "../css/parallax.css";

export default function App() {
  return (
    <MDBContainer fluid className="p-0">
      <section
        className="background-radial-gradient overflow-hidden p-4 testparallax13"
        // style={{
        //   backgroundImage: `url(${Background})`,
        //   minHeight: "500px",
        //   backgroundAttachment: "fixed",
        //   backgroundPosition: "center",
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        // }}
      >


        {/* <div className="row">
          <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <MDBCard background="dark" className="text-white">
              <MDBCardImage overlay src={Service1} alt="..." />
              <MDBCardOverlay>
                <MDBCardTitle>Medical Coding</MDBCardTitle>
                <MDBCardText>
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </MDBCardText>
                <MDBCardText>Last updated 3 mins ago</MDBCardText>
              </MDBCardOverlay>
            </MDBCard>
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <MDBCard background="dark" className="text-white">
              <MDBCardImage overlay src={Service1} alt="..." />
              <MDBCardOverlay>
                <MDBCardTitle>QC Check</MDBCardTitle>
                <MDBCardText>
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </MDBCardText>
                <MDBCardText>Last updated 3 mins ago</MDBCardText>
              </MDBCardOverlay>
            </MDBCard>
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <MDBCard background="dark" className="text-white">
              <MDBCardImage overlay src={Service1} alt="..." />
              <MDBCardOverlay>
                <MDBCardTitle>Pre-Authorization</MDBCardTitle>
                <MDBCardText>
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </MDBCardText>
                <MDBCardText>Last updated 3 mins ago</MDBCardText>
              </MDBCardOverlay>
            </MDBCard>
          </div>
        </div> */}
      </section>
    </MDBContainer>
  );
}
