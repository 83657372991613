import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/breadcrumbs";
import Banner from "../../components/img_bannerblogs";
import RCMcy from "../../img/Blogbanners1/test/choosingtooutsourceyourmedicalbillingisasmartdecision1.jpg";
import BlogImg4 from "../../img/Blogbanners1/Maximize revenue and minimize hassles with acerhealth experts1.jpg";
import BlogImg1 from "../../img/Blogbanners1/difference between medical billing and credentialing1.jpg";
import BlogImg7 from "../../img/Blogbanners1/obtaining medical billing services from the best medical1.jpg";
import BlogImg9 from "../../img/Blogbanners1/how to prevent medical billing rejections1.jpg";
import Blogcontent from "../../img/Blogcontent1/blog content images 2.jpg";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";

import { Helmet } from 'react-helmet';
import DownContact from "../../components/downcontact";
const About = () => {
  useEffect(() => {
    // change title of page
    document.title = "Blogs | AcerHealth Best Medical Billing Company";
  }, []);


  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({title: "Choosing to Outsource Your Medical Billing is a Smart decision?", subtitle: "", img:`url(${RCMcy})`});

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({
    title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });

  return (
    <>
      <Banner BannerTitle={BannerTitle} />
      <MDBContainer fluid className="p-0 bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
              Definitely! Many healthcare providers are making the right decision in making this move of outsourcing medical billing services to trusted experts. In this blog, we explore how outsourcing medical billing can maximize revenue and minimize hassles for healthcare providers.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
              Outsourcing medical billing puts your revenue cycle in the hands of skilled and knowledgeable professionals. These experts possess a deep understanding of medical coding, billing regulations, and industry best practices. By leveraging their expertise, you ensure accurate claims submission, reduced errors, and optimized reimbursement rates, ultimately increasing your practice's revenue.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
              AcerHealth would like to give you a few reasons why this is the case.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
              In-house medical billing requires investment in staff training, software, hardware, and ongoing maintenance. Outsourcing can be cost-effective, as you pay for the services you need without the overhead expenses.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
              Billing can be time-consuming and divert the focus of healthcare providers from patient care. Outsourcing allows healthcare professionals to concentrate on their core responsibilities.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
              Established medical billing companies have streamlined processes, which can lead to quicker payments and a reduction in accounts receivable time.
              </p>
              <div className="mb-3">
                <img
                  src={Blogcontent}
                  alt="outsourcing medical billing"
                  className="img-fluid"
                  width={500}
                  height={500}
                />
              </div>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
              Outsourcing to professionals who are well-versed in medical billing can help minimize errors, rejections, and denials, leading to improved revenue cycle management.
              </p>
              <p className="" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
              Medical billing companies are familiar with healthcare regulations and can ensure your billing practices comply with industry standards, reducing the risk of penalties and legal issues.
              </p>
              <h3 style={{fontFamily: 'EBGarmond semi bold, Helvetica',fontWeight:'bold' }}>Conclusion </h3>
              <p style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Outsourcing medical billing is a smart decision for healthcare organizations facing financial challenges and seeking to build a stable revenue cycle management system. By partnering with trusted outsourcing companies, healthcare providers can benefit from the expertise, experience, and technology they bring to the table. It allows medical personnel to focus more on patient care, reduces billing errors and labour costs, and optimizes the revenue cycle speed. It also ensures the safe transmission and protection of patient data and prevents revenue leakage from denied claims. Overall, outsourcing medical billing can save time, reduce overhead expenses, and improve revenue, making it a wise choice for healthcare organizations.</p>
              <br></br>
            <a href="/" className="text-reset" style={{color:'red'}}>
                 <button style={{background:'#3B0075',color:'white'}}>Learn more</button>
                </a>
            </div>
          </div>
          <MDBRow className="row-cols-1 row-cols-md-4 g-4">
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg4}
                  alt="Maximize Revenue and Minimize Hassles with AcerHealth
                  Experts Medical Billing Services"
                  position="top"
                  // style={{height:'206px'}}
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="maximize_revenue_and_minimize_hassles_with_acerhealth_experts_medical_billing_services">
                      Maximize Revenue and Minimize Hassles with AcerHealth
                      Experts Medical Billing Services
                    </a>
                  </MDBCardTitle>
                  <MDBCardText>
                    <small className="text-muted">
                      AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg1}
                  alt="What’s the Difference Between Medical Billing and
                  Credentialing"
                  position="top"
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="whats_the_difference_between_medical_billing_and_credentialing">
                      What’s the Difference Between Medical Billing and
                      Credentialing
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-4">
                    <small className="text-muted">
                      AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg7}
                  alt="Obtain Billing Services from the Best Medical Billing
                  Company"
                  position="top"
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="obtain_billing_services_from_the_best_medical_billing_company">
                      Obtain Billing Services from the Best Medical Billing
                      Company
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-4">
                    <small className="text-muted">
                      AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="cardtest1">
                <MDBCardImage
                  src={BlogImg9}
                  alt="How to Prevent Medical Billing Rejections"
                  position="top"
                  // style={{height:'206px'}}
                />
                <MDBCardBody>
                  <MDBCardTitle className="cardtitletest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    <a href="how_to_prevent_medical_billing_rejections">
                      How to Prevent Medical Billing Rejections
                    </a>
                  </MDBCardTitle>
                  <MDBCardText className="pt-5">
                    <small className="text-muted">
                      AcerHealth | Aug - 2024
                    </small>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
      {/* <DownContact DownContactTitle={DownContactTitle} /> */}
    </>
  );
};

export default About;
