import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import Final from "../img/setupapracticeinfo.jpg";
import "../css/servicesfont.css";
const StartPracticeDesc = () => {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <h2 className="text-center pb-4 AcerhealthTextPurple fomtsizetest" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>How You Will Get the Benefits</h2>
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-12">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Low Upfront Costs & No Long-term Contracts
                </h4>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily:'Alegreya Sans',fontWeight:'500'}}>
                At AcerHealth, our success depends on yours. Our payment model aligns our financial incentives along with your practice’s performance. That’s why we’re committed to serving you results from day one, with low up-front prices that will help you start as soon as possible. To make sure you achieve those results, we provide you one comprehensive solution that’s invariably up to date. No hidden prices or costly upgrades. No ongoing maintenance fees and lastly, no long-run contracts.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-12">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Assisting with Administrative Burden to Prioritize Provider-Patient Interaction
                </h4>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily:'Alegreya Sans',fontWeight:'500'}}>
                As a network-enabled service, we offer you more than just a technology platform. We take on those burdensome administrative tasks to cut back your work, so that that you can concentrate on patient care. Whether scanning and sorting faxes, posting payments, following up on denials, finishing eligibility checks, or creating reminder calls, we take work off your hands with a high degree of accuracy. Our billing rules engine is up-to-date, thus you've got the expertise you need to stay up with changing payer needs, without hiring extra workers.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5">
            <div className='card'>
            <div className="row">
              <div class="col-md-12 text-center">
              <img src={Final} height="725px" with="100%" alt="Medical billing for small practices"/>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
};
export default StartPracticeDesc;
