import React, { useEffect, useState } from 'react';
import { MDBContainer, } from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/img_banner';
import DownContact from '../components/downcontact';
import Info from '../components/services_consultancy_Info';
import Desc from '../components/services_consultancy_desc';
import RCMcy from '../img/consultancy-services2.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const Consultancy = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Medical Billing & Consultancy | Professional Solutions';

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Consultancy Services');

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({ title: "Medical Consultancy Services", subtitle: "Maximize Medicare Payments, Avoid penalties with Expert MIPS Attestation Support.", img: `url(${RCMcy})` });

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({ title: "To satisfy your unique needs, we offer a wide range of services. Practice administration resources, EHR installation, medical billing outsourcing, and patient billing and collections." });


  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://acerhealth.com/services/medical-consultancy-services" />
        <meta name="description" content="See how we can help with medical billing advice, managing your practice, setting up EHR, and billing services. Contact us!" />
        <meta name="keywords" content="Medical Billing Consultancy Service" />
      </Helmet>
      {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
      <Banner BannerTitle={BannerTitle} />
      <Info />
      <Desc />
      <DownContact DownContactTitle={DownContactTitle} />
    </>
  );
};

export default Consultancy;