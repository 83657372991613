import React from 'react';
import {MDBContainer,} from "mdb-react-ui-kit";
import "../css/servicesfont.css";
const StartPractice = () => {
    return (
        <MDBContainer fluid className="p-0">
        <div className="container py-5">
            <div className="row">
              <div className="col-12">
                  <div className="section-title mb-4 pb-2">
                      <div className="title mb-4 display-heading AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}> Set Up a Practice </div>
                      <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>AcerHealth is in a unique position to help clinicians avoid the challenges of starting a medical practice. With our assistance, you can thrive and withstand change. When starting a solo practice, commercial acumen is frequently more important than expertise. Time spent with patients will seem rushed because there are so many distractions during treatment but that shouldn't be the case if you are working with a results-driven business leader in health IT. AcerHealth is uniquely prepared to help because it is aware of the challenges faced by those starting their own medical practices.</p>
                  </div>
              </div>
          </div>
        </div>    
        </MDBContainer>
    );
};
export default StartPractice;



