import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import transition from '../img/medicalbillingservices/collaboration.jpg';
import specialty from '../img/medicalbillingservices/specialty billing.jpg';
import eligibility from '../img/medicalbillingservices/eligibility and benefits verification.jpg';
import procedure from '../img/medicalbillingservices/procedure Authorizations.jpg';
import quality from '../img/medicalbillingservices/quality and claims submission.jpg';
import payment from '../img/medicalbillingservices/payment reconciliation.jpg';
import account from '../img/medicalbillingservices/account receivables.jpg';
import denial from '../img/medicalbillingservices/daily denial management.jpg';
import reports from '../img/medicalbillingservices/reports.jpg';
import "../css/servicesfont.css";

const MedicalbillingDesc = () => {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center" >
              <img src={transition} className="img-fluid" style={{width:'350px',height:'250px'}} alt="Billing Company"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontfamilyAvenir fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Collaboration</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left fontfamilyAvenir" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Whether you are switching your billing company or starting a brand-new practice, we will work with you and implement a seamless operation from the very first day. Providers can focus on patients, not claims and patient statements. With our experience working with different insurance companies, we know what works and what doesn't. Before boarding, we will plan and assist you with the entire transition and make sure that the entire team focuses on avoiding interruptions in billing. We will work with your practice in a phase-wise manner where we will discuss your software and administrative requirements, data transfer requirements, take decisions on previous AR.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center">
              <img src={specialty} className="img-fluid" style={{width:'350px',height:'250px'}} alt="Specialty billing"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Specialty Billing
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Every day, our billing team focuses on maximizing results for our clients. To make sure billing is clean and up-to-date, we are on the top of denial trends and advice our clients accordingly.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center">
              <img src={eligibility} className="img-fluid" width={350} height={250} alt="Insurance Verification"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Eligibility and Benefits Verification
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                We avoid with eligibility denials by proactively verifying benefits for all patients. All scheduled patients are verified 2 days before their appointment. Among the data we gather are the type of insurance plan, coordination of benefits, copays, and the deductible and out-of-pocket amounts.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center">
              <img src={procedure} className="img-fluid" width={350} height={250} alt="Medical Claims Processing"/>
              </div>
              <div class="col-md-8 fontsizeset2">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Procedure Authorizations
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Ensuring insurance companies to cover procedures and medications has become increasingly difficult among physicians because of the different administrative processes. It's usually an expensive distraction that slows down productivity. We complete the authorization process for all patients and send the details to you. 2 days before the procedure.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center">
              <img src={quality} className="img-fluid" width={350} height={250} alt="Claim denial management services"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Quality and Claims Submissions
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Any process without a quality check isn't complete. Claims that are incorrect or incomplete may cascade if they are not checked at every step of the process. We submit all claims to the insurance companies within 24 hours. After scrubbing, the claims are manually verified. This procedure reduces the chance the claims get denied and boosts the first-pass ratio at first submission.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center">
              <img src={payment} className="img-fluid" width={350} height={250} alt="Payment Reconciliation "/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2"  style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Payment Reconciliation
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                We coordinate with clearing houses and carriers to set up Electronic Remittance Advice (ERAs) and Electronic Fund Transfers (EFTs) that manage your payments. Pending patient balances are applied and adjusted frequently to avoid the accumulation of unapplied payments. We also ensure that the insurances pay the claims as per the contractually agreed upon rates.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center">
              <img src={account} className="img-fluid" width={350} height={250} alt="Account Receivables"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Account Receivables
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Having trouble keeping track of your receivables? Do you have a backlog of claims to process because of a lack of skilled resources? Worried? In order to make this process as efficient as possible, we follow up on all claims, if they are not paid within 14 days. All claims are tracked and denials are resolved withing 24 hours.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center">
              <img src={denial} className="img-fluid" width={350} height={250} alt="Denial management process"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Daily Denial Management
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                No matter how smart your claims submission process is, denied claims are liable to happen. Management of those issues is going to be a fact of life for medical practices. All denials are addressed within 24 hours. If a claim is not paid within 14 days, our AR team follows up with insurances and take appropriate action to ensure all the claims gets paid.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 text-center">
              <img src={reports} className="img-fluid" width={350} height={250} alt="Medical Billing Reports"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Reports</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Using our smart reports, you can make informed decisions about the health of your practice.
                Using our Smart Reports, you can make informed decisions about the health of your practice. The AcerHealth Smart Reports interface is intuitive, with eye-catching dashboards and graphical collections. You can generate reports and graphs by specifying parameters. In addition, you can monitor the key performance indicators of your practice such as operational costs, the billing & reimbursement cycle, accounts receivable, and revenue stream. With this analysis, you'll find underperforming areas and operational silos, so you can increase productivity and maximize revenue.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
};
export default MedicalbillingDesc;
