import React from 'react';
import {MDBContainer,} from "mdb-react-ui-kit";
import "../css/servicesfont.css";
const techpreautho = () => {
    return (
        <MDBContainer fluid className="p-0">
        <div className="container py-5">
            <div className="row">
              <div className="col-12">
                  <div className="section-title mb-4 pb-2">
                      <div className="title mb-4 display-heading AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}>Patient Eligibility Verification</div>
                      <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}> The eligibility dashboard is published for the client’s access 2 days prior to the patient visit. All information such as copay, previous balance, patient’s insurance status, authorization for any procedures are all listed in a single easy-to-use a screen for your front desk staff. At a click of a button you can download the previous balance statement from our eligibility dashboard without having to go to multiple screens in the billing system.
You can use management reports to evaluate the efficiency of front desk collections. Clients who have started using our eligibility and verification services have recognized a 150% increase in their front desk collections.</p>
                  </div>
              </div>
          </div>
        </div>    
        </MDBContainer>
    );
};
export default techpreautho;