import React, { useState, useEffect } from "react";
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/banner';
import Smartreport from "../components/tech_smartreport";
import DownContact from "../components/downcontact";
import Info from '../components/smartreportinfo';
import RCMcy from '../img/technologies/practiceanalysis.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const smartreport = () => {
  useEffect(() => {
    // change title of page
    document.title = "Comprehensive Practice Analysis | AcerHealth";

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set Breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState("SmartReport");

   // Set Banner Title
   const [BannerTitle, setBannerTitle] = useState({title: "AcerHealth Medical billing services across America", subtitle: "Maximize Your Revenue while Minimizing  the billing Woes ,No Matter Where You Are with AcerHealth Preferred Medical Billing Services", img:`url(${RCMcy})`});

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({
    title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });

  return (
    <>
     <Helmet>
        <link rel="canonical" href="https://acerhealth.com/technologies/practice-analytics" />
        <meta name="description" content="Receive comprehensive practice analyses through technology regularly. Connect with us for a detailed assessment and enhance your practice." />
        <meta name="keywords" content="Practice Analysis" />
      </Helmet>
      {/* <BreadCrumbs Breadcrumb={Breadcrumb} /> */}
      <Banner BannerTitle={BannerTitle} />
      <Info />
      <Smartreport />
      {/* <DownContact DownContactTitle={DownContactTitle} /> */}
    </>
  );
};

export default smartreport;
