
import React from "react";
import {MDBContainer} from "mdb-react-ui-kit";
import "../css/servicesfont.css";

export default function ConsultancyInfo() {
    return (
      <MDBContainer fluid className="p-0">
          <div className="container py-5">
            <div className="row">
                    <div className="col-12">
                        <div className="section-title mb-4 pb-2">
                            <div className="title mb-4 display-heading AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}>Credentialing Services</div>
                            <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>AcerHealth has a special group of credentialing experts who will go above and beyond to give you exceptional service that effortlessly fits into your practice’s operations. These experts have years of experience and high levels of ability. More than 300+ providers have received credentialing services from us.</p>
                        </div>
                    </div>
                </div>
          </div>
       </MDBContainer>
    );
}
