import React from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import ReactPlayer from "react-player";
import styles from "../css/services.css";
import rcmcycle from "../img/rcmcycle.jpg";
import "../css/distancerecservices.css";
import {BrowserRouter, Link} from "react-router-dom";

export default function App() {
  return (
<MDBContainer fluid className="p-0 bg-white">
    <div className="row justify-content-center">
      <div className="col-12">
      <div className="section-heading text-center d-flex justify-content-center align-items-center" style={{background: '#F8F8F8' }}>
  {/* <div className="title mb-4 display-heading AcerhealthTextPurple" style={{ fontSize: '45px', fontFamily: 'EBGarmond semi bold' }}> */}
    <h2 className="title mb-4 display-heading AcerhealthTextPurple rcmservices" style={{fontFamily:'Lora, serif'}}>RCM Services</h2>
    
  {/* </div> */}
</div>
<div className="row justify-content-center mt-2"> {/* Center align the paragraph */}
          <div className="col-md-10">
            <p className="container para-desc mx-auto mb-0 display-9 rcmservices1" style={{fontFamily: "Alegreya Sans",fontWeight:'500'}}>
            Increasing the profitability of your practice by include claim payouts. Our tenacity improves entire practice effectiveness, from claim recycling to efficient medical billing processes. Improve your profitability with our strategies designed for trendy businesses in a competitive healthcare diligence.
            </p>
          </div>
        </div>
<div className="d-flex justify-content-center d-none d-md-block">
    <ReactPlayer
      url="https://tracksystem.s3.us-west-2.amazonaws.com/videos/Revenue+Cycle+Management+Animation.mp4"
      playing={true}
      loop={true}
      muted={true}
      controls={true}
      className="mw-100 custom-video"
      width="700px"
        height="600px"
      id="background_video"
    />
  </div>
  <div className="d-flex justify-content-center d-md-none">
    <ReactPlayer
      url="https://tracksystem.s3.us-west-2.amazonaws.com/videos/Revenue+Cycle+Management+Animation.mp4"
      playing={true}
      loop={true}
      muted={true}
      controls={true}
      className="mw-100 custom-video"
      id="background_video"
    />
  </div>
        <div className="row case-studies2 mt-0">
          <div className="col-md-2"></div>
          <div className="col-md-8 text-center m-0 p-0">
</div>
          <div className="col-md-2"></div>
        </div>
        <div className="row text-center testpadding ml-1">
          <div className="col-12"> {/* Add this div to ensure center alignment */}
          <a href="/services/revenue-cycle-management">
              <MDBBtn style={{ background: '#3B0075' }}>Learn More</MDBBtn>
           </a>
          </div>
        </div>
      </div>
    </div>
</MDBContainer>
  );
}
