import React, { useEffect , useState } from 'react';
import {MDBContainer,} from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/img_banner';
import DownContact from '../components/downcontact';
import Info from '../components/services_rcm_info';
import Desc from '../components/services_rcm_description';

import RCMcy from '../img/Revenue-cycle-management.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const RCM = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Revenue Cycle Management | Medical Billing | AcerHealth';

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Revenue Cycle Management');

  // Set Banner Title
   const [BannerTitle, setBannerTitle] = useState({title: "Revenue Cycle Management", subtitle: "We help Doctors Collect more Money Faster and Easier.", img:`url(${RCMcy})`});

   // Set DownContact Heading
   const [DownContactTitle, setDownContactTitle] = useState({title: "We help medical professionals in raising money swiftly and efficiently. Contact us now to speak with a professional."});

 
 return (
    <>
    <Helmet>
        <link rel="canonical" href="https://acerhealth.com/services/revenue-cycle-management" />
        <meta name="description" content="We provide comprehensive revenue cycle management for healthcare systems, making operations and finances more efficient. Connect with us for more details." />
        <meta name="keywords" content="Revenue Cycle Management" />
      </Helmet>
        {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
        <Banner BannerTitle ={BannerTitle} />
        <Desc />
        <Info />
        <DownContact DownContactTitle = {DownContactTitle}  />
    </>
  );
};
  
export default RCM;