import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import "../css/servicesfont.css";
const Medicalbillingsys = () => {
  return (
    <MDBContainer fluid className="p-0">
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="section-title mb-4 pb-2">
              <div className="title mb-4 AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}>
                Medical Billing Services
              </div>
              <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight: '500' }}>
              Medical billing is a crucial aspect of the healthcare industry, but it can also be one of the most complex and time-consuming tasks for healthcare providers. AcerHealth is one of the top medical billing companies in the country that helps practices to streamline revenue management and allow healthcare providers to focus on providing quality care to their patients. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
};
export default Medicalbillingsys;
