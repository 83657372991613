import React, { useState } from 'react';
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer, MDBNavbar,MDBBtn,MDBCard,MDBCardBody,MDBCardTitle,MDBInput,MDBCheckbox,MDBModal,MDBModalDialog,MDBModalContent,MDBModalHeader,MDBModalTitle, MDBRow, MDBIcon, MDBCol } from 'mdb-react-ui-kit';
 import ContactModal from '../modals/modal_contact';

export default function App() {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const [isHovered, setIsHovered] = useState(false);
    const handleCloseModal = () => {
    setBasicModal(false);
  };
  const buttonStyle = {
    color: '#0f8a07', // Text color property
    outline: 'none',  // Remove the button outline
    color: 'secondary',
    backgroundColor: isHovered ? '#0f8a07' : '#0f8a07',  // Change background color on hover
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',  // Apply transform on hover
    transition: 'transform 0.2s',  // Add a smooth transition
  };
  return (
    <MDBNavbar expand='lg' light className='AcerhealthPurple' color='light'>
      <MDBContainer>
    <div className='d-none d-md-block'>
    <i className='fa-solid fa-phone p-2' style={{fontSize:'20px'}}></i> <span style={{fontSize:'22px',fontFamily: "Alegreya Sans"}}>+1 (888) 666-1912</span> <i class="fa-solid fa-envelope p-2" style={{fontSize:'22px'}}></i><span style={{fontSize:'20px',fontFamily: "Alegreya Sans"}}>support@acerhealth.com</span>
    </div>
    <div className='d-md-none'>
    <div><i className='fa-solid fa-phone p-2' style={{fontSize:'20px'}}></i> <span style={{fontSize:'22px',fontFamily: "Alegreya Sans"}}>+1 (888) 666-1912</span> </div><div><i class="fa-solid fa-envelope p-2" style={{fontSize:'22px'}}></i><span style={{fontSize:'20px',fontFamily: "Alegreya Sans"}}>support@acerhealth.com</span></div>
    </div>
    
        <MDBBtn className='text-light' onClick={toggleShow} onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)} style={buttonStyle}>
            Schedule 1:1 Meeting
        </MDBBtn>
        <ContactModal show={basicModal} onClose={handleCloseModal} />
      </MDBContainer>
    </MDBNavbar>
  );
}