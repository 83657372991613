import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import CountUp from 'react-countup';
const TestimonialBand = () => {
  const [shouldCount, setShouldCount] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('targetSection');
      if (element) {
        const elementPosition = element.getBoundingClientRect();
        if (elementPosition.top < window.innerHeight) {
          setShouldCount(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <MDBContainer fluid className="p-0" style={{ backgroundColor: "#3B0075"}}>
      <div className="container py-5">
          <div class="row">
            <div class="col-lg-3 text-white" >
              <div className="row" style={{fontSize:'65px'}}>
              <div id="targetSection" className='text-center'>
             <div class="row">{shouldCount && <CountUp end={800} duration={3} suffix="+" />}</div>
            </div>
            </div>
            <div className="row" style={{fontSize:'16px', paddingLeft:'95px',fontFamily: 'Alegreya Sans'}}>
           ONGOING ASSISTANCE
            </div>
            </div>
            <div class="col-lg-3 text-white" style={{borderLeft:'1px solid white'}}>
            <div id="targetSection" className='text-center'>
              <div className="row" style={{fontSize:'65px'}}>
              {shouldCount && <CountUp end={14} duration={3} suffix="+" />}
            </div>
            </div>
            <div className='text-center'></div>
            <div className="row" style={{fontSize:'16px', paddingLeft:'110px',fontFamily: 'Alegreya Sans'}}>
            SPECIALTIES
            </div>
            </div>
            <div class="col-lg-3 text-white" style={{borderLeft:'1px solid white'}}>
            <div id="targetSection" className='text-center'>
              <div className="row" style={{fontSize:'65px'}}>
              {shouldCount && <CountUp end={20} duration={3} suffix="+" />}
            </div>
            </div>
            <div className="row" style={{fontSize:'16px', paddingLeft:'75px',fontFamily: 'Alegreya Sans'}}>
            GEOGRAPHIES SERVED
            </div>
            </div>
            <div class="col-lg-3 text-white" style={{borderLeft:'1px solid white'}}>
            <div id="targetSection" className='text-center'>
              <div className="row" style={{fontSize:'65px'}}>
              {shouldCount && <CountUp end={30} duration={3} suffix="%" />}
            </div>
            </div>
            <div className="row" style={{fontSize:'16px',paddingLeft:'100px',fontFamily: 'Alegreya Sans'}}>
            REVENUE INCREASE
            </div>
            </div>
          </div>
      </div>
    </MDBContainer>
  );
};
export default TestimonialBand;
