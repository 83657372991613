import React, { useEffect, useState } from 'react';
import { MDBContainer, } from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/banner';
import DownContact from '../components/downcontact';
import Patientpayment from '../components/tech_patientpayment_info';
import RCMcy from '../img/technologies/patientstatementandpayment.jpg';
import Info from '../components/patientpaymentportal';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const About = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Patient Statement & Payment Portal | AcerHealth';

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set Breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Online Patient Payments');

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({ title: "Our custom reports and credentialing specialists help multi-specialty groups with their contracting needs as the practice grows.", subtitle: "", img: `url(${RCMcy})` });

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({ title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER", subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." });

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://acerhealth.com/technologies/patient-statements-payment-portal" />
        <meta name="description" content="Use AcerHealth's patient payment portal to receive e-statements via phone, email, or text messages with the latest analytic system." />
        <meta name="keywords" content="Patient Statement Payment Portal" />
      </Helmet>
      {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
      <Banner BannerTitle={BannerTitle} />
      <Info />
      <Patientpayment />
      {/* <DownContact DownContactTitle = {DownContactTitle}  /> */}
    </>

  );
};

export default About;