import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput,MDBCard,MDBCardBody,MDBCardTitle,MDBTextArea } from "mdb-react-ui-kit";
import React from 'react';

const Keyfeatures = () => {
  return (
    <MDBContainer fluid className="p-0 bg-light">
    </MDBContainer>
  );
};

export default Keyfeatures;
