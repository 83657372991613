import React, { useEffect , useState } from 'react';
import {MDBContainer,} from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/imgbammeraboutheading';
import AboutAcer from '../components/about_aboutacerhealth';
import AboutCEO from '../components/about_CEO';
import Chooseus from '../components/about_ourvalues';
import Keyfeatures from '../components/about_visionandmission';
import DownContact from '../components/downcontact';
import TestimonialBand from '../components/testimonial_band';
import RCMcy from '../img/Homepagebanners/about.jpg';
import FreeConsultancy from '../components/about_freeconsultancy';
import Joinacer from '../components/about_joinacer';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const About = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Medical Billing Services|Medical Billing Outsource|Medical Billing Consultant';
    
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set Breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('About Acerhealth');

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({title: "Your Premier Partner in Practice Excellence ", subtitle: "", img:`url(${RCMcy})`});

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({title: "", subtitle: "We offer the best in billing, coding, reporting, credentialing, accounts receivable management, and follow-up services. Our highly experienced staff creates revenue prospects for medical practices that result in significant and predictable reimbursements. For additional information, please connect with one of our experts."});

 return (
    <>
      {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
      <Banner BannerTitle ={BannerTitle} />
      <AboutAcer />
      <Chooseus />
      <FreeConsultancy />
      <Keyfeatures />
      <TestimonialBand />
      <AboutCEO />
      <Joinacer />

    </>

  );
};
  
export default About;