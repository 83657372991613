import React from 'react';
import {MDBContainer,} from "mdb-react-ui-kit";
import "../css/servicesfont.css";
const Smartreportinfo = () => {
    return (
        <MDBContainer fluid className="p-0">
        <div className="container py-5">
            <div className="row">
              <div className="col-12">
                  <div className="section-title mb-4 pb-2">
                      <div className="title mb-4 display-heading AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}> Practice Analytics </div>
                      <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>        You can combine any practice management/billing system with Smart Reports to get helpful reports that are easy to use. Despite not having to wade through hundreds of pages of data to find the information you need, you are still unable to manage the situation in your practice. 
Our Smart Reports will provide you thorough and holistic analysis of your practice’s financial and operational health, so you can make informed decisions.
AcerHealth Smart Reports has an intuitive interface with enticing dashboards and graphical assortments. You can generate reports and graphs by specifying parameters and monitor the key performance indicators of your practice such as operational costs, the billing & reimbursement cycle, accounts receivable and revenue stream. This analysis will help you identify and isolate under-performing areas and operational silos, so you can increase productivity, maximize revenue. Here are a few important instances of the automatic tools that are now accessible to you in addition to the typical tabular reports.</p>
                  </div>
              </div>
          </div>
        </div>    
        </MDBContainer>
    );
};
export default Smartreportinfo;