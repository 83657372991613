import React from 'react';
import {MDBContainer,} from "mdb-react-ui-kit";

const patientpaymentportal = () => {
    return (
        <MDBContainer fluid className="p-0">
        <div className="container py-5">
            <div className="row">
              <div className="col-12">
                  <div className="section-title mb-4 pb-2">
                      <div className="title mb-4 display-heading AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}>Patient Statements & Payment Portal</div>
                      <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>Technology has dramatically improved capabilities within the health care industry. We utilize the latest technological advancements by offering patients the option to pay over the phone, to receive electronic statements via email, and to receive text messages about patient statement balances.</p>
                  </div>
              </div>
          </div>
        </div>    
        </MDBContainer>
    );
};
export default patientpaymentportal;