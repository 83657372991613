import React, { useEffect , useState } from 'react';
import {MDBContainer,} from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/img_banner';
import DownContact from '../components/downcontact';
import Info from '../components/services_contracts_info';
import Desc from '../components/services_contracts_desc';
import RCMcy from '../img/contractandcomp.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const ContractCompliance = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Contract & Compliance | AcerHealth';

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Contracts And Compliance');

  // Set Banner Title
   const [BannerTitle, setBannerTitle] = useState({title:"Contracts and Compliance", subtitle: "Save Time and Resources for a Successful Practice with Streamlined Provider Credentialing.", img:`url(${RCMcy})`});

   // Set DownContact Heading
   const [DownContactTitle, setDownContactTitle] = useState({title: "Improve your billing process with our customized solutions.", subtitle: ""});

 
 return (
    <>
    <Helmet>
        <link rel="canonical" href="https://acerhealth.com/services/contract-compliance" />
        <meta name="description" content="We help you operate smoothly, negotiate with third-party administrators, handle important contract details, and conduct regular health audits. Reach out to us!" />
        <meta name="keywords" content="Contract & Compliance" />
      </Helmet>
        {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
        <Banner BannerTitle ={BannerTitle} />
        <Info />
        <Desc />
        <DownContact DownContactTitle = {DownContactTitle}  />

    </>
  );
};
  
export default ContractCompliance;