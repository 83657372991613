import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import up from '../img/rcmservices/up-to-date claims submission.jpg'
import focus from '../img/rcmservices/focus on quality.jpg'
import denial from '../img/rcmservices/daily denial management.jpg'
import regular from '../img/rcmservices/insurance follow ups.jpg'
import technology from '../img/rcmservices/technology infrastructure.jpg'
import payment from '../img/rcmservices/payment reconciliation.jpg'
import "../css/servicesfont.css";
const Revenuesys = () => {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <div className="row">
                <div className="col-md-4 text-center">
                  <img src={up} className="img-fluid" width={350} height={250} alt="Medical Claims Processing"/>
                  <hr className="d-md-none" />
                </div>
                <div className="col-md-8">
                  <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Up-to-date Claims Submission
                  </h4>
                  <hr style={{ border: "2px Solid #582C83" }}></hr>
                  <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily:'Alegreya Sans',fontWeight:'500'}}>
                    Systematized revenue cycle management in healthcare is
                    considered as the key to the sleek administration for
                    hospitals and organizations. We achieve this by using
                    intelligent scripts that automatically extract ICDs and CPTs
                    from bills, checks coding-related compliance, modifier
                    usage, and then enter into a Practice Management System. We
                    benchmark ourselves at submitting claims two days prior to
                    the date of service. This results in a systematized revenue
                    cycle and a regular income flow. The replacement of the
                    traditional manual entering with automation guarantees
                    greater efficiency. This saves us a massive amount of
                    resources and time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-5">
              <div className="row">
                <div className="col-md-4 text-center">
                  <img src={focus} className="img-fluid" style={{width:'350px',height:'250px'}} alt="RCM"/>
                  <hr className="d-md-none" />
                </div>
                <div className="col-md-8">
                  <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Focus on Quality
                  </h4>
                  <hr style={{ border: "2px Solid #582C83" }}></hr>
                  <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily:'Alegreya Sans',fontWeight:'500'}}>
                    Quality is our main focus. We standardize ourselves by
                    implementing the latest technology and embracing our
                    knowledge into executable actions in the key areas of
                    revenue cycle management. This includes, online claim
                    status, expected and estimated cash flow, claims details and
                    eligibility verifications. We follow two significant steps
                    of the quality analysis. Before submitting claims to the
                    carrier the claims are scrubbed across the various
                    standardized rules and regulations to verify the accuracy
                    and flag inconsistencies – this is the first analysis. And
                    then the claims are again verified manually and this is the
                    second analysis. By following this two-step, quality
                    analysis helps us to achieve a greater first-pass ratio at
                    first submission.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-5">
              <div className="row">
                <div className="col-md-4 text-center">
                  <img src={denial} className="img-fluid" width={350} height={250} alt="Denial Management Process"/>
                  <hr className="d-md-none" />
                </div>
                <div className="col-md-8">
                  <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Regular Denial Management
                  </h4>
                  <hr style={{ border: "2px Solid #582C83" }}></hr>
                  <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily:'Alegreya Sans',fontWeight:'500'}}>
                    Every medical practice experiences claim denials and it is
                    crucial for centers to keep a track of denials. Our daily
                    denial management process ensures claims reconciliation
                    quickly and effectively. Our skilled team keep complete
                    track of denials and whenever a denial is detected, it is
                    flagged and instantly sent to the accounts receivables team
                    for resolution. Our teams are in constant coordination with
                    each other until proper denial resolution is achieved. Our
                    stringent processes had resulted in increased cash flows and
                    reduced the accumulation of accounts receivable.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-5">
              <div className="row">
                <div className="col-md-4 text-center">
                  <img src={regular} className="img-fluid" width={350} height={250} alt="Insurance Verification"/>
                  <hr className="d-md-none" />
                </div>
                <div className="col-md-8">
                  <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Insurance Follow-ups
                  </h4>
                  <hr style={{ border: "2px Solid #582C83" }}></hr>
                  <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily:'Alegreya Sans',fontWeight:'500'}}>
                    With the main goal to provide maximized revenue, we work
                    through all the phases of the revenue cycle management from
                    insurance eligibility checks to Regular insurance
                    follow-ups. The crucial part is some centers decide one day
                    of the week to work on denials. By this time, the denials
                    get overflow and it gets tough to recover the balances.
                    Depending on insurance, we regularly track and follow up on
                    the denied claims. This provides accurate claims status and
                    predicts revenues for the practice.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-5">
              <div className="row">
                <div className="col-md-4 text-center">
                  <img src={technology} className="img-fluid" width={350} height={250} alt="Technology Infrastructure"/>
                  <hr className="d-md-none" />
                </div>
                <div className="col-md-8">
                  <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Technology Infrastructure
                  </h4>
                  <hr style={{ border: "2px Solid #582C83" }}></hr>
                  <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily:'Alegreya Sans',fontWeight:'500'}}>
                    Our direct interaction with insurances helps to receive
                    payments quickly and electronically. We constantly keep in
                    touch with carriers and clearing houses and set up ERA
                    (Electronic Remittance Advice) and EFT (Electronic Fund
                    Transfer) utilities with all possible carriers. The main
                    advantage of this infrastructure is it provides quick
                    payment and detailed revenue projection.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-5">
              <div className="row">
                <div className="col-md-4 text-center">
                  <img src={payment} className="img-fluid" width={350} height={250} alt="Payment Reconciliation"/>
                  <hr className="d-md-none" />
                </div>
                <div className="col-md-8">
                  <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Payment Posting and Reconciliation
                  </h4>
                  <hr style={{ border: "2px Solid #582C83" }}></hr>
                  <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans' ,fontWeight:'500'}}>
                    Our skilled team checks payment gateways on a daily basis
                    and if the payment is received, the balances are matched on
                    the same day. Timely reconciliation reports help the clients
                    understand the financials. Pending patient balances are
                    applied and adjusted frequently to avoid an accumulation of
                    unapplied payments. Apart form these, we also update and
                    assist centers on payment while reducing massive paperwork
                    within your practice
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MDBContainer>
  );
};
export default Revenuesys;
