import React from "react";
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import medicalspeciality from "../img/Oursolutions/medical specialist 2.jpg";
import ambulatory from "../img/Oursolutions/ambulatory 1.svg";
import medicalspecialitygroup from "../img/Oursolutions/multi speciality 2.jpg";
import hospital from "../img/Oursolutions/hospital 1.jpg";
import lab from "../img/Oursolutions/lab 1.jpg";
import IT from "../img/Oursolutions/health it.jpg";
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function ConsultancyDesc() {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
const location = useLocation();

useEffect(() => {
  // Parse the URL parameter to get the contentId
  const searchParams = new URLSearchParams(location.search);
  const contentId = searchParams.get('contentId');
  const contentId1 = searchParams.get('contentId1');
  const contentId2 = searchParams.get('contentId2');
  const contentId3 = searchParams.get('contentId3');
  const contentId4 = searchParams.get('contentId4');
  const contentId5 = searchParams.get('contentId5');
  
  // Find the element with the specified contentId and scroll to it.
  const targetElement = document.getElementById(contentId);
  const targetElement1 = document.getElementById(contentId1);
  const targetElement2 = document.getElementById(contentId2);
  const targetElement3 = document.getElementById(contentId3);
  const targetElement4 = document.getElementById(contentId4);
  const targetElement5 = document.getElementById(contentId5);
  if (targetElement) {
    // Calculate the scroll position to show the entire content div
    const yOffset = -100; // Adjust this value as needed
    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
    const scrollToPosition = targetPosition + yOffset;

    // Scroll to the calculated position
    window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
  }
  if (targetElement1) {
 
    const yOffset1 = -100; // Adjust this value as needed
    const targetPosition1 = targetElement1.getBoundingClientRect().top + window.scrollY;
    const scrollToPosition1 = targetPosition1 + yOffset1;

    // Scroll to the calculated position
    window.scrollTo({ top: scrollToPosition1, behavior: 'smooth' });
  }
  if (targetElement2) {
    const yOffset2 = -100; // Adjust this value as needed
    const targetPosition2 = targetElement2.getBoundingClientRect().top + window.scrollY;
    const scrollToPosition2 = targetPosition2 + yOffset2;

    // Scroll to the calculated position
    window.scrollTo({ top: scrollToPosition2, behavior: 'smooth' });
  }
  if (targetElement3) {
    const yOffset3 = -100; // Adjust this value as needed
    const targetPosition3 = targetElement3.getBoundingClientRect().top + window.scrollY;
    const scrollToPosition3 = targetPosition3 + yOffset3;

    // Scroll to the calculated position
    window.scrollTo({ top: scrollToPosition3, behavior: 'smooth' });
  }
  if (targetElement4) {
    const yOffset4 = -100; // Adjust this value as needed
    const targetPosition4 = targetElement4.getBoundingClientRect().top + window.scrollY;
    const scrollToPosition4 = targetPosition4 + yOffset4;

    // Scroll to the calculated position
    window.scrollTo({ top: scrollToPosition4, behavior: 'smooth' });
  }
  if (targetElement5) {
    const yOffset5 = -100; // Adjust this value as needed
    const targetPosition5 = targetElement5.getBoundingClientRect().top + window.scrollY;
    const scrollToPosition5 = targetPosition5 + yOffset5;

    // Scroll to the calculated position
    window.scrollTo({ top: scrollToPosition5, behavior: 'smooth' });
  }
}, [location.search]);

  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <div className="row">
            <div className="card p-4">
          <div className="col-lg-12 mb-5">
          <div id="myUniqueContent" ref={ref}>
            <div className="row">
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Medical Specialists 
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                AcerHealth medical billing is not only skilled at managing the complexities of billing and claims processing, also has a thorough understanding of the unique billing issues. Medical specialists are the backbone of modern healthcare, specializing in a certain specialty to provide patients with competent solutions. The complexity of medical billing might vary greatly between various specialties ranging from coding complications to unique documentation needs. Our expertise and assistance assist doctors to concentrate on the medical needs of their patients while we handle the billing.
                </p>
              </div>
              <div className="col-md-4 text-end" width={50}>
              <img src={medicalspeciality} className="img-fluid" width={350} height={250} alt="Medical Billing Experts"/>
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-12 mb-5 d-none d-md-block" id="myUniqueContent1" ref={ref1}>
            <div className="row">
            <div className="col-md-4 text-start" width={50}>
              <img src={ambulatory} className="img-fluid" width={350} height={250} alt="Healthcare Credentialing Service"/>
              </div>
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Ambulatory Surgery Centers 
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                AcerHealth Ambulatory Surgery Centers differentiate themselves from regular hospital settings by specializing solely in same day surgeries that do not necessitate overnight stays. This not only decreases the financial burden on patients but it also contributes to overall healthcare delivery efficiency. Ambulatory Surgery Centers operate inside a different billing system that necessitates specialized knowledge to navigate properly. AcerHealth is a trustworthy medical billing agency that specializes in Ambulatory Surgery Centers billing and it becomes a beneficial partner for hospitals. 
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 d-md-none">
          <div id="myUniqueContent" ref={ref}>
            <div className="row">
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{ fontFamily: 'Alegreya Sans', fontWeight: 'bold' }}>
                Ambulatory Surgery Centers 
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{ fontSize: '18px', fontFamily: "Alegreya Sans", fontWeight: '500' }}>
                AcerHealth Ambulatory Surgery Centers differentiate themselves from regular hospital settings by specializing solely in same day surgeries that do not necessitate overnight stays. This not only decreases the financial burden on patients but it also contributes to overall healthcare delivery efficiency. Ambulatory Surgery Centers operate inside a different billing system that necessitates specialized knowledge to navigate properly. AcerHealth is a trustworthy medical billing agency that specializes in Ambulatory Surgery Centers billing and it becomes a beneficial partner for hospitals. 
                </p>
              </div>

              {/* Mobile view: Display image after content */}
              <div className="col-md-4 text-end" width={50}>
                <img src={ambulatory} className="img-fluid" width={350} height={250} alt="Medical Billing Experts" />
              </div>
            </div>
          </div>
        </div>
          <div className="col-lg-12 mb-5" id="myUniqueContent2" ref={ref2}>
            <div className="row">
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Multi-Specialty Groups  
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left"style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                AcerHealth Multi-specialty medical group identified itself as the symbol of trust in comprehensive and integrated healthcare. These groups unite medical professionals from various specialties under one roof. Thus, fostering collaboration, enhancing patient care, and maximizing efficiency. AcerHealth is an expert in the field of multi-specialty medical groups, which presents specific potential and challenges that call for a specialized approach to billing and revenue management. Multi-specialty medical groups offer a holistic approach to healthcare financial delivery.
                </p>
              </div>
              <div className="col-md-4 p-4 text-end" width={50}>
              <img src={medicalspecialitygroup} className="img-fluid" width={350} height={250} alt="Multi Speciality Group "/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 d-none d-md-block" id="myUniqueContent3" ref={ref3}>
            <div className="row">
            <div className="col-md-4 text-start p-2" width={50}>
              <img src={hospital} className="img-fluid" width={350} height={250} alt="Hospital Centers"/>
              </div>
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Hospital Centers 
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Hospitals remain as a pillar of medical excellence in the ever-changing healthcare sector, offering a broad spectrum of care, ranging from routine checkups to life-saving procedures. AcerHealth is a recognized specialist in medical billing. We play a key part in ensuring that hospitals can concentrate on providing excellent patient care while our professional team expertly handle the various financial processes that support their operations. Medical services are offered in hospitals in a variety of settings. The range of medical interventions is broad and diverse, encompassing everything from emergency rooms and operating rooms to inpatient care and specialty hospitals. AcerHealth caters to all such services. 
                </p>
              </div>  
            </div>
          </div>
           <div className="col-lg-12 mb-5 d-md-none">
          <div id="myUniqueContent" ref={ref}>
            <div className="row">
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{ fontFamily: 'Alegreya Sans', fontWeight: 'bold' }}>
                Hospital Centers  
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{ fontSize: '18px', fontFamily: "Alegreya Sans", fontWeight: '500' }}>
                Hospitals remain as a pillar of medical excellence in the ever-changing healthcare sector, offering a broad spectrum of care, ranging from routine checkups to life-saving procedures. AcerHealth is a recognized specialist in medical billing. We play a key part in ensuring that hospitals can concentrate on providing excellent patient care while our professional team expertly handle the various financial processes that support their operations. Medical services are offered in hospitals in a variety of settings. The range of medical interventions is broad and diverse, encompassing everything from emergency rooms and operating rooms to inpatient care and specialty hospitals. AcerHealth caters to all such services. 
                </p>
              </div>

              {/* Mobile view: Display image after content */}
              <div className="col-md-4 text-end" width={50}>
                <img src={hospital} className="img-fluid" width={350} height={250} alt="Medical Billing Experts" />
              </div>
            </div>
          </div>
        </div>
          <div className="col-lg-12 mb-5" id="myUniqueContent4" ref={ref4}>
            <div className="row">
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Laboratories 
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Modern healthcare depends frequently on laboratories, because they provide valuable diagnostic evidence that informs treatment strategies and medical decisions. They focus on providing accurate and quick test results in the complex realm of laboratories. One significant partner is Acerhealth, a well-known medical billing provider. Diagnostic services provided in laboratories ranges widely from simple blood testing to advanced genetic testing. This variety also affects the billing environment, where it's essential to have a thorough awareness of CPT codes, medical necessity, insurance rules, and compliance standards.
                </p>
              </div>  
              <div className="col-md-4 text-end p-3" width={50}>
              <img src={lab} className="img-fluid" width={350} height={250} alt="Laboratories"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5  d-none d-md-block" id="myUniqueContent5" ref={ref5}>
            <div className="row">
            <div className="col-md-4 text-start p-2" width={50}>
              <img src={IT} className="img-fluid" width={350} height={250} alt="Health IT"/>
              </div>
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                Health IT
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                In the digital era of healthcare, health information technology is changing how medical practices run, handle data, and treat patients. The health IT is the cornerstone of clarity, efficiency, and seamless collaboration that underpins the best medical billing systems. The term "Health IT" refers to a broad range of tools and systems created to improve the gathering, archiving, sharing, and analysis of health-related data. It is also essential for optimizing workflows, lowering errors, maintaining compliance, and enhancing overall financial performance in the field of medical billing.
                </p>
              </div>  
            </div>
          </div>
           <div className="col-lg-12 mb-5 d-md-none">
          <div id="myUniqueContent" ref={ref}>
            <div className="row">
              <div className="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{ fontFamily: 'Alegreya Sans', fontWeight: 'bold' }}>
                Health IT
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{ fontSize: '18px', fontFamily: "Alegreya Sans", fontWeight: '500' }}>
                In the digital era of healthcare, health information technology is changing how medical practices run, handle data, and treat patients. The health IT is the cornerstone of clarity, efficiency, and seamless collaboration that underpins the best medical billing systems. The term "Health IT" refers to a broad range of tools and systems created to improve the gathering, archiving, sharing, and analysis of health-related data. It is also essential for optimizing workflows, lowering errors, maintaining compliance, and enhancing overall financial performance in the field of medical billing.
                </p>
              </div>

              {/* Mobile view: Display image after content */}
              <div className="col-md-4 text-end" width={50}>
                <img src={IT} className="img-fluid" width={350} height={250} alt="Medical Billing Experts" />
              </div>
            </div>
          </div>
        </div>
          
        </div>
        </div>
      </div>
    </MDBContainer>
  );
}
