import React from 'react';
import {MDBContainer,} from "mdb-react-ui-kit";
import "../css/servicesfont.css";
const StartPractice = () => {
    return (
        <MDBContainer fluid className="p-0">
        <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-12">
                  <div className="section-title text-center mb-4 pb-2">
                      <div className="title mb-4 display-heading AcerhealthTextPurple fomtsizetest" style={{fontFamily:'Lora, serif'}}>Contracts and Compliance</div>
                      <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>Helping Your Practice Work Seamlessly</p>
                  </div>
              </div>
          </div>
        </div>    
        </MDBContainer>
    );
};
export default StartPractice;



