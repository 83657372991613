import React, { useEffect, useState } from 'react';
import Banner from '../components/img_banner';
import RCMcy from '../img/banners/medicalspecialist.jpg';
import Solutionsbilling from '../components/solutionsmedicalbillling';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const Solutions = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Multi-Speciality & Ambulatory Medical Billing | AcerHealth';

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Our Solutions');


  const [BannerTitle, setBannerTitle] = useState({ subtitle: "Medical Professionals can Focus on Treating Patients’ Ailment while We Handle Medical Billings.", img: `url(${RCMcy})` });


  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({ title: "Generate iquidity for the work you do. Our highly experienced staff creates revenue prospects for medical practices that result in significant and predictable reimbursements. " });



  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://acerhealth.com/medical-billing-specialities" />
        <meta name="description" content="We manage medical billing for different specialties, using advanced technology to improve healthcare. Contact us for more info." />
        <meta name="keywords" content="Medical Billing Speacialities" />
      </Helmet>
      {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
      <Banner BannerTitle={BannerTitle} />
      <Solutionsbilling />
    </>
  );
};

export default Solutions;
