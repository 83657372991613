import React from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import Cardiology from "../img/Landing page device mockup.svg";
import "../css/specilities.css";
export default function App() {
  return (
    <MDBContainer fluid className="p-0 bg-white">
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="section-title pb-2">
              {/* <div className="title mb-4 display-heading AcerhealthTextPurple text-center" style={{fontSize:'45px',fontFamily:'EBGarmond semi bold',fontWeight:'bold'}}> */}
                <h2 className="title mb-4 display-heading AcerhealthTextPurple text-center speciality" style={{fontFamily:'Lora, serif',fontWeight:'bold'}}>Medical Billing Specialities</h2>
              {/* </div> */}
              <p className="para-desc mx-auto mb-0 justify-content-left speciality1" style={{fontFamily: "Alegreya Sans",fontWeight:'500' }} >
              Managing every phase of the revenue cycle with precision and expertise is our commitment. From the initial steps of patient registration and insurance eligibility verification to the seamless progression through charge capture and claim submission, we ensure a streamlined process. Our comprehensive suite of services includes specialized Revenue Cycle Management (RCM) solutions catering to a diverse range of medical specialties. With our dedicated approach, we aim to optimize the financial aspects of healthcare operations, allowing our clients to focus on delivering quality patient care.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="row mb-4">
          <div className="col-lg-10 col-md-6 col-12">
              <div className="content mt-3 text-center"> */}
                <div className="text-center">
                  <img class="img-fluid profile" src={Cardiology} alt="Cardiology"/>
                </div>
              
        <div className="row text-center">
        <a href="/Cardologyspeciality">
      <MDBBtn style={{background:'#3B0075'}}>Learn More</MDBBtn>
        </a>
        </div>
      </div>
    </MDBContainer>
  );
}
