import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardText,
} from "mdb-react-ui-kit";
import React from "react";
import CEOimage from "../img/JaiKumar.jpg";

const AboutCEO = () => {
  return (
    <MDBContainer fluid className='p-0'>
    <div className="container py-5">
        <section>
           <MDBRow className="">
            <MDBCol md="3" className="mb-4">
              <img src={CEOimage} className="img-fluid rounded-circle" alt="Jai Kumar Acerhealth CEO"/>
            </MDBCol>
            <MDBCol md="8 pt-5 text-start mx-2">
              <div className="AcerhealthTextPurple h3" style={{fontFamily: "Alegreya Sans",fontWeight:'bold' }}> Jai Kumar </div>
              <span className="h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold' }}>
                CEO, AcerHealth Inc.
              </span>
              <br />
              <span style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>+1 (703) 399-6118</span>
              <br />
              <span style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>jai@acerhealth.com</span>
              <p className="mt-3" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>
                Jai Kumar, the Founder and CEO of AcerHealth, is an information
                technology professional with more than 21 years of experience.
                He has earned advanced degrees in computer science from the
                Indian Institute of Technology. Before founding AcerHealth, Jai
                was an executive with several Fortune 500 companies.
              </p>
            </MDBCol>
          </MDBRow>
        </section>
      </div>
    </MDBContainer>
  );
};

export default AboutCEO;
