import React, { useState } from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBNavbarLink 
} from "mdb-react-ui-kit";
import ContactModal from '../modals/modal_contact';
import Modalpartner from '../modals/modal_becomepartner';
import ModalJoinus from '../modals/modal_joinus';
import ModalConsalt from '../modals/modal_freeconsaltant';
import ModalInfo from '../modals/modal_info';
import { Link } from 'react-router-dom';

const contentId = 'myUniqueContent';
const contentId1 = 'myUniqueContent1';
const contentId2 = 'myUniqueContent2';
const contentId3 = 'myUniqueContent3';
const contentId4 = 'myUniqueContent4';
const contentId5 = 'myUniqueContent4';
  
const btncolor = {
  background: "#582C83",
};
export default function App() {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
    const handleCloseModal = () => {
    setBasicModal(false);
  };

  const [basicModalpartner, setBasicModalPartner] = useState(false);
  const toggleShowpartner = () => setBasicModalPartner(!basicModal);
    const handleCloseModalPartner = () => {
      setBasicModalPartner(false);
  };

  const [basicModaljoinus, setBasicModalJoinus] = useState(false);
  const toggleShowjoinus = () => setBasicModalJoinus(!basicModal);
    const handleCloseModalJoinus = () => {
      setBasicModalJoinus(false);
  };
  const [basicModalConsalt, setBasicModalConsalt] = useState(false);
  const toggleShowConsalt = () => setBasicModalConsalt(!basicModalConsalt);
    const handleCloseModalConsalt = () => {
      setBasicModalConsalt(false);
  };
  const [basicModalInfo, setBasicModalInfo] = useState(false);
  const toggleShowInfo = () => setBasicModalInfo(!basicModalInfo);
    const handleCloseModalInfo = () => {
      setBasicModalInfo(false);
  };
  const buttonStyle = {
    color: '#0f8a07', // Text color property
  };
  return (
    <MDBFooter className="text-center text-lg-start AcerhealthPurple text-light">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 ">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            {/* address */}
            <MDBCol sm="12" md="12" lg="3" className="mx-auto mb-4">
              <div>
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p className="text-reset mb-2 " style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>
                  <MDBIcon fas icon="map-marker-alt" className="me-2" style={{paddingRight:'5px'}}/>AcerHealth Inc. 
                  <p style={{paddingLeft:'25px'}}>3300 Southern Walk Plz Ste 116-804 Ashburn 20148 </p>
                  
                </p>
              <p className="text-reset mb-3" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>
                <MDBIcon fas icon="phone" className="me-2"/> +1 (888) 666-1912
              </p>
              <p className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>
                <MDBIcon fas icon="envelope" className="me-2"/> info@acerhealth.com
              </p>
              <div className="mt-3">
                <a
                  href="https://goo.gl/maps/aqgxVfTTnSaZmVJ66"
                  target="_blank"
                  className="text-reset"
                  button
                  type="button"
                >
                  <MDBBtn outline color="secondary" className="text-light">
                    <MDBIcon fas icon="route" className="me-2" /> View On Map
                  </MDBBtn>
                </a>
              </div>
              </div>
            
            </MDBCol>
             
           {/* other links */}
            <MDBCol sm="12" md="12" lg="3" className="mx-auto mb-2">
              <h6 className="text-uppercase fw-bold mb-4" style={{fontFamily: 'EBGarmond semi bold, Helvetica'}}>Acer Solutions</h6>
              <p>
                <a href={`/medical-billing-specialities?contentId=${contentId}`} className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>Medical Specialists</a>
                <br></br>
                <a href={`/medical-billing-specialities?contentId1=${contentId1}`} className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>Ambulatory Surgery Centers</a>
                <br></br>
                <a href={`/medical-billing-specialities?contentId2=${contentId2}`} className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>Multi-Specialty Groups</a>
                <br></br>
                <a href={`/medical-billing-specialities?contentId3=${contentId3}`} className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>Hospitals</a>
                <br></br>
                <a href={`/medical-billing-specialities?contentId4=${contentId4}`} className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>Laboratories</a>
                <br></br>
                <a href={`/medical-billing-specialities?contentId5=${contentId5}`} className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>Health IT</a>
                <br></br>
                <hr className='w-75'></hr>
                
                <h6 className="text-uppercase fw-bold mb-4" style={{fontFamily: 'EBGarmond semi bold, Helvetica'}}>Company</h6>

    <MDBNavbarLink outline onClick={toggleShowConsalt} style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>
    Free Consultation &nbsp;
    </MDBNavbarLink>
    <ModalConsalt show={basicModalConsalt} onClose={handleCloseModalConsalt} />
    <MDBNavbarLink outline onClick={toggleShowInfo} style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>
    Schedule a Free Demo &nbsp;
    </MDBNavbarLink>
    <ModalInfo show={basicModalInfo} onClose={handleCloseModalInfo} />
    <a href="/Testimonials" className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>
                  Testimonials
                </a>
                <br></br>
                <a href="/medical-billing-blogs" className="text-reset" style={{fontFamily: 'Alegreya Sans',fontSize:'18px'}}>
                  Blogs
                </a>
              </p>
            </MDBCol>

            {/* our service */}
            <MDBCol sm="12" md="12" lg="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4" style={{fontFamily: 'EBGarmond semi bold, Helvetica'}}>Acer Services</h6>
              <p style={{fontFamily: 'EBGarmond semi bold, Helvetica',fontSize:'17px'}}>
              <a href="/services/medical-billing-services" className="text-reset">Medical Billing Services</a>
                <br></br>
                <a href="/services/medical-consultancy-services" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Medical Consultancy Services</a>
                <br></br>
                <a href="/services/medical-billing-and-credentialing" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Credentialing Services</a>
                <br></br>
                <a href="/services/revenue-cycle-management" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Revenue Cycle Management</a>
                <br></br>
                <a href="/services/set-up-practice" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Set Up a Practice</a>
                <br></br>
                <a href="/services/contract-compliance" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Contracts and Compliance</a>
              
              <hr className='w-75'></hr>
              <h6 className="text-uppercase fw-bold mb-4" style={{fontFamily: 'EBGarmond semi bold, Helvetica'}}>Acer Technologies</h6>

              <a href="/technologies/practice-analytics" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Practice Analytics</a>
                <br></br>
                <a href="/technologies/patient-balance-estimator" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Patient Balance Estimator</a>
                <br></br>
                <a href="/technologies/patient-eligibility-verification" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Patient Eligibility Verification</a>
                <br></br>
                <a href="/technologies/patient-statements-payment-portal" className="text-reset" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>Patient Statements & Payment Portal</a>
                <br></br>
                </p>
            </MDBCol>
            <MDBCol sm="12" md="12" lg="3">
              <h6 className="text-uppercase fw-bold mb-4" style={{fontFamily: 'EBGarmond semi bold, Helvetica'}}>Our Hours</h6>
              <p className="w-75">
                <span className="text-reset">
                  <span style={{ float: "left",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Monday</span>
                  <span style={{ float: "right",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>8:30am - 5:00pm</span>
                </span>
                <br></br>
                <span className="text-reset">
                  <span style={{ float: "left",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Tuesday</span>
                  <span style={{ float: "right",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>8:30am - 5:00pm</span>
                </span>
                <br></br>
                <span className="text-reset">
                  <span style={{ float: "left",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Wednesday</span>
                  <span style={{ float: "right",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>8:30am - 5:00pm</span>
                </span>
                <br></br>
                <span className="text-reset">
                  <span style={{ float: "left",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Thursday</span>
                  <span style={{ float: "right",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>8:30am - 5:00pm</span>
                </span>
                <br></br>
                <span className="text-reset">
                  <span style={{ float: "left",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Friday</span>
                  <span style={{ float: "right",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>8:30am - 5:00pm</span>
                </span>
                <br></br>
                <span className="text-reset">
                  <span style={{ float: "left",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Saturday</span>
                  <span style={{ float: "right",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Closed</span>
                </span>
                <br></br>
                <span className="text-reset">
                  <span style={{ float: "left",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Sunday</span>
                  <span style={{ float: "right",fontFamily: 'Alegreya Sans',fontSize:'16px'}}>Closed</span>
                </span>
                <br></br><br></br>
                <MDBBtn outline color='secondary' className='text-light' onClick={toggleShow}>
                  Contact Us
              </MDBBtn>
              <ContactModal show={basicModal} onClose={handleCloseModal} />
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <div className="text-center p-4 " style={{ color: "#582C83" }}>
        <a
          class="btn btn-light btn-floating btn-lg m-1"
          href=" https://www.facebook.com/acerhealth1"
          style={{ color: "#582C83" }}
        >
          <i className="fab fa-2x fa-facebook-f"></i>
        </a>
        <a
          class="btn btn-light btn-floating btn-lg m-1"
          href="https://twitter.com/AcerhealthL"
          style={{ color: "#582C83" }}
        >
          <i className="fab fa-2x fa-twitter"></i>
        </a>
        <a
          class="btn btn-light btn-floating btn-lg m-1"
          href="https://www.linkedin.com/company/acerhealth-inc/?viewAsMember=true"
          style={{ color: "#582C83" }}
        >
          <i className="fab fa-2x fa-linkedin-in"></i>
        </a>
        <a
          class="btn btn-light btn-floating btn-lg m-1"
          href= "https://www.instagram.com/acerhealth_/"
          style={{ color: "#582C83" }}
        >
          <i className="fab fa-2x fa-instagram"></i>
        </a>
      </div>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        © 2023 Copyright | <b>AcerHealth Inc</b>
      </div>
    </MDBFooter>
  );
}
