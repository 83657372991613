import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import yourchalenge from "../img/challange.png";
import ourservice from "../img/services.png";
import ourapproch from "../img/approach.png";
import benifits from "../img/benifits.png";
import "../css/servicesfont.css";

export default function CredentialingDesc() {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="card p-2 pb-0" style={{backgroundColor:'#B777FF',opacity:'100%'}}>
            <div className="row pt-4">
              <div class="col-md-2 text-center">
                <img src={yourchalenge} height="200px" with="200" alt="Challange"/>
              </div>
              <div class="col-md-10">
                <h4 className="title fontsizeset2" style={{color:'black',fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Your Challenge</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left pb-4"  style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500',color:'black' }}>
                Credentialing and provider enrollment experts know it takes a lot of time, and organization to navigate the complex credentialling process with the insurance companies, The practice can face serious reimbursement issues if the credentialling is not completed in a timely manner. You have to spend significant amount time in your follow up of the credentialling status with the insurances. During this process you will either hear calls dropped or leave a message that is never returned. For a practice, keeping up with all the provider credentialing requirements and paperwork can be quite overwhelming
                </p>
              </div>
            </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
          <div className="card p-2" style={{backgroundColor:'#BFD200',opacity:'100%'}}>
            <div className="row pt-4">
              <div class="col-md-2 mb-2 mt-4 p-2 text-center ">
                <img src={ourservice} height="200px" with="200" alt="Insurance Verification services"/>
              </div>
              <div class="col-md-10">
                <h4 className="title fontsizeset2" style={{color:'black',fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Our Service</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{color:'black',fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Insurance companies require providers to update their credentials on a regular basis. If updates are not completed in a timely manner, claims may get denied.
                </p>
                <p className="mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500',color:'black' }}>
                We customize our services based on your needs. Below is a transient listing of many of our credentialing services offered:
                </p>
                <div class="col"  style={{color:'black',fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                  <ul class="service_list">
                    <li style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                    New provider enrollment (All Payers/Multiple States)
                    </li>
                    <ul class="service_list" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                      <li style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Commercial insurance credentialing</li>
                      <li style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Medicaid Provider Enrollment (including HMO's)</li>
                    </ul>
                    <li style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Professional License Renewal</li>
                    <li style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>PECOS/ I&A updates</li>
                    <li style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Facility Privileging</li>
                    <li style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>NPI Registration (Type I and Type II)</li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
          <div className="card p-2" style={{backgroundColor:'#FFA034',opacity:'100%'}}>
            <div className="row pt-4">
              <div class="col-md-2 mb-2 mt-2 p-2 text-center ">
                <img src={ourapproch} height="200px" with="200" alt="Our Approach"/>
              </div>
              <div class="col-md-10">
                <h4 className="title fontsizeset2"  style={{color:'black',fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Our Approach</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className=" mb-0 justify-content-left"  style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500',color:'black' }}>
                Management is often unaware that provider credentials haven't been updated or invalidated with a specific payer. At Acer, we use a systematized set of verification protocols with every new client to make sure all provider credentials are verified in a timely manner.
                </p>
                <div class="col ml-2"  style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500',color:'black' }}>
                  <ul class="approach_list">
                    <li className=" mb-0 justify-content-left" sstyle={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                    Expiration dates for all important provider credentials can are tracked and monitored
                    </li>
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                    All providers credentials databases are organized and well maintained
                    </li>
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                    Our credentialing experts contact each insurance to authenticate the provider's effective dates
                    </li>
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                      {" "}
                    Group and provider data remain strictly confidential and are only released to authorized parties
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
          <div className="card p-2" style={{backgroundColor:'#B6CCFE',opacity:'100%'}}>
            <div className="row pt-4">
              <div class="col-md-2 mb-2 mt-4 p-2 text-center ">
                <img src={benifits} height="200px" with="200" alt="Outsourcing Benifits"/>
              </div>
              <div class="col-md-10">
                <h4 className="title fontsizeset2"  style={{color:'black',fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Benefits</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className=" mb-0 justify-content-left"  style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500',color:'black' }}>
                  Outsourcing credentialing has a lot of benefits:
                </p>
                <div class="col ml-2"  style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500',color:'black' }}>
                  <ul class="benefits">
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Improved cash flow</li>
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Our years of expertise ensure that your credentialling will be completed with all major payers within 60 to 90 days.</li>
                    
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Faster responses to queries or issues</li>
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                      Eliminate accounting errors that delay the enrollment
                      process
                    </li>
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                      Reduce the amount of time you spend making phone calls,
                      submitting applications, and dealing with emails and
                      faxes
                    </li>
                    
                    <li className=" mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                      Improves turnaround time, usually within 60 days, by
                      keeping the organization compliant with insurance payers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
}
