import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBBtn,
    MDBInput,
    MDBIcon,
    MDBContainer,
} from "mdb-react-ui-kit";
import '../css/smaller.css';

const IMGBanner = ({BannerTitle}) => {
    return (
        <MDBContainer fluid className='p-0'>
            <header>
                <div
                    className='p-5 text-center bg-image'
                    style={{ backgroundImage: BannerTitle.img,
                        minHeight: "500px",
                        backgroundPosition: "top",
                        backgroundRepeat: "repeat",
                         backgroundPositionX:'75%',
                         backgroundPositionY:'25%',

                 }}
                >       <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                    <div className='row justify-content-center' style={{marginTop:'200px'}}>
                    <div className='col-12 col-lg-8 text-white'>
                            <h1 className='display-4' style={{fontFamily: 'Alegreya Sans'}}>
                            {BannerTitle.title}
                            </h1>
                            <p className='lead head-para'>
                            {BannerTitle.subtitle}
                            </p>
                        </div>
                        </div>
                        </div>
                        
                </div>
                </header>
        </MDBContainer>
    ); 
};
export default IMGBanner;


