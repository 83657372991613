import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBInput,
    MDBCheckbox,
    MDBModal,
    MDBModalDialog ,
    MDBModalContent ,
    MDBModalBody ,
    MDBTextArea ,
  } from "mdb-react-ui-kit";
  import React from "react";

  const ContactModal = ({ show, onClose }) => {
  
  return (
    <MDBModal tabIndex='-1' show={show} onHide={onClose}>
        <MDBModalDialog style={{ maxWidth: '800px' }}>
          <MDBModalContent>
           <MDBModalBody>
    <MDBCard>
    <MDBCardBody>
    <MDBCardTitle className="AcerhealthTextPurple text-center mb-3" style={{fontFamily: 'EBGarmond semi bold, Helvetica',fontWeight:'bold' }}>
    Become a Partner
          </MDBCardTitle>
    <MDBRow>
    <MDBCol>
      <MDBRow>
        <MDBCol>
    <MDBInput label='First Name' id='form1' type='text'/>
    </MDBCol>
    <MDBCol>
    <MDBInput label='Last Name' id='form1' type='text'/>
    </MDBCol>
    </MDBRow>
    <br></br>
    </MDBCol>
    <MDBCol>
    <MDBInput label='Phone No.' id='form1' type='text' />
    </MDBCol>
    </MDBRow>
    <br></br>
    <MDBRow>
    <MDBCol>
    <MDBInput label='Business Name' id='form1' type='text' />
    </MDBCol>
    <MDBCol>
    <MDBInput label='Primary Offerings' id='form1' type='text' />
    </MDBCol>
    </MDBRow>
    <br></br>
    <br></br>
    <MDBRow>
    <MDBCol>
    <MDBInput label='Email ID' id='form1' type='text' />
    </MDBCol>
    <MDBCol>
    <MDBTextArea label='Your Message' id='form1' type='text'/>
    </MDBCol>
    </MDBRow>
    <br></br>
    <MDBRow>
    <MDBCol>
    <MDBInput label='Business Website' id='form1' type='text' />
    </MDBCol>
    <MDBCol>
    
    </MDBCol>
    </MDBRow>
    <br></br>
    <MDBRow className="justify-content-center">
    <MDBBtn style={{marginTop:'15px',backgroundColor: "#3B0075",width:'200px',height:'40px'}}>
          Send Message
        </MDBBtn>
    </MDBRow>
    
    </MDBCardBody>
    </MDBCard> 
    </MDBModalBody>
    </MDBModalContent>
    </MDBModalDialog>
    </MDBModal>
        );
  };
  
  export default ContactModal;