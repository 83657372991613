import React,{useRef} from "react";
import { MDBBtn } from 'mdb-react-ui-kit';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBRipple,
} from "mdb-react-ui-kit";
import   "../css/circle.css";
 import smartreportBanner from "../img/technologies/Infographic/payment statement and patient portal.png";
 import smartreportBanner1 from "../img/technologies/Infographic/Final patient statements and payment portal.png";
 import comunicate from "../img/communicate.svg";
 import digitalpayment from "../img/embrace digital payment.svg";
 import textreminder from "../img/send text reminders.svg";
 import "../css/servicesfont.css";
const Home = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick1 = () => {
    ref1.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick2 = () => {
    ref2.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <MDBContainer fluid className="bg-light p-0">
      <div className="container py-5">

<div class="button-overlay1 mb-4 mt-4 d-none d-lg-block" style={{paddingLeft:'420px'}}>
<img
                className="img-fluid"
                src={smartreportBanner}
                style={{ borderRadius: "10px",width:'60%'}}
                alt="Patient statement and payment portal"
              />
            <MDBRow>
 <MDBBtn color='link' rippleColor='dark' onClick={handleClick1} style={{ fontSize: '15px',color:'white',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}} className="button-overlay1">
      Digital Payments
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick} style={{ fontSize: '15px',color:'white',fontFamily:'Alegreya Sans',width:'210px',height:'160px',
    left: '45%',top:'160px', backgroundColor: 'transparent'}}>
     Communication
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick2} style={{ fontSize: '15px',color:'white',width:'210px',left: '52%',top:'350px',height:'160px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}}>
     text reminders
    </MDBBtn>
    </MDBRow>
</div>

<div class="button-overlay1 mb-4 mt-4 d-lg-none">
<img
                className="img-fluid"
                src={smartreportBanner1}
                style={{ borderRadius: "10px",width:'100%'}}
                alt="Patient statement and payment portal"
              />
</div>


      <div ref={ref} className="card mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5 p-4 d-md-none">
            <div className="row">
            <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Communicate the Way Your Patients Prefer</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                E-delivering patient statements to those who prefer to communicate via email. This method reduces the postage and the time taken to receive the patient’s statements. In addition, the solution also makes it easier for the patient to pay immediately.

                </p>
              </div>
              <div class="col-md-4 text-center mt-4">
              <img src={comunicate} className="img-fluid" width={350} height={250} alt="Patient Statement"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 p-4 d-none d-md-block">
            <div className="row">
              <div class="col-md-4 text-center mt-4">
              <img src={comunicate} className="img-fluid" width={350} height={250} alt="Patient Statement"/>
              </div>
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Communicate the Way Your Patients Prefer</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                E-delivering patient statements to those who prefer to communicate via email. This method reduces the postage and the time taken to receive the patient’s statements. In addition, the solution also makes it easier for the patient to pay immediately.

                </p>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div ref={ref1} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Embrace Digital Payments</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                We always keep the track of the changing trend and technology in the healthcare industry. Not just that, we offer the ability to patients to make payments over the phone or through any other digitally connected devices. With the AcerHealth Patient Payment Portal, your patients’ checks or credit card payments go straight to your associated bank account.
                </p>
              </div>
              <div class="col-md-4 text-center ">
              <img src={digitalpayment} className="img-fluid" width={350} height={250} alt="Embrace Digital Payment"/>
              </div>
            </div>
          </div>
          </div>
   </div>
     
      <div ref={ref2} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5 d-md-none">
            <div className="row">
            <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Send Text Reminders</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Increase patient payments by sending statement balance due reminders via phone text messages.
                </p>
              </div>
            <div class="col-md-4 text-center ">
              <img src={textreminder} className="img-fluid" width={350} height={250} alt="Send text reminders"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 d-none d-md-block">
            <div className="row">
            <div class="col-md-4 text-center ">
              <img src={textreminder} className="img-fluid" width={350} height={250} alt="Send text reminders"/>
              </div>
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Send Text Reminders</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Increase patient payments by sending statement balance due reminders via phone text messages.
                </p>
              </div>
              
            </div>
          </div>
          </div>
      </div>
    </div>
    </MDBContainer>
  );
};

export default Home;
