import React, { useEffect, useState } from 'react';
import { MDBContainer, } from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/banner';
import EligibilityInfo from '../components/tech_eligibility_info';
import DownContact from '../components/downcontact';
import RCMcy from '../img/technologies/patientbalanceestimator.jpg';
import Info from '../components/patienbalanceestimator';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const Eligibility = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Patients Balance Estimation | AcerHealth';
    
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set Breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Eligibility');

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({ title: "", subtitle: "", img: `url(${RCMcy})` });


  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({ title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER", subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." });

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://acerhealth.com/technologies/patient-balance-estimator" />
        <meta name="description" content="Gain clarity on collecting patient balances. Our in-house expert estimator swiftly handles insurance and price calculations without delays." />
        <meta name="keywords" content="Patient Balance estimator" />
      </Helmet>
      {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
      <Banner BannerTitle={BannerTitle} />
      <Info />
      <EligibilityInfo />
      {/* <DownContact DownContactTitle = {DownContactTitle}  /> */}
    </>

  );
};

export default Eligibility;