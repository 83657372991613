import React, { useRef, useState, useEffect } from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBModalBody,
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBInput,
  MDBCheckbox,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBIcon,
  MDBCol,
  MDBTextArea,
  MDBSpinner
} from "mdb-react-ui-kit";
import axios from "axios";

export default function App() {
  const [Selectedfirstname, setfirstname] = useState("");
  const [selectedemail, setemail] = useState("");
  const [selectedphone, setcity] = useState("");
  const [selectedmessage, setmessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const usPhoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const handlefirstnamechange = (event) => {
    setfirstname(event.target.value);
  };
  const handlechangeemail = (event) => {
    setemail(event.target.value);
  };
  const handlechangephone = (event) => {
    setcity(event.target.value);
  };
  const handlechangemessage = (event) => {
    setmessage(event.target.value);
  };

  const [errors, setErrors] = useState({
    firstname: '',
    email: '',
    phone: '',
    message: '',
  });

  const clearSuccessMessage = () => {
    setSuccessMessage("");
  };
  
  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };
  
    if (!Selectedfirstname.trim()) {
      newErrors.firstname = 'Name is required';
      valid = false;
    } else {
      newErrors.firstname = '';
    }
  if (!selectedemail.trim()) {
    newErrors.email = 'Email is required';
    valid = false;
  } else if (!emailRegex.test(selectedemail)) {
    newErrors.email = 'Invalid email address';
    valid = false;
  } else {
    newErrors.email = '';
  }
    if (!selectedphone) {
      newErrors.phone = 'Phone number is required';
      valid = false;
    } else if (!usPhoneRegex.test(selectedphone)) {
      newErrors.phone = 'Invalid US phone number';
      valid = false;
    } else {
      newErrors.phone = '';
    }
    if (!selectedmessage.trim()) {
      newErrors.message = 'Message is required';
      valid = false;
    } else {
      newErrors.message = '';
    }
  
    // You can add more validation rules for email, phone, etc.
  
    setErrors(newErrors);
    return valid;
  };
  const handleInsertion = () => {
    if (validateForm()) {
      setIsLoading(true);
    const formData = new FormData();
    // Add other form fields to formData
    formData.append("name", Selectedfirstname);
    formData.append("email", selectedemail);
    formData.append("message", selectedmessage);
    formData.append("phone", selectedphone);

    console.log(formData);
    // Make a POST request to your PHP insertion API
    axios
      .post("https://mdbill.io/websiteemail/testfile.php", formData)
      .then((response) => {
      })
      .catch((error) => {
        setfirstname('');
        setemail('');
        setcity('');
        setmessage('');
        setSuccessMessage("Form submitted successfully");
        setTimeout(clearSuccessMessage, 3000);
        setIsLoading(false);
        console.error(error);
      });
    }
};


  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <div className="row mb-4">
          <div className="col-lg-6 col-md-6 col-12 mb-4">
            <div className="card rounded service-wrapper1 rounded border-0 shadow p-4">
              <div className="content mt-5 text-center">
                <div className="display-8">
                  <i
                    class="fas fa-envelope-open-text"
                    style={{
                      fontSize: "65px",
                      color: "#3B0075",
                      float: "center",
                    }}
                  ></i>
                </div>
                <h3
                  className=" mt-3 mb-0 display-9 fontsizeset3"
                  style={{
                    fontWeight: "bold",
                    color: "#3B0075",
                    fontFamily:'Alegreya Sans',fontWeight:'bold' 
                  }}
                >
                  Email Us
                </h3>
                <p className="fontsizeset3" style={{ fontWeight: "bold", color: "#3B0075",fontFamily:'Alegreya Sans, Helvetica'}}>
                  info@acerhealth.com
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div className="card rounde service-wrapper1 rounded border-0 shadow p-4">
              <div className="content mt-5 text-center">
                <div className="display-8">
                  <i
                    class="fas fa-phone-square"
                    style={{
                      fontSize: "65px",
                      color: "#3B0075",
                      float: "center",
                    }}
                  ></i>
                </div>
                <h3
                  className=" mt-3 mb-0 display-9 fontsizeset3"
                  style={{
                    fontWeight: "bold",
                    color: "#3B0075",
                    fontFamily:'Alegreya Sans',fontWeight:'bold' 
                  }}
                >
                  Call Us
                </h3>
                <p className="fontsizeset3" style={{ fontWeight: "bold", color: "#3B0075",fontFamily:'Alegreya Sans, Helvetica'}}>
                  +1 (888) 666-1912
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-sm-6 col-12">
            <div className="card rounded service-wrapper1 rounded border-0 shadow p-4">
              <div className="content text-center">
                <div className="display-8">
                  <i
                    class="fas fa-map-marker-alt"
                    style={{
                      fontSize: "65px",
                      color: "#3B0075",
                      float: "center",
                    }}
                  ></i>
                </div>
                <h3
                  className=" mt-3 mb-0 display-9 fontsizeset3"
                  style={{
                    fontWeight: "bold",
                    color: "#3B0075",
                    fontFamily:'Alegreya Sans',fontWeight:'bold' 
                  }}
                >
                  Our Office
                </h3>
                <p className="fontsizeset3" style={{ fontWeight: "bold", color: "#3B0075" ,fontFamily:'Alegreya Sans, Helvetica'}}>
                  AcerHealth Inc. 43300 Southern Walk Plz Ste 116-804, Ashburn VA, 20148, USA
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-4">
          <div className="col-lg-6">
            <div style={{ color: "#3B0075" }}>
              <div>
                <h1 className="fontsizeset4" style={{fontFamily:'Alegreya Sans' }}>We are here</h1>
              </div>
              <div>
                <h6 className="fontsizeset5" style={{fontFamily:'Alegreya Sans'}}>to provide you</h6>
              </div>
              <div>
                <h1 className="fontsizeset6" style={{fontFamily:'Alegreya Sans' }}>
                  Best Medical Billing Services
                </h1>
              </div>
            </div>
            <div className="pt-4">
              <p className="justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                AcerHealth is delighted to announce our Free Medical Consulting
                Services, which are dedicated to providing you with expert
                healthcare guidance at no extra cost. Our objective is to ensure
                that everyone, regardless of background or financial situation,
                has access to reliable medical advice.
              </p>
              <p className="justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                Our team of experienced medical experts, including doctors and
                specialists, is available to you. Whether you have questions
                about a recent diagnosis, need clarification on treatment
                options, or are looking for general health information please
                reach out us.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card bg-glass">
              <div className="card-body px-4 py-5 px-md-5">
                    <MDBInput
                      className="mb-3"
                      id="first2"
                      label="Name"
                      name="name"
                      onChange={handlefirstnamechange} value={Selectedfirstname}
                      autocomplete="off"
                    />
                     {errors.firstname && <div className="error-message"style={{color:'red'}}>{errors.firstname}</div>}
                    <MDBInput
                      className="mb-3"
                      type="email"
                      id="email2"
                      label="Email ID"
                      name="email"
                      onChange={handlechangeemail} value={selectedemail}
                      autocomplete="off"
                      
                    />
                    {errors.email && <div className="error-message"style={{color:'red'}}>{errors.email}</div>}
                    <MDBInput
                      className="mb-3"
                      type="phone"
                      id="phone"
                      label="Phone No."
                      name="phone"
                      onChange={handlechangephone} value={selectedphone}
                      autocomplete="off"
                      
                    />
                    {errors.phone && <div className="error-message"style={{color:'red'}}>{errors.phone}</div>}
                    <MDBTextArea
                      className="mb-3"
                      label="Message"
                      id="textAreaExample"
                      rows={4}
                      name="messagefield"
                      onChange={handlechangemessage} value={selectedmessage}
                      autocomplete="off"
                    />
                    {errors.message && <div className="error-message"style={{color:'red'}}>{errors.message}</div>}
                    <MDBRow className="mb-4 justify-content-center">
                      <MDBCol md="12" className="d-flex justify-content-center">
                        <MDBCheckbox
                          className=" mb-3 mb-md-0"
                          defaultChecked
                          label="On submiting I authorize AcerHealth to contact me on above details."
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBBtn
                      type="submit"
                      className="mb-4 d-grid gap-2 col-5 mx-auto"
                      style={{ backgroundColor: "#3B0075" }}
                      onClick={handleInsertion}
                    >
                   {isLoading ? (
              <div className="d-flex align-items-center">
                <strong>Sending...</strong>
                <MDBSpinner className="ms-auto" size='sm' role="status" />
              </div>
            ) : (
              "Submit"
            )}
                    </MDBBtn>
              </div>
              {successMessage && (
  <div className="alert alert-success">{successMessage}</div>
)}
            </div>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
}
