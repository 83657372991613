import React, { useRef, useState, useEffect  } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBCardTitle,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBRadio,
  MDBSpinner,
} from "mdb-react-ui-kit";
import Select from "react-select";
import ReactPlayer from "react-player";
import axios from "axios";
import "../css/staticstest.css";

const inputboxset = {
  width: "400px", // Text color property
};

const btncolor = {
  width: "400px",
  background: "#582C83",
};

const backcolor = {
  color: "Black",
};

export default function App() {
  const options = [
    { value: "Choose Service", label: "Choose Service" },
    { value: "Billing Services", label: "Billing Services" },
    {
      value: "Credentialing Services",
      label: "Credentialing Services",
    },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const [serviceselected, handleSelectedValue] = useState(null);
  const [selecthandlename, setname] = useState("");
  const [selecthandlephone, setphone] = useState("");
  const [selectehandleemail, setemail] = useState("");
  const [checkedValue, setcheckedValue] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const usPhoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#582C83" : "white",
      color: state.isFocused ? "white" : "inherit",
    }),
  };

  const handleCheckboxChange = (event) => {
    setcheckedValue(event.target.value);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    handleSelectedValue(selectedOption.value);
  };

  const handlename = (event) => {
    setname(event.target.value);
  };


  const handlephone = (event) => {
    setphone(event.target.value);
  };


  const handleemail = (event) => {
    setemail(event.target.value);
  };
  const [errors, setErrors] = useState({
    firstname: '',
    email: '',
    phone: '',
    message: '',
    provider: '',

  });

  const clearSuccessMessage = () => {
    setSuccessMessage("");
  };
  
  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };
  
    if (!selecthandlename.trim()) {
      newErrors.firstname = 'Name is required';
      valid = false;
    } else {
      newErrors.firstname = '';
    }
    if (!selectehandleemail.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!emailRegex.test(selectehandleemail)) {
      newErrors.email = 'Invalid email address';
      valid = false;
    } else {
      newErrors.email = '';
    }
    if (!selecthandlephone) {
      newErrors.phone = 'Phone number is required';
      valid = false;
    } else if (!usPhoneRegex.test(selecthandlephone)) {
      newErrors.phone = 'Invalid US phone number';
      valid = false;
    } else {
      newErrors.phone = '';
    }
    if (!serviceselected) {
      newErrors.message = 'service is required';
      valid = false;
    } else {
      newErrors.message = '';
    }
    if (!checkedValue.trim()) {
      newErrors.provider = 'Providers is required';
      valid = false;
    } else {
      newErrors.provider = '';
    }
  
    // You can add more validation rules for email, phone, etc.
  
    setErrors(newErrors);
    return valid;
  };

  const handleInsertion = () => {
    if (validateForm()) {
      setIsLoading(true);
    const formData = new FormData();
    // Add other form fields to formData
    formData.append("name", selecthandlename);
    formData.append("email", selectehandleemail);
    formData.append("service", serviceselected);
    formData.append("phone", selecthandlephone);
    formData.append("noofprovider", checkedValue);

    console.log(formData);
    // Make a POST request to your PHP insertion API
    axios
      .post("https://mdbill.io/websiteemail/testfile.php", formData)
      .then((response) => {
        
        console.log(response);
      })
      .catch((error) => {
        setSelectedOption(null);
        setname("");
        setphone("");
        setemail("");
        setcheckedValue("");
        setSuccessMessage("Form submitted successfully");
        setTimeout(clearSuccessMessage, 3000);
        setIsLoading(false);
        console.error(error);
      });
    }
};

  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <div class="row">
          {/* <section className="col-md-12 sub-section text-center"> */}
            <div className="row case-studies2 mt-0">
              <div className="col-md-8 fromdown">
                <ReactPlayer
                  url="https://tracksystem.s3.us-west-2.amazonaws.com/videos/AcerHealthVideo.mp4"
                  playing={false}
                  loop={true}
                  muted={true}
                  controls={true}
                  id="background_video"
                  width="900"
                  height="400"
                />
              </div>
              <div className="col-md-4 fromdown">
                <div>
                <div
                  className="bg-white p-3"
                  style={{
                    border: "1px solid #A569BD",
                    borderRadius: "10px",
                  }}
                >
                  <MDBCardTitle className="AcerhealthTextPurple text-center" style={{fontFamily: "Alegreya Sans",fontWeight:'bold',fontSize:'22px'}}>
                    Schedule an Appointment
                  </MDBCardTitle>
                  <br></br>
                  {errors.firstname && <div className="error-message"style={{color:'red'}}>{errors.firstname}</div>}
                    <MDBInput
                      className="mb-4"
                      type="text"
                      id="formWhite"
                      style={inputboxset}
                      label="Name"
                      name="name"
                      onChange={handlename} value={selecthandlename}
                      autocomplete="off"
                    />
                    {errors.phone && <div className="error-message"style={{color:'red'}}>{errors.phone}</div>}
                    <MDBInput
                      className="mb-4"
                      type="phone"
                      id="formWhite"
                      style={inputboxset}
                      label="Phone No."
                      name="phone"
                      onChange={handlephone} value={selecthandlephone}
                      autocomplete="off"
                    />
                     {errors.email && <div className="error-message"style={{color:'red'}}>{errors.email}</div>}
                    <MDBInput
                      className="mb-4"
                      type="email"
                      id="form3Example3"
                      style={inputboxset}
                      label="Email ID"
                      name="email"
                      onChange={handleemail} value={selectehandleemail}
                      autocomplete="off"
                      
                    />
                     {errors.message && <div className="error-message"style={{color:'red'}}>{errors.message}</div>}
                    <MDBRow>
                      <MDBCol className="mb-4 text-start">
                        <Select
                          options={options}
                          styles={customStyles}
                          placeholder={"Choose Service"}
                        onChange={handleSelectChange}
                        value={selectedOption}
                        autocomplete="off"
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="container-fluid w-100 mb-3 p-0">
                    {errors.provider && <div className="error-message"style={{color:'red'}}>{errors.provider}</div>}
                      <span className="mr-2 pr-3 text-muted">
                        No of Provider(s) :{" "}
                      </span>
                      <br />
                      <MDBCol>
                        <MDBRadio
                          name="inlineCheck"
                          id="inlineCheckbox1"
                          value="1-5"
                          label="1-5"
                          inline
                          checked={checkedValue === "1-5"}
                        onChange={handleCheckboxChange}
                        />
                        <MDBRadio
                          name="inlineCheck"
                          id="inlineCheckbox2"
                          value="6-10"
                          label="6-10"
                          inline
                          checked={checkedValue === "6-10"}
                        onChange={handleCheckboxChange}
                        />
                        <MDBRadio
                          name="inlineCheck"
                          id="inlineCheckbox4"
                          value="10+"
                          label="10+"
                          inline
                          checked={checkedValue === "10+"}
                        onChange={handleCheckboxChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-center mt-4">
                    <MDBBtn
                      type="submit"
                      className="mb-4 d-grid gap-2 col-5 mx-auto"
                      style={{ backgroundColor: "#3B0075" }}
                      onClick={handleInsertion}
                    >
                   {isLoading ? (
              <div className="d-flex align-items-center">
                <strong>Sending...</strong>
                <MDBSpinner className="ms-auto" size='sm' role="status" />
              </div>
            ) : (
              "Submit"
            )}
                    </MDBBtn>
                    </MDBRow>
                </div>
                </div>
              </div>
            </div>
            {successMessage && (
  <div className="alert alert-success">{successMessage}</div>
)}
          {/* </section> */}
        </div>
      </div>
    </MDBContainer>
  );
}
