// import React, { useState, useEffect } from "react";
// import {
//   MDBCard,
//   MDBCardBody,
//   MDBCardTitle,
//   MDBBtn,
//   MDBInput,
//   MDBIcon,
//   MDBContainer,
//   MDBCarousel,
//   MDBCarouselItem,
// } from "mdb-react-ui-kit";
// // import { Carousel } from "react-bootstrap";
// import BannerContact from "./home_banner_contact";
// import Carousel1 from "../img/Homepagebanners/achieving100.jpg";
// import Carousel2 from "../img/Homepagebanners/ondemandreport.jpg";
// import Carousel4 from "../img/Homepagebanners/dedicatedbillingteam.jpg";
// import Carousel5 from "../img/Homepagebanners/timelydenialfollowups.jpg";
// // import "../css/setconaole.css";
// export default function App() {
//   return (
//     <MDBContainer fluid className="p-0">
//     <div className="carousel-wrapper">
//       <MDBCarousel
//         showIndicators
//         interval={7000}
//         style={{ height: 600 }}
//       >
// <MDBCarouselItem
//   className="w-100 bg-image d-block mask1"
//   itemId={1}
//   style={{
//     backgroundImage: `url(${Carousel1})`,
//     minHeight: "600px",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     backgroundSize: "cover",
//     backgroundPositionX: '75%',
//     backgroundPositionY: '25%',
//   }}
// >
//   <div className="carousel-caption text-start ">
//     <div className="row" style={{ marginLeft: "40px" }}>
//       <div className="col-2"></div>
//       <div className="col col-10 flex-row-reverse" style={{ marginTop: "10%" }}>
//         <div
//           className=" banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}
//       >
//           <b>Increase Your Cash Flow by
//           <br />
//           10 to 15%</b>
//         </div>
//         <div
//           className="banner-text1 AcerhealthTextGreen"
//           style={{ fontFamily: "Alegreya Sans" }}
//         >
//           Ensure Payment for Every Claim, Eliminating the Possibility of Missed Reimbursements
//         </div>
//       </div>
//     </div>
//   </div>
// </MDBCarouselItem>
//         <MDBCarouselItem
//           className="w-100 d-block bg-image mask1"
//           itemId={2}
//           style={{
//             backgroundImage: `url(${Carousel2})`,
//             minHeight: "600px",
//             backgroundPosition: "center",
//             backgroundRepeat: "no-repeat",
//             backgroundSize: "cover",
//           }}
          
//         >
//         <div className="carousel-caption text-start ">
//             <div className="row" style={{ marginLeft: "40px" }}>
//               <div className="col-2" ></div>
//               <div 
//                 className="col col-10 flex-row-reverse"
//                 style={{ marginTop: "10%" }}
//               >
//                 <div
//                   className="banner-text2 AcerhealthTextPurple"
//                   style={{ fontFamily: "Alegreya Sans" }}
//                 >
//                   <b>
//                On Demand Meaningful Management Reports 
//                </b>
//                 </div>
//                 <div
//                   className="banner-text1 AcerhealthTextGreen"
//                   style={{ fontFamily: "Alegreya Sans" }}
//                 >
//                 Instantly Access AI Data-Driven Practice Analytics and On-Demand Reports
//                 </div>
//               </div>
//             </div>
//           </div>
//         </MDBCarouselItem>
//         <MDBCarouselItem
//           className="w-100 d-block mask1"
//           itemId={2}
//           style={{
//             backgroundImage: `url(${Carousel4})`,
//             minHeight: "600px",
//             backgroundPosition: "center",
//             backgroundRepeat: "no-repeat",
//             backgroundSize: "cover",
//             backgroundPositionX:'75%',
//          backgroundPositionY:'100%',
//           }}
          
//         >
//             <div className="carousel-caption text-start ">
//             <div className="row" style={{ marginLeft: "40px" }}>
//               <div className="col-2"></div>
//               <div 
//                 className="col col-10 flex-row-reverse"
//                 style={{ marginTop: "10%" }}
//               >
//                 <div
//                   className="banner-text2 AcerhealthTextPurple"
//                   style={{ fontFamily: "Alegreya Sans" }}
//                 >
//                   <b>
//                     Dedicated Billing Team
//                     </b>
//                 </div>
//                 <div
//                   className="banner-text1 AcerhealthTextGreen"
//                   style={{ fontFamily: "Alegreya Sans" }}
//                 >
//                 Bespoke Billing and Customer Service Managers Exclusively Focused on Your Practice
//                 </div>
//               </div>
//             </div>
//           </div>
//         </MDBCarouselItem>
//         <MDBCarouselItem
//           className="w-100 d-block mask1"
//           itemId={2}
//           style={{
//             backgroundImage: `url(${Carousel5})`,
//             minHeight: "600px",
//             backgroundPosition: "center",
//             backgroundRepeat: "no-repeat",
//             backgroundSize: "cover",
//             backgroundPositionX:'75%',
//          backgroundPositionY:'25%',
//           }}
       
//         >
//           <div className="carousel-caption text-start" >
//             <div className="row" style={{ marginLeft: "40px" }}>
//               <div className="col-2"></div>
//               <div 
//                 className="col col-10 flex-row-reverse"
//                 style={{ marginTop: "10%" }}
//               >
//                 <div
//                   className="banner-text2 AcerhealthTextPurple"
//                   style={{ fontFamily: "Alegreya Sans" }}
//                 >
//                   <b>
//             Prompt Rejection Resolution
//             </b>
//                 </div>
//                 <div
//                   className="banner-text1 AcerhealthTextGreen"
//                   style={{ fontFamily: "Alegreya Sans" }}
//                 >
//                 Secure Your Practice's Financial Health With Timely Denial Resolution to Avert Revenue Loss
//                 </div>
//               </div>
//             </div>
//           </div>
//         </MDBCarouselItem>
//       </MDBCarousel>

//       <div className="carousel-captions">
//         <BannerContact />
//       </div>
//     </div>
//   </MDBContainer>
//   );
// }


import Carousel from 'react-bootstrap/Carousel';
import Carousel1 from "../img/Homepagebanners/Frame6.png";
import Carousel2 from "../img/Homepagebanners/banner2ondemad.png";
import Carousel4 from "../img/Homepagebanners/banner3billingtem.png";
import Carousel5 from "../img/Homepagebanners/banner4.png";
import MobileBackground from "../img/Homepagebanners/achieving100.jpg";
import MobileBackground1 from "../img/Homepagebanners/ondemandreport.jpg";
import MobileBackground2 from "../img/Homepagebanners/dedicatedbillingteam.jpg";
import MobileBackground3 from "../img/Homepagebanners/timelydenialfollowups.jpg";

import BannerContact from "./home_banner_contact";
import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import "../css/setconaole.css";

function UncontrolledExample() {
  return (
    <MDBContainer fluid className="p-0" style={{ position: 'relative' }}>
      
      <Carousel controls={false} interval={3000} indicators={false} className="d-none d-md-block">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Carousel1}
            style={{ height: '600px', objectFit: 'cover' }}
            alt="First slide"
          />
          <Carousel.Caption className="caption-overlay">
          <div
    className="carousel-caption text-start"
    style={{
      marginLeft: '20%',
      marginRight: '5%',
      textAlign: 'center', // Center the text horizontally
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center the text vertically
      height: '100%', // Make the container full height
    }}
  >
    <div className="slide-in banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}>
      <b>Increase Your Cash Flow by 10 to 15%</b>
    </div>
    <div className="slide-in-from-left banner-text1 AcerhealthTextGreen" style={{ fontFamily: "Alegreya Sans" }}>
    Ensure Payment for Every Claim, Eliminating the Possibility of Missed Reimbursements
    </div>
  </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Carousel2}
            style={{ height: '600px', objectFit: 'cover'  }}
            alt="Second slide"
          />
          <Carousel.Caption className="caption-overlay">
          <div
    className="carousel-caption text-start"
    style={{
      marginLeft: '20%',
      marginRight: '5%',
      textAlign: 'center', // Center the text horizontally
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center the text vertically
      height: '100%', // Make the container full height
    }}
  >
    <div className="slide-in banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}>
      <b>On Demand Meaningful Management Reports </b>
    </div>
    <div className="slide-in-from-left banner-text1 AcerhealthTextGreen" style={{ fontFamily: "Alegreya Sans" }}>
    Instantly Access AI Data-Driven Practice Analytics and On-Demand Reports
    </div>
  </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Carousel4}
            style={{ height: '600px', objectFit: 'cover'  }}
            alt="Third slide"
          />
          <Carousel.Caption className="caption-overlay">
          <div
    className="carousel-caption text-start"
    style={{
      marginLeft: '20%',
      marginRight: '5%',
      textAlign: 'center', // Center the text horizontally
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center the text vertically
      height: '100%', // Make the container full height
    }}
  >
    <div className="slide-in banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}>
      <b>Dedicated Billing Team</b>
    </div>
    <div className="slide-in-from-left banner-text1 AcerhealthTextGreen" style={{ fontFamily: "Alegreya Sans" }}>
    Bespoke Billing and Customer Service Managers Exclusively Focused on Your Practice
    </div>
  </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Carousel5}
            style={{ height: '600px' }}
            alt="Third slide"
          />
          <Carousel.Caption className="caption-overlay">
          <div
    className="carousel-caption text-start"
    style={{
      marginLeft: '20%',
      marginRight: '5%',
      textAlign: 'center', // Center the text horizontally
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center the text vertically
      height: '100%', // Make the container full height
    }}
  >
    <div className="slide-in banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}>
      <b>Prompt Rejection Resolution</b>
    </div>
    <div className="slide-in-from-left banner-text1 AcerhealthTextGreen" style={{ fontFamily: "Alegreya Sans" }}>
    Secure Your Practice's Financial Health With Timely Denial Resolution to Avert Revenue Loss
    </div>
  </div>

          </Carousel.Caption>
        </Carousel.Item>

        </Carousel>

<Carousel controls={false} interval={3000} indicators={false}>
        <Carousel.Item className="d-md-none"> {/* Apply only for mobile devices */}
          <img
            className="d-block w-100 mask1"
            src={MobileBackground}
            style={{ height: '600px', objectFit: 'cover' }}
            alt="Mobile background slide"
          />
          <Carousel.Caption className="caption-overlay">
          <div
    className="carousel-caption text-start"
    style={{
      marginLeft: '20%',
      marginRight: '5%',
      textAlign: 'center', // Center the text horizontally
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center the text vertically
      height: '100%', // Make the container full height
    }}
  >
    <div className="slide-in banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}>
      <b>Increase Your Cash Flow by 10 to 15%</b>
    </div>
    <div className="slide-in-from-left banner-text1 AcerhealthTextGreen" style={{ fontFamily: "Alegreya Sans" }}>
    Ensure Payment for Every Claim, Eliminating the Possibility of Missed Reimbursements
    </div>
  </div>
  
  </Carousel.Caption>
          {/* No need for Carousel.Caption as you want to keep other content the same */}
        </Carousel.Item>
        <Carousel.Item className="d-md-none"> {/* Apply only for mobile devices */}
          <img
            className="d-block w-100 mask1"
            src={MobileBackground1}
            style={{ height: '600px', objectFit: 'cover' }}
            alt="Mobile background slide"
          />
          <Carousel.Caption className="caption-overlay">
          <div
    className="carousel-caption text-start"
    style={{
      marginLeft: '20%',
      marginRight: '5%',
      textAlign: 'center', // Center the text horizontally
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center the text vertically
      height: '100%', // Make the container full height
    }}
  >
    <div className="slide-in banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}>
      <b>On Demand Meaningful Management Reports </b>
    </div>
    <div className="slide-in-from-left banner-text1 AcerhealthTextGreen" style={{ fontFamily: "Alegreya Sans" }}>
    Instantly Access AI Data-Driven Practice Analytics and On-Demand Reports
    </div>
  </div>
  
  </Carousel.Caption>
          {/* No need for Carousel.Caption as you want to keep other content the same */}
        </Carousel.Item>

        <Carousel.Item className="d-md-none"> {/* Apply only for mobile devices */}
          <img
            className="d-block w-100 mask1"
            src={MobileBackground2}
            style={{ height: '600px', objectFit: 'cover' }}
            alt="Mobile background slide"
          />
          <Carousel.Caption className="caption-overlay">
          <div
    className="carousel-caption text-start"
    style={{
      marginLeft: '20%',
      marginRight: '5%',
      textAlign: 'center', // Center the text horizontally
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center the text vertically
      height: '100%', // Make the container full height
    }}
  >
    <div className="slide-in banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}>
      <b>Dedicated Billing Team</b>
    </div>
    <div className="slide-in-from-left banner-text1 AcerhealthTextGreen" style={{ fontFamily: "Alegreya Sans" }}>
    Bespoke Billing and Customer Service Managers Exclusively Focused on Your Practice
    </div>
  </div>
  
  </Carousel.Caption>
          {/* No need for Carousel.Caption as you want to keep other content the same */}
        </Carousel.Item>

        <Carousel.Item className="d-md-none"> {/* Apply only for mobile devices */}
          <img
            className="d-block w-100 mask1"
            src={MobileBackground3}
            style={{ height: '600px', objectFit: 'cover' }}
            alt="Mobile background slide"
          />
          <Carousel.Caption className="caption-overlay">
          <div
    className="carousel-caption text-start"
    style={{
      marginLeft: '20%',
      marginRight: '5%',
      textAlign: 'center', // Center the text horizontally
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center the text vertically
      height: '100%', // Make the container full height
    }}
  >
    <div className="slide-in banner-text2 AcerhealthTextPurple" style={{ fontFamily: "Alegreya Sans" }}>
      <b>Prompt Rejection Resolution</b>
    </div>
    <div className="slide-in-from-left banner-text1 AcerhealthTextGreen" style={{ fontFamily: "Alegreya Sans" }}>
    Secure Your Practice's Financial Health With Timely Denial Resolution to Avert Revenue Loss
    </div>
  </div>
  
  </Carousel.Caption>
          {/* No need for Carousel.Caption as you want to keep other content the same */}
        </Carousel.Item>
        
      </Carousel>
      <div className="carousel-captions">
        <BannerContact />
      </div>
    </MDBContainer>
  );
}

export default UncontrolledExample;