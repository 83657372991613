import React, { useEffect , useState } from 'react';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/banner';
import Cards from '../components/blogs_cards';
import DownContact from '../components/downcontact';
import RCMcy from '../img/Blogbanners/blogbanner.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const Blogs = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Medical Billing Services|Medical Billing Outsource|Medical Billing Consultant';
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
    
  }, []);

  // Set Breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Blogs');

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({img:`url(${RCMcy})`});

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER", subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."});

 return (
    <>
      {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
      <Banner BannerTitle ={BannerTitle} />
      <Cards />
    </>

  );
};

export default Blogs;