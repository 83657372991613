import React from 'react';
import {
    MDBContainer,
    MDBBtn, 
    MDBIcon
} from "mdb-react-ui-kit";
import { Carousel } from "react-bootstrap";
const TestimonialBand = () => {
    return (
      <MDBContainer fluid className='p-0' style={{backgroundColor:'#D6DBDF'}}>
      <div className="container py-5">
        <section id="services-section3">
                <div class="row justify-content-around align-items-center">
                    <div class="col-md-7">
                        <div>
                            <p style={{fontSize:'30px',fontWeight:'bold',color:'black',fontFamily: 'Alegreya Sans'}}>
                            Our Clients Tell Us What They Think
                            </p>
                        </div>
                        <div className="carousel-wrapper align-items-center justify-content-center">
                <Carousel
                  interval={5000}
                  pause={true}
                  indicators={false}
                  controls={false}
                >
                  <Carousel.Item>
                    <div className="AcerhealthTextPurple h5" style={{fontFamily: 'Alegreya Sans',fontWeight:'bold' }}>
                      Omair Siraj, CEO
                    </div>
                    <span style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>We wanted an opinion on our AR and areas to improve. We gave
                    Acer our claim details.Acer offered us a comprehensive
                    analysis of our practice's financial and operational health.</span>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold' }}>
                      Saifullah Nasir, MD
                    </div>
                    <span style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>At the beginning, we thought that the process of getting
                    credentialed and submitting claims for payment would be a
                    reasonable endeavor and something that a person with an MD
                    could surely do.</span>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold' }}>
                      Zarina, Executive Director
                    </div>
                    <span style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>We enjoy working with AcerHealth because they listen to us.
                    When I say, ‘we need this and this and this,’ I know that
                    they hear it. They are very accommodating.</span>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold' }}>
                      Adil Usman, MD
                    </div>
                    <span style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Acer Health has been with us since 2010. Acer quickly adapts
                    to all the procedure billing guidelines and help me receive
                    99% collection of all charges submitted.</span>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold' }}>
                      Dr.Kambiz Yazdani, MD
                    </div>
                    <span style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>Acer Health billers and coders are on top of everything.
                    Since Acer started to do to our medical billing, insurance
                    denials are few and far between.</span> 
                  </Carousel.Item>
                </Carousel>
              </div>
                    </div>
                    <div class="col-md-4">
                        <div class="action-btn text-right">
                        <MDBBtn style={{backgroundColor: "#3B0075", float:'right'}} href="Testimonials">
                        Read More
                        </MDBBtn>
                        </div>
                    </div>
                </div>
        </section>
      </div>

    </MDBContainer>
    );
};
export default TestimonialBand;


