
import React from "react";
import {MDBContainer} from "mdb-react-ui-kit";

export default function App() {
    return (
      <MDBContainer fluid className="p-0 bg-light">
          <div className="container-fluid">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6200.761052841985!2d-77.500497!3d39.0066323!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63fbdbd6cf1b9%3A0x972eb5f2fd493513!2sAcer%20Health%20Inc!5e0!3m2!1sen!2sin!4v1690884381900!5m2!1sen!2sin" width="100%" height="300" style={{border:'0px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
      </MDBContainer>
    );
}
