
import React, { useState,useEffect } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from "mdb-react-ui-kit";
import logo from "../img/acerhealth website logo.svg";
import "../css/smoothscroll.css";
import {BrowserRouter, Link} from "react-router-dom";

export default function App() {
  const [showNavText, setShowNavText] = useState(false);
  // navbar shadow
  const [NavbarShadow, setNavbarShadow] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 20) {
      setNavbarShadow(true);
      // Close the navbar when scrolling in mobile view
      if (window.innerWidth < 767) {
        setShowNavText(false);
      }
    } else {
      setNavbarShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  return (
    <MDBNavbar
      expand="lg"
      className={NavbarShadow ? "sticky-top bg-light with-shadow" : "shadow-none bg-light "}
    >
      <MDBContainer>

        <MDBNavbarBrand href="/">
          <img
            src={logo}
            className="img img-fluid"
            alt="Medical Billing Solutions"
            style={{width:'250px'}}
          />
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type="button"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowNavText(!showNavText)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNavText}>
          <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-center">
            <MDBNavbarItem>
            
                  {/* <MDBDropdownItem link href="about"> <span className="AcerhealthTextPurple h6"> Company </span></MDBDropdownItem> */}
                  <MDBNavbarLink href="/about-us" className="mt-2">About</MDBNavbarLink>
                  </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link mt-2' role='button'>
                Services
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem link href="/services/medical-billing-services"> <span className="AcerhealthTextPurple h6"> Medical Billing Services </span></MDBDropdownItem>
                  <MDBDropdownItem link href="/services/medical-consultancy-services"> <span className="AcerhealthTextPurple h6">Medical Consultancy Services</span></MDBDropdownItem>
                  <MDBDropdownItem link href="/services/medical-billing-and-credentialing"> <span className="AcerhealthTextPurple h6"> Credentialing Services</span> </MDBDropdownItem>
                  <MDBDropdownItem link href="/services/revenue-cycle-management"> <span className="AcerhealthTextPurple h6"> Revenue Cycle Management </span></MDBDropdownItem>
                  <MDBDropdownItem link href="/services/set-up-practice"> <span className="AcerhealthTextPurple h6"> Set Up a Practice  </span></MDBDropdownItem>
                  <MDBDropdownItem link href="/services/contract-compliance"> <span className="AcerhealthTextPurple h6"> Contracts and Compliance  </span></MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link mt-2' role='button'>
                  Technologies
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem link href="/technologies/practice-analytics"> <span className="AcerhealthTextPurple h6">Practice Analytics</span></MDBDropdownItem>
                  <MDBDropdownItem link href="/technologies/patient-balance-estimator"> <span className="AcerhealthTextPurple h6">Patient Balance Estimator</span></MDBDropdownItem>
                  <MDBDropdownItem link href="/technologies/patient-eligibility-verification"> <span className="AcerhealthTextPurple h6">Patient Eligibility Verification</span></MDBDropdownItem>
                  <MDBDropdownItem link href="/technologies/patient-statements-payment-portal"> <span className="AcerhealthTextPurple h6">Patient Statements & Payment Portal</span></MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="/medical-billing-specialities" className="mt-2">Our Solutions</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="/medical-billing-blogs" className="mt-2">Blogs</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="/contact-us" className="mt-2">Contact Us</MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
          {/* <a href="http://35.161.67.133:90/">
          <MDBBtn style={{backgroundColor: "#582C83"}}>
            <MDBIcon fas icon="user-md" className='me-2'/> <span>Physician Login</span>
          </MDBBtn>
          </a> */}
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}

