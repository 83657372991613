import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import { MDBContainer } from "mdb-react-ui-kit";
import BreadCrumbs from "../components/breadcrumbs";
import DownContact from "../components/downcontact";
import Details from "../components/testimonials_details";
import RCMcy from '../img/Homepagebanners/testimonials.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const Testimonial = () => {
  useEffect(() => {
    // change title of page
    document.title = "Medical Billing Services|Medical Billing Outsource|Medical Billing Consultant";

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set Breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState("Testimonials");

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({title: "", subtitle: "", img:`url(${RCMcy})`});

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({
    title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });

  return (
    <>
      {/* <BreadCrumbs Breadcrumb={Breadcrumb} /> */}
      <Banner BannerTitle={BannerTitle} />
      <Details />
      {/* <DownContact DownContactTitle={DownContactTitle} /> */}
    </>
  );
};

export default Testimonial;
