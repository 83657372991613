import React, { useState, useEffect } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBInput,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBCheckbox,
  MDBSpinner,
  MDBRadio,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "../css/card.css";
import axios from "axios";


export default function App() {
  const backcolor = {
    color: "Black",
  };
  const [checkedValue, setCheckedValue] = useState("");
  const [selecthandleemail, setemail] = useState("");
  const [selecthandlename, setname] = useState("");
  const [selecthandlephone, setphone] = useState("");
  const [selecthandlepracticename, setpractice] = useState("");
  const [selecthandlemessage, setmessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const usPhoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const handleCheckboxChange = (event) => {
    setCheckedValue(event.target.value);

  };

  const handlemessage = (event) => {
    setmessage(event.target.value);
  };


  const handlepracticename = (event) => {
    setpractice(event.target.value);
  };


  const handlephone = (event) => {
    setphone(event.target.value);
  };


  const handleemail = (event) => {
    setemail(event.target.value);
  };


  const handlename = (event) => {
    setname(event.target.value);
  };

  const clearSuccessMessage = () => {
    setSuccessMessage("");
  };


  const [errors, setErrors] = useState({
    firstname: '',
    email: '',
    phone: '',
    message: '',
    practice: '',
    provider: '',

  });
  
  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };
  
    if (!selecthandlename.trim()) {
      newErrors.firstname = 'Name is required';
      valid = false;
    } else {
      newErrors.firstname = '';
    }

    if (!selecthandleemail.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!emailRegex.test(selecthandleemail)) {
      newErrors.email = 'Invalid email address';
      valid = false;
    } else {
      newErrors.email = '';
    }
    if (!selecthandlephone) {
      newErrors.phone = 'Phone number is required';
      valid = false;
    } else if (!usPhoneRegex.test(selecthandlephone)) {
      newErrors.phone = 'Invalid US phone number';
      valid = false;
    } else {
      newErrors.phone = '';
    }
    if (!selecthandlepracticename.trim()) {
      newErrors.practice = 'Practice is required';
      valid = false;
    } else {
      newErrors.practice = '';
    }
    if (!selecthandlemessage.trim()) {
      newErrors.message = 'Message is required';
      valid = false;
    } else {
      newErrors.message = '';
    }
    if (!checkedValue.trim()) {
      newErrors.provider = 'Providers is required';
      valid = false;
    } else {
      newErrors.provider = '';
    }
  
    // You can add more validation rules for email, phone, etc.
  
    setErrors(newErrors);
    return valid;
  };
  const handleInsertion = () => {
    if (validateForm()) {
      setIsLoading(true);
    const formData = new FormData();
    // Add other form fields to formData
    formData.append("noofprovider", checkedValue);
    formData.append("email", selecthandleemail);
    formData.append("name", selecthandlename);
    formData.append("phone", selecthandlephone);
    formData.append("practiceName", selecthandlepracticename);
    formData.append("message", selecthandlemessage);

    console.log(formData);
    // Make a POST request to your PHP insertion API
    axios
      .post("https://mdbill.io/websiteemail/testfile.php", formData)
      .then((response) => {
      })
      .catch((error) => {
        console.error(error);
        setCheckedValue("");
        setemail("");
        setname("");
        setphone("");
        setpractice("");
        setmessage("");
        setSuccessMessage("Form submitted successfully");
        setTimeout(clearSuccessMessage, 3000);
        setIsLoading(false);
      });
    }
};
  return (
      <MDBCard className="justify-content-center banner-contact-form">
        <MDBCardBody>
          <MDBCardTitle className="AcerhealthTextPurple text-center mb-3" style={{fontFamily: 'Alegreya Sans',fontWeight:'bold',fontSize:'22px' }}>
          {/* Are You Looking for the Quality Medical Billing Services? */}
          Ready to get Started?
          </MDBCardTitle>
          <MDBRow>
            <MDBCol size="md">
            {errors.firstname && <div className="error-message"style={{color:'red'}}>{errors.firstname}</div>}
              <MDBInput
                className="mb-4 shadow-box"
                label="Name"
                type="text"
                id="formWhite"
                name="name"   
                onChange={handlename} value={selecthandlename}
                autocomplete="off"
              />
            </MDBCol>
            <MDBCol size="md" className="mb-4">
            {errors.email && <div className="error-message"style={{color:'red'}}>{errors.email}</div>}
            <MDBInput
                className="shadow-box"
                label="Email ID"
                type="text"
                id="formWhite"
                name="email"
                onChange={handleemail} value={selecthandleemail}
                autocomplete="off"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="md" className="mb-4">
            {errors.phone && <div className="error-message"style={{color:'red'}}>{errors.phone}</div>}
            <MDBInput
                    label="Phone No."
                    placeholder="Enter phone number"
                    className="shadow-box"
                    name="phone"
                    onChange={handlephone} value={selecthandlephone}
                    autocomplete="off"
                  />
            </MDBCol>
            <MDBCol size="md" className="mb-4">
            {errors.practice && <div className="error-message"style={{color:'red'}}>{errors.practice}</div>}
              <MDBInput
                className="mb-4 shadow-box"
                label="Practice Name"
                type="text"
                id="formWhite"
                name="practicename"
                onChange={handlepracticename} value={selecthandlepracticename}
                autocomplete="off"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="mb-4">
            {errors.message && <div className="error-message"style={{color:'red'}}>{errors.message}</div>}
            <MDBTextArea label='Message' id='textAreaExample' name="messagefield" autocomplete="off" rows={2} onChange={handlemessage} value={selecthandlemessage} />  
            </MDBCol>
          </MDBRow>
          <MDBRow className="container-fluid w-100 mb-3 p-0">
          {errors.provider && <div className="error-message"style={{color:'red'}}>{errors.provider}</div>}
            <span className="mr-2 pr-3 text-muted">No of Provider(s) : </span>
            <br />
            <MDBCol>
              <MDBRadio
                name="inlineCheck"
                id="inlineCheckbox1"
                value="1-5"
                label="1-5"
                inline
                checked={checkedValue === "1-5"}
                onChange={handleCheckboxChange}
              />
              <MDBRadio
                name="inlineCheck"
                id="inlineCheckbox2"
                value="6-10"
                label="6-10"
                inline
                checked={checkedValue === "6-10"}
                onChange={handleCheckboxChange}
              />
              <MDBRadio
                name="inlineCheck"
                id="inlineCheckbox4"
                value="10+"
                label="10+"
                inline
                checked={checkedValue === "10+"}
                onChange={handleCheckboxChange}
              />
            </MDBCol>
          </MDBRow>
         
          <MDBBtn
                      type="submit"
                      className="mb-4 d-grid gap-2 col-5 mx-auto"
                      // style={{ backgroundColor: "#582C83" }}color: #3B0075;
                      style={{ backgroundColor: "#3B0075" }}
                      onClick={handleInsertion}
                    >
                   {isLoading ? (
              <div className="d-flex align-items-center">
                <strong>Sending...</strong>
                <MDBSpinner className="ms-auto" size='sm' role="status" />
              </div>
            ) : (
              "Submit"
            )}
                    </MDBBtn>
        </MDBCardBody>
        {successMessage && (
  <div className="alert alert-success">{successMessage}</div>
)}
      </MDBCard>
  );
}
