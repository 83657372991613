
import React from "react";
import {MDBContainer} from "mdb-react-ui-kit";
import "../css/servicesfont.css";
export default function ConsultancyInfo() {
    return (
      <MDBContainer fluid className="p-0">
          <div className="container py-2">
            <div className="row">
                    <div className="col-12">
                        <div className="section-title mb-4 pb-2">
                            <div className="title mb-4 mt-4 display-heading AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}>Medical Consultancy Services</div>
                            <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>Maximizing Healthcare Revenues with 
                             expert medical billing consultancy services as a healthcare provider, you're dedicated to delivering high-quality care to your patients. However, navigating the complexities of medical billing and revenue cycle management can be a challenge. That's where professional medical billing consultancy services come in. Our team of healthcare consultants specializes in revenue cycle management, medical coding, insurance claim processing, and compliance with industry regulations. With years of experience and a deep understanding of the healthcare industry, we can help you improve your medical billing processes and maximize your revenue. We offer a range of services to meet your specific needs, including medical billing outsourcing, EHR implementation, practice management solutions, and patient billing and collections. Our goal is to make the financial side of your practice run smoothly so you can focus on what really matters – providing top-notch care to your patients.</p>
                        </div>
                    </div>
                </div>
          </div>
       </MDBContainer>
    );
}
