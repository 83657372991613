import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBInput,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBCheckbox,
  MDBDatePicker,
  MDBCardText,
  MDBRipple,
} from "mdb-react-ui-kit";
import "../css/service.css";

const AboutAcerhealth = () => {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <h1 className="AcerhealthTextPurple text-center testourvalue" style={{fontFamily:'Lora, serif', fontWeight: 'bold' }}>
          Our Values
        </h1>
        <br></br>
        <MDBRow className="justify-content-center">
          <MDBCol md="6" lg="3">
            <div className="card rounded service-wrapper rounded border-0 shadow p-4" style={{ width: '335px', height: '300px' }}>
              <h5 class="text-center AcerhealthTextPurple testourvalues1" style={{fontFamily: "Alegreya Sans", fontWeight: 'bold' }}>Diversity</h5>
              <p className="justify-content-left" style={{ height: '150px', fontSize: '18px', fontFamily: "Alegreya Sans",fontWeight:'500' }}>
                We aim to offer diverse healthcare solutions through collaboration with experts from various backgrounds.
              </p>
            </div>
          </MDBCol>

          <MDBCol md="6" lg="3">
            <div className="card rounded service-wrapper rounded border-0 shadow p-4" style={{ width: '335px', height: '300px' }}>
              <h5 class="text-center AcerhealthTextPurple testourvalues1" style={{fontFamily: "Alegreya Sans", fontWeight: 'bold' }}>Expertise</h5>
              <p className="justify-content-left" style={{ height: '150px', fontSize: '18px', fontFamily: "Alegreya Sans",fontWeight:'500' }}>
                Our cutting-edge research and healthcare solutions drive innovation, and as leaders in the field, we continually adapt to change.
              </p>
            </div>
          </MDBCol>

          <MDBCol md="6" lg="3">
            <div className="card rounded service-wrapper rounded border-0 shadow p-4" style={{ width: '335px', height: '300px' }}>
              <h5 class="text-center AcerhealthTextPurple testourvalues1" style={{fontFamily: "Alegreya Sans", fontWeight: 'bold' }}>Technology</h5>
              <p className="justify-content-left" style={{ height: '150px', fontSize: '18px', fontFamily: "Alegreya Sans",fontWeight:'500' }}>
                To increase patient payments, practices can utilize integrated software for text and email statements, reducing postage, accelerating statements, and ensuring secure transactions.
              </p>
            </div>
          </MDBCol>

          <MDBCol md="6" lg="3">
            <div className="card rounded service-wrapper rounded border-0 shadow p-4" style={{ width: '335px', height: '300px' }}>
              <h5 class="text-center AcerhealthTextPurple testourvalues1" style={{fontFamily: "Alegreya Sans", fontWeight: 'bold' }}>Client Satisfaction</h5>
              <p className="justify-content-left" style={{ height: '150px', fontSize: '18px', fontFamily: "Alegreya Sans",fontWeight:'500' }}>
                AcerHealth prioritizes customer happiness with personalized treatment plans, modern technology, and exceptional service, striving to exceed expectations and foster enduring relationships.
              </p>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
};

export default AboutAcerhealth;
