import ReactDOM from "react-dom/client";
import useState from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navband from "./components/navband";
import Navigation from "./components/navigation";
import Footer from "./components/footer";
import Home from "./pages/Home";
import About from "./pages/AboutAcerHealth";
import Contact from "./pages/Contact";
import Solution from "./pages/Solutions";
import RCM from "./pages/Services_RCM";
import MBS from "./pages/Services_MedicalBilling";
import Consultancy from './pages/Services_Consultancy';
import Credentialing from './pages/Services_Credentialing';
import StartPractice from './pages/Service_StartPracticeService';
import Contract from './pages/Services_ContractCompliance';
import Blogs from './pages/Blogs';
import Blogs1 from './pages/Blogs/Whats_the_Difference_Between_Medical_Billing_and_Credentialing';
import Blogs2 from './pages/Blogs/Choosing_to_outsource_your_medical_billing_is_a_smart_decision';
import Blogs3 from './pages/Blogs/Maximize_Revenue_and_Minimize_Hassles_with_AcerHealth_Experts_Medical_Billing_Services';
import Blogs4 from './pages/Blogs/Unveiling_the_Core_Concepts_of_Electronic_Health_Records_EHRs';
import Blogs5 from './pages/Blogs/Best_Practices_for_Improving_Revenue_Cycle_Management_AcerHealth';
import Blogs6 from './pages/Blogs/How_to_Accelerate_claims_processing_technology';
import Blogs7 from './pages/Blogs/Obtain_Billing_Services_from_the_Best_Medical_Billing_Company';
import Blogs8 from './pages/Blogs/Techniques_to_Increase_Patient_Engagement';
import Blogs9 from './pages/Blogs/How_to_Prevent_Medical_Billing_Rejections';
import Blogs10 from './pages/Blogs/What_Does_Billing_Mean_to_Patients';
import Blogs11 from './pages/Blogs/Challenges_in_managing_the_healthcare_revenue_cycle';
import Blogs12 from './pages/Blogs/AcerHealth_medicalbilling_techniques';
import Blogs13 from './pages/Blogs/Medical_Billing_Trends_to_Watch_in_2023';
import Blogs14 from './pages/Blogs/Comparing_Healthcare_Revenue_Cycle_Management_Vs_Health_Informatics';
import Blogs15 from './pages/Blogs/Management_of_the_Healthcare_Revenue_Cycle';
import Blogs16 from './pages/Blogs/Details_about_Patients _is_protected_by_AcerHealth';
import Blogs17 from './pages/Blogs/De_Identified_Health_Information';
import Blogs18 from './pages/Blogs/Potentially_how_can_we_increase_your_Revenues';
import Testimonials from './pages/Testimonials';
import Eligibility from './pages/Tech_Eligibility';
import PatientPayment from './pages/Tech_PatientPayment';
import SmartReport from './pages/Tech_SmartReport';
import PreAuth from './pages/Tech_PreAuthorization';
import Cardologyspeciality from "./pages/Specialist_cardologyInfo";
export default function App() {
  return (
    <Router>
      <Navband />
      <Navigation />
      <Routes>
        <Route path="/" exact element={<Home/>} />
        {/* <Route path="blogs/*" element={<Blogs />} /> */}
        <Route path="/about-us" element={<About/>} />
        <Route path="/contact-us" element={<Contact/>} />
        <Route path="/services/revenue-cycle-management" element={<RCM/>} />
        <Route path="/services/medical-billing-services" element={<MBS/>} />
        <Route path="/services/medical-consultancy-services" element={<Consultancy />} />
        <Route path="/services/medical-billing-and-credentialing" element={<Credentialing />} />
        <Route path="/services/set-up-practice" element={<StartPractice />} />
        <Route path="/services/contract-compliance" element={<Contract />} />
        <Route path="/medical-billing-blogs" element={<Blogs />} />
        <Route path="/blogs/whats_the_difference_between_medical_billing_and_credentialing" element={<Blogs1 />} />
        <Route path="/blogs/choosing_to_outsource_your_medical_billing_is_a_smart_decision" element={<Blogs2 />} />
        <Route path="/blogs/maximize_revenue_and_minimize_hassles_with_acerhealth_experts_medical_billing_services" element={<Blogs3 />} />
        <Route path="/blogs/unveiling_the_core_concepts_of_electronic_health_records_EHRs" element={<Blogs4 />} />
        <Route path="/blogs/best_practices_for_improving_revenue_cycle_management" element={<Blogs5 />} />
        <Route path="/blogs/how_to_accelerate_claims_processing_technology" element={<Blogs6 />} />
        <Route path="/blogs/obtain_billing_services_from_the_best_medical_billing_company" element={<Blogs7 />} />
        <Route path="/blogs/Techniques_to_Increase_Patient_Engagement" element={<Blogs8 />} />
        <Route path="/blogs/how_to_prevent_medical_billing_rejections" element={<Blogs9 />} />
        <Route path="/blogs/what_does_billing_mean_to_patients" element={<Blogs10 />} />
        <Route path="/blogs/Challenges_in_managing_the_healthcare_revenue_cycle" element={<Blogs11 />} />
        <Route path="/blogs/acerhealth_medicalbilling_techniques" element={<Blogs12 />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/technologies/patient-balance-estimator" element={<Eligibility />} />
        <Route path="/technologies/patient-statements-payment-portal" element={<PatientPayment />} />
        <Route path="/technologies/practice-analytics" element={<SmartReport />} />
        <Route path="/technologies/patient-eligibility-verification" element={<PreAuth />} />
        <Route path="/medical-billing-specialities" element={<Solution />} />
        <Route path="/Cardologyspeciality" element={<Cardologyspeciality />} />
        <Route path="/blogs/medical_billing_trends_to_watch_in_2023_24" element={<Blogs13 />} />
        <Route path="/blogs/comparing_healthcare_revenue_cycle_management_vs_health_informatics" element={<Blogs14 />} />
        <Route path="/blogs/management_of_the_healthcare_revenue_cycle" element={<Blogs15 />} />
        <Route path="/blogs/details_about_patients_is_protected_by_acerhealth" element={<Blogs16 />} />
        <Route path="/blogs/de_identified_health_information" element={<Blogs17 />} />
        <Route path="/blogs/Potentially_how_can_we_increase_your_Revenues" element={<Blogs18 />} />
      </Routes>
      <Footer />
    </Router>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
