import React from "react";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
import { Link } from 'react-router-dom';
import BlogImg1 from "../img/Blogbanners1/difference between medical billing and credentialing1.jpg";
import BlogImg2 from "../img/Blogbanners1/choosing to outsource your medical billing is a smart decision1.jpg";
import BlogImg3 from "../img/Blogbanners1/Maximize revenue and minimize hassles with acerhealth experts1.jpg";
import BlogImg4 from "../img/Blogbanners1/unveiling the core concepts of ehr (1).jpg";
import BlogImg5 from "../img/Blogbanners1/best practices for improving rcm1.jpg";
import BlogImg6 from "../img/Blogbanners1/how to accelerate claims processing1.jpg";
import BlogImg7 from "../img/Blogbanners1/obtaining medical billing services from the best medical1.jpg";
import BlogImg8 from "../img/Blogbanners1/techniques to increase patient engagement1.jpg";
import BlogImg9 from "../img/Blogbanners1/how to prevent medical billing rejections1.jpg";
import BlogImg10 from "../img/Blogbanners1/what does billing mean to patients1.jpg";
import BlogImg11 from "../img/Blogbanners1/challenges in managing healthcare rev cycle1.jpg";
import BlogImg12 from "../img/Blogbanners1/acer's medical billing techniques1.jpg";
import BlogImg13 from "../img/Blogbanners1/medical billing trends to watch 20231.jpg";
import BlogImg14 from "../img/Blogbanners1/comparing healthcare rcm vs health informatics1.jpg";
import BlogImg15 from "../img/Blogbanners1/management of healthcare revenue cycle1.jpg";
import BlogImg16 from "../img/Blogbanners1/details about patients are protected by acer1.jpg";
import BlogImg17 from "../img/Blogbanners1/potentially how can we increase your revenue1.jpg";
import BlogImg18 from "../img/Blogbanners1/how outsourcing medical billing will help your practice1.jpg";
import "../css/bestpractice.css";
export default function App() {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <MDBRow className="row-cols-1 row-cols-md-3 g-4">
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg1}
                alt="Medical Claims Processing"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/whats_the_difference_between_medical_billing_and_credentialing" style={{ fontFamily: 'Alegreya Sans', fontWeight: 'bold' }}>
    What’s the Difference Between Medical Billing and Credentialing
  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg2}
                alt="Outsourcing Medical Billing service"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/choosing_to_outsource_your_medical_billing_is_a_smart_decision" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Choosing to Outsource Your Medical Billing is a Smart
                    decision?
                  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg4}
                alt="EHR Solutions"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/unveiling_the_core_concepts_of_electronic_health_records_EHRs" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Unveiling the Core Concepts of Electronic Health Records (EHRs)
                  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
                     <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg5}
                alt="Revenue Cycle Management"
                position="top"
                // style={{height:'276px'}}
                className="bestpractice"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/best_practices_for_improving_revenue_cycle_management" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Best Practices for Improving Revenue Cycle Management – AcerHealth 
                  </a>
                </MDBCardTitle>
                <MDBCardText className="pb-4">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
                    <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg8}
                alt="healthcare consulting service"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/Techniques_to_Increase_Patient_Engagement" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Techniques to Increase Patient Engagement
                  </a>
                </MDBCardTitle>
                <MDBCardText className="pb-4">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg6}
                alt="Claims Processing Technology"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/how_to_accelerate_claims_processing_technology" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  How to Accelerate Claims Processing Technology
                  </a>
                </MDBCardTitle>
                <MDBCardText className="pb-4">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg7}
                alt="Medical billing company in usa"
                position="top"
                // style={{height:'270px'}}
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/obtain_billing_services_from_the_best_medical_billing_company" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Obtain Billing Services from the Best Medical Billing
                    Company
                  </a>
                </MDBCardTitle>
                <MDBCardText className="pb-4">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg3}
                alt="Medical Billing Experts"
                position="top"
                // style={{height:'267px'}}
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/maximize_revenue_and_minimize_hassles_with_acerhealth_experts_medical_billing_services" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Maximize Revenue and Minimize Hassles with AcerHealth
                    Experts Medical Billing Services
                  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol> 
                    <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg9}
                alt=" Preventing medical billing rejections"
                position="top"
                // style={{height:'270px '}}
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/how_to_prevent_medical_billing_rejections" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    How to Prevent Medical Billing Rejections
                  </a>
                </MDBCardTitle>
                <MDBCardText className="pb-4">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg10}
                alt="Patients Medical Billing"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/what_does_billing_mean_to_patients" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    What Does Billing Mean to Patients
                  </a>
                </MDBCardTitle>
                <MDBCardText className="pb-4">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg11}
                alt="Healthcare Revenue Cycle Management"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/Challenges_in_managing_the_healthcare_revenue_cycle" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    Challenges in Managing the Healthcare Revenue Cycle
                  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg12}
                alt="Acerhealth's Medical Billing Technologies"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/acerhealth_medicalbilling_techniques" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                    AcerHealth's Medical Billing Techniques
                  </a>
                </MDBCardTitle>
                <MDBCardText className="">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
                    <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg16}
                alt="HIPPA act"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/details_about_patients_is_protected_by_acerhealth" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Details about Patients is Protected by AcerHealth
                  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol> 
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg14}
                alt="Healthcare Revenue Cycle Management"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/comparing_healthcare_revenue_cycle_management_vs_health_informatics" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Comparing Healthcare Revenue Cycle Management Vs. Health Informatics
                  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg15}
                alt="Healthcare Revenue Cycle Management"
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/management_of_the_healthcare_revenue_cycle" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Management Of the Healthcare Revenue Cycle
                  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg13}
                alt="Medical Billing Trends"
                position="top"
                // style={{height:'250px'}}
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/medical_billing_trends_to_watch_in_2023_24" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Medical Billing Trends to Watch in 2024
                  </a>
                </MDBCardTitle>
                <MDBCardText className="pb-4">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg17}
                alt="Acerhealth's Medical Billing Services" 
                position="top"
                // style={{height:'245px'}}
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/de_identified_health_information" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Potentially How Can We Increase Your Revenues
                  </a>
                </MDBCardTitle>
                <MDBCardText>
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src={BlogImg18}
                alt="Outsourcing Medical Billing service"
                position="top"
                // style={{height:'250px'}}
              />
              <MDBCardBody>
                <MDBCardTitle className="cardtitletest1">
                <a href="/blogs/potentially_how_can_we_increase_your_revenues" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  How Outsourcing Medical Billing Will Help Your Practice
                  </a>
                </MDBCardTitle>
                <MDBCardText className="pt-1">
                  <small className="text-muted" style={{fontFamily:'Alegreya Sans',fontWeight:'500'}}>AcerHealth | Aug - 2024</small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
}
