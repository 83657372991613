import React from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardImage,
  MDBCardOverlay,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import service1 from "../img/home/service1.png";
import service2 from "../img/home/service2.png";
import service3 from "../img/home/service3.png";
import service4 from "../img/home/service4.png";
import service5 from "../img/home/service5.png";
import service7 from "../img/pre_autho.png";
import "../css/service.css";

export default function App() {
  return (
    <MDBContainer fluid className="p-0 bg-white servicetest">
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="section-heading1">
              {/* <div className="title mb-4 AcerhealthTextPurple text-center"  style={{fontSize:'45px',fontFamily:'EBGarmond semi bold',fontWeight:'bold'}}> */}
                <h1 className="title mb-4 AcerhealthTextPurple text-center servicespace3" style={{fontFamily:'Lora, serif'}}>Services</h1>
              {/* </div> */}
              <p className="para-desc mx-auto mb-0 display-9 justify-content-left servicespace" style={{fontFamily: "Alegreya Sans",fontWeight:'500' }}>
              We offer comprehensive medical billing services to help your practice's profitability, increase patient satisfaction, and streamline your operations.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
        <div className="col-lg-4 col-md-12 mb-4 mb-md-4">
            <MDBCard className="service-wrapper">
              <MDBCardImage src={service1} alt="Comprehensive Medical Billing Services" position="top" />
              <MDBCardBody>
                <MDBCardTitle className="AcerhealthTextPurple servicespace1" style={{fontFamily: 'Alegreya Sans',fontWeight:'bold'}}>Comprehensive Medical Billing Services</MDBCardTitle>
                <MDBCardText className="servicespace2" style={{fontFamily: "Alegreya Sans",fontWeight:'500'}} >
                While medical billing can be one of the most challenging and time-consuming tasks for healthcare practitioners, it remains a crucial aspect of the practice. AcerHealth is a top-tier medical billing firm that simplifies revenue cycle management, allowing healthcare practitioners to focus on delivering high-quality patient care.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>

          <div className="col-lg-4 col-md-12 mb-4 mb-md-4">
            <MDBCard className="service-wrapper">
              <MDBCardImage src={service2} alt="Credentialing Services" position="top" />
              <MDBCardBody>
                <MDBCardTitle className="AcerhealthTextPurple servicespace1" style={{fontFamily: 'Alegreya Sans',fontWeight:'bold'}}>Credentialing Services</MDBCardTitle>
                <MDBCardText style={{fontFamily: "Alegreya Sans",fontWeight:'500'}} className="servicespace2">
                Acer Health has a dedicated group of credentialing experts who will go above and above to give you exceptional service that effortlessly fits into your practice operations. These experts have years of experience and high levels of ability. More than 300 providers have received credentialing services from us.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>

          <div className="col-lg-4 col-md-12 mb-4 mb-md-4">
            <MDBCard className="service-wrapper">
              <MDBCardImage src={service3} alt="Set Up a Practice" position="top" />
              <MDBCardBody>
                <MDBCardTitle className="AcerhealthTextPurple servicespace1" style={{fontFamily: 'Alegreya Sans',fontWeight:'bold'}}>Set Up a Practice</MDBCardTitle>
                <MDBCardText style={{fontFamily: "Alegreya Sans",fontWeight:'500' }} className="servicespace2">
                AcerHealth offers dedicated support to providers navigating the challenges of setting up a medical practice. When setting up a solo or a group practice, business acumen often holds more significance than clinical expertise. Acer takes care of the backend work, giving providers the confidence to focus on patient care.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className="col-lg-4 col-md-12 mb-4 mb-md-4">
            <MDBCard className="service-wrapper">
              <MDBCardImage src={service7} alt="Pre-Authorization Assistance" position="top" />
              <MDBCardBody>
                <MDBCardTitle className="AcerhealthTextPurple servicespace1" style={{fontFamily: 'Alegreya Sans',fontWeight:'bold'}}>Pre-Authorization Assistance</MDBCardTitle>
                <MDBCardText style={{fontFamily: "Alegreya Sans",fontWeight:'500' }} className="servicespace2">
                Streamline your processes and prevent payment delays with our pre-authorization assistance services. We work with insurance companies and obtain pre-authorizations for procedures, ensuring a smooth cash flow.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className="col-lg-4 col-md-12 mb-4 mb-md-4">
            <MDBCard className="service-wrapper">
              <MDBCardImage src={service4} alt="Provider Credential Maintenance" position="top" />
              <MDBCardBody>
                <MDBCardTitle className="AcerhealthTextPurple servicespace1" style={{fontFamily: 'Alegreya Sans',fontWeight:'bold'}}>Provider Credential Maintenance</MDBCardTitle>
                <MDBCardText style={{fontFamily: "Alegreya Sans",fontWeight:'500' }} className="servicespace2">
                Compliance is key. We take the hassle out of credential maintenance, keeping your provider credentials up-to-date with commercial insurances and government payers. 
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>

          <div className="col-lg-4 col-md-12 mb-md-4">
            <MDBCard className="service-wrapper">
              <MDBCardImage src={service5} alt="MIPS Attestation" position="top" />
              <MDBCardBody>
                <MDBCardTitle className="AcerhealthTextPurple servicespace1" style={{fontFamily: 'Alegreya Sans',fontWeight:'bold'}}>MIPS Attestation</MDBCardTitle>
                <MDBCardText style={{fontFamily: "Alegreya Sans",fontWeight:'500' }} className="servicespace2">
                Maximize your Medicare payments and avoid penalties with our MIPS attestation support. We guide you through the attestation process to achieve optimal results.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
    
        </div>
      </div>
    </MDBContainer>
  );
}
