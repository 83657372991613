import React from 'react';
import {MDBContainer,} from "mdb-react-ui-kit";

const Patientbalanceest = () => {
    return (
        <MDBContainer fluid className="p-0">
        <div className="container py-5">
            <div className="row">
              <div className="col-12">
                  <div className="section-title mb-4 pb-2">
                      <div className="title mb-4 display-heading AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}> Patient Balance Estimator</div>
                      <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>       One of the biggest challenges providers face today is collecting past dues from the patients. In healthcare, the providers must wait for months to get compensated after rendering valuable service. MDBill’s patient balance estimator helps your front desk to determine out-of-pocket patient responsibility, insurance eligibility, and benefit information resulting in better collections at the time of patients visits. It’s much easier to request payment when patients can see what they owe.</p>
                  </div>
              </div>
          </div>
        </div>    
        </MDBContainer>
    );
};
export default Patientbalanceest;