import React, { useEffect, useState } from 'react';
import { MDBContainer, } from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/img_banner';
import Info from '../components/services_mbs_info';
import Desc from '../components/services_mbs_desc';

import DownContact from '../components/downcontact';
import RCMcy from '../img/medical-billing-services-icons2.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const MedicalBillingService = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Professional Healthcare Medical Billing - USA - AcerHealth';
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Medical Billing Services');

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({ title: "Medical Billing Services", subtitle: "Effortlessly Secure Pre-authorizations, Streamline Processes, and Prevent Payment Delays with Us.", img: `url(${RCMcy})` });

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({ title: "Generate liquidity for the work you do. Our highly experienced staff creates revenue prospects for medical practices that result in significant and predictable reimbursements. " });


  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://acerhealth.com/services/medical-billing-services" />
        <meta name="description" content="We are committed to delivering unmatched professional medical billing & RCM Solutions that drives success & profitability in your practice. Get a qoute now!" />
        <meta name="keywords" content="Medical Billing Service" />
      </Helmet>
      {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
      <Banner BannerTitle={BannerTitle} />
      <Info />
      <Desc />
      <DownContact DownContactTitle={DownContactTitle} />

    </>
  );
};

export default MedicalBillingService;