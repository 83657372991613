import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import testimonial1 from "../img/testimonial images/kambiz.png";
import testimonial2 from "../img/testimonial images/kochuraani.png";
import testimonial3 from "../img/testimonial images/leelamma george.png";
import testimonial4 from "../img/testimonial images/praveen.png";
import testimonial5 from "../img/testimonial images/Saifullah nasir.png";
import testimonial6 from "../img/testimonial images/zarina.png";
import testimonial7 from "../img/testimonial images/Omair.png";
import testimonial8 from "../img/testimonial images/Adil Usman.png";
import testimonial9 from "../img/testimonial images/shadi marina.png";
import testimonial20 from "../img/testimonial images/sathish.png";
const testimonialdetails = () => {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <MDBRow className="p-4">
          <MDBCol md="4">
            <img src={testimonial7} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Dr-Omair Siraj"/>
          </MDBCol>
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
                <p className="justify my-auto">
                  <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Omair Siraj, CEO</div>
                  <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Chestnut Health, IL</div>
                  <i class="fa fa-quote-left"></i> We requested comments on our AR and ideas for enhancements. 
                  Our practice's financial and operational health was thoroughly assessed by Acer. 
                  We asked for criticism on our AR as well as ideas for enhancement. 
                  Information about our assertion was given to AcerHealth. <br /><br />
                  <p>
                  AcerHealth provided us with a comprehensive analysis of our financial and operational health. 
                  Acer's online reporting platform has an easy-to-use user interface and helpful dashboards. 
                  As a result of this study, we are able to identify and isolate problematic areas and operational 
                  silos in order to increase efficiency and maximize revenue. We are using this data to identify and 
                  unproductive services in order to increase productivity and revenue.  <i class="fa fa-quote-right"></i>
                </p>
                </p>
                
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
        <MDBRow className="p-4">
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Adil Usman , MD</div>
            <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Premier Dermatology Ashburn, Virginia</div>
              <i class="fa fa-quote-left"></i>  An additional distinction that
              sets AcerHealth apart is its integrity in working on denied
              claims. Other companies will only handle the highest-paying
              claims. Although AcerHealth follows up on each payment, whether
              it's $50 or $2,000, it maximizes our collections and improves our
              revenue. <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
          <MDBCol md="4" className="text-center">
            <img src={testimonial8} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Dr-Adil Usman"/>
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
        <MDBRow className="p-4">
          <MDBCol md="4">
            <img src={testimonial5} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Dr-Saifullah Nasir"/>
          </MDBCol>
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
              <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Saifullah Nasir, MD</div>
              <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Stat Cardiologists, Chicago IL</div>
              <i class="fa fa-quote-left"></i>Initially, we underestimated the complexity of the credentialing and claims submission process, assuming it could be handled by an MD. However, after two months, it became clear that we were mistaken. Acer's intervention marked a turning point, with the company excelling in its role. They diligently provided regular updates on credentialing progress, complete with detailed call notes. Remarkably, Acer managed to credential us with 90% of insurance providers within the first four months. I extend my heartfelt appreciation for Acer's outstanding efforts.<i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
                <MDBRow className="p-4">
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Zarina, Executive Director</div>
            <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Premier Dermatology Ashburn, VA</div>
              <i class="fa fa-quote-left"></i>Our collaboration with AcerHealth is a source of satisfaction due to their attentive approach. When I communicate our specific needs and requirements, I am confident that they genuinely listen and take action accordingly. AcerHealth consistently demonstrates a high level of flexibility and a genuine commitment to ensuring that our expectations are met. Their customer service is characterized by a tangible dedication to meeting our needs, not just verbal assurances.
              revenue. <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
          <MDBCol md="4" className="text-center">
          <img src={testimonial6} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Dr-Zarina Usman"/>
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
        <MDBRow className="p-3">
        <MDBCol md="4">
            <img src={testimonial1} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Dr-kambiz-yazdani"/>
          </MDBCol>
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Kambiz Yazdani, MD</div>
            <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>NOVA Cardiovascular Care, Stafford VA</div>
              <i class="fa fa-quote-left"></i>Acer Health's billing and coding professionals have consistently demonstrated exceptional competence and attention to detail. Since engaging Acer for our medical billing needs, instances of insurance denials have become rare occurrences. Acer has afforded our practice the tailored and essential attention required to operate efficiently and successfully. Remarkably, over the course of the past year and a half, not a single complaint has arisen regarding our medical billing processes under their stewardship.{" "}
              <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
                        <MDBRow className="p-4">
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Leelamma George, MD</div>
            <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>MD Colonial Heights, VA</div>
              <i class="fa fa-quote-left"></i> We are extremely pleased with the
              quality of services and knowledge Acer Health provides. By
              outsourcing our billing, we can trust that it will be handled with
              confidence; claims will be submitted efficiently, information will
              be inputted accurately, and attention to detail will always be a
              priority. Our reimbursements are now maximized to their full
              potential. Acer has handled every aspect of our billing, and every
              question has always been turned into a solution. They are quick to
              respond to requests needed in our office to help our productivity.
              Their continued quality service has resulted in a major decrease
              in our accounts receivable and I can depend on their professional
              staff getting the job done right, and in a timely manner.{" "}
              revenue. <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
          <MDBCol md="4" className="text-center">
          <img src={testimonial3} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Dr-Leelamma George"/>
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
        <MDBRow className="p-3">
        <MDBCol md="4">
            <img src={testimonial2} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Dr-Kochurani Puthumana"/>
          </MDBCol>
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Kochurani Puthumana, MD</div>
            <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Colonial Heights, VA</div>
              <i class="fa fa-quote-left"></i> As a doctor in these times, I
              understand the importance of dependable service. Acerhealth has
              helped me un-focus from the billing side of my practice and focus
              on taking care of my patients. Since we started with Acer, our
              collections have increased by 30% without adding any additional
              providers or services to our practice.{" "}
              <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
         <MDBRow className="p-4">
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Shadi Marina, MD</div>
            <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Streamwood, IL</div>
              <i class="fa fa-quote-left"></i>  In my capacity as a medical professional during these challenging times, I have come to appreciate Acer's exceptional customer service managers who consistently exhibit a profound understanding of our needs. They consistently go the extra mile to address even the most minor of requests. Acer's dedicated team of experts consistently delivers the desired outcomes, achieving an impressive 99% collection rate.{" "}
              <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
          <MDBCol md="4" className="text-center">
          <img src={testimonial9} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Dr-Leelamma George"/>
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
        <MDBRow className="p-3">
        <MDBCol md="4">
            <img src={testimonial20} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="sathish"/>
          </MDBCol>
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Sathish Cullath, MD</div>
            <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Premier Health Consultants, Streamwood, IL</div>
              <i class="fa fa-quote-left"></i> Acer Health staff is reliable and efficient, and they
                        have significantly increased my collections. I was
                        concerned about changing billing services, but they made
                        the transition seamless. There was no delay in our
                        accounts receivables, in fact, I saw a significant
                        increase in the number of collections we received. Now
                        we get paid by the insurance companies more quickly than
                        I ever before.{" "}
              <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
        <MDBRow className="p-3">
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Vinay Satti, MD</div>
              <i class="fa fa-quote-left"></i> Patient base without adding staff
              thanks to AcerHealth’s time and cost saving services. Doctors
              today have a hard time doing good work and being profitable.
              Reimbursements are down, overhead is up. AcerHealth helps us stay
              efficient in a tough environment.{" "}
              <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
          <MDBCol md="4">
            {/* <img src={testimonial1} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Vinay Satti"/> */}
          </MDBCol>
        </MDBRow>
        <hr style={{ border: "2px Solid #582C83" }}></hr>
        <MDBRow className="p-3">
        <MDBCol md="4">
            {/* <img src={testimonial1} className="img-fluid" style={{ borderRadius:'10px',height:'250px'}} alt="Donna"/> */}
          </MDBCol>
          <MDBCol md="8" className="d-flex" style={{fontFamily: "Alegreya Sans",fontSize:'18px',fontWeight:'500'}}>
            <p className="justify my-auto">
            <div className="AcerhealthTextPurple h5" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>Donna</div>
            <div className="AcerhealthTextPurple h6" style={{fontFamily: "Alegreya Sans",fontWeight:'bold'}}>NOVA Cardiovascular Care, Stafford, VA</div>
              <i class="fa fa-quote-left"></i> The practice manager says
              “AcerHealth is now an integral part of our practice. MDBill
              provides all the information including past patient balances,
              insurance status and co-pays are in one single screen. Our staff
              spends less than 30 seconds to check out a patient using MDBill
              from Acer. Our patient statement charges have also gone down by
              almost 30% and our front desk collections have risen by 150% since
              we started using MDBill from Acer{" "}
              <i class="fa fa-quote-right"></i>
            </p>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
};

export default testimonialdetails;
