import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBBtn,
    MDBInput,
    MDBIcon,
    MDBContainer,
} from "mdb-react-ui-kit";
import "../css/imgbanner.css";

const IMGBanner = ({BannerTitle}) => {
    return (
        <MDBContainer fluid className='p-0'>
            <header>
                <div
                    className='p-5 text-center bg-image'
                    style={{ backgroundImage: BannerTitle.img,
                        minHeight: "500px",
                        backgroundPosition: "top",
                        backgroundRepeat: "repeat",
                         backgroundPositionX:'75%',
                         backgroundPositionY:'25%'
                 }}
                >              
                        <div className="col-md-12 col-lg-6 p-3 text-light text-start imgbanner" style={{ backgroundColor: "rgba(103, 25, 185, 0.5)", borderRadius:'10px'}}>
                            <h1 style={{fontFamily: 'Alegreya Sans' }}>
                            {BannerTitle.title}
                            </h1>
                            <p className="head-para" style={{fontSize:'26px',fontFamily:'Alegreya Sans'}}>
                            {BannerTitle.subtitle}
                            </p>
                        </div>
                        <div class="col-md-12 col-lg-6 mt-md-10 mt-sm-5 form ">
                   
                        </div>
                </div>
                </header>
        </MDBContainer>
    );
};
export default IMGBanner;


