import React, { useRef, useState,useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardHeader,
  MDBCol,
  MDBCardBody,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTabsContent,
  MDBIcon,
  MDBCheckbox,
  MDBInput,
  MDBBtn,
  MDBTextArea,
  MDBSpinner,
} from "mdb-react-ui-kit";
import Background from "../img/contactback2.svg";
import axios from "axios";


function App() {
  const [Selectedfirstname, setfirstname] = useState("");
  const [selectedemail, setemail] = useState("");
  const [selectedphone, setcity] = useState("");
  const [selectedmessage, setmessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const usPhoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const handlefirstnamechange = (event) => {
    setfirstname(event.target.value);
  };
  const handlechangeemail = (event) => {
    setemail(event.target.value);
  };
  const handlechangephone = (event) => {
    setcity(event.target.value);
  };
  const handlechangemessage = (event) => {
    setmessage(event.target.value);
  };

  const clearSuccessMessage = () => {
    setSuccessMessage("");
  };
  const [errors, setErrors] = useState({
    firstname: '',
    email: '',
    phone: '',
    message: '',
  });
  
  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };
  
    if (!Selectedfirstname.trim()) {
      newErrors.firstname = 'Name is required';
      valid = false;
    } else {
      newErrors.firstname = '';
    }
    if (!selectedemail.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!emailRegex.test(selectedemail)) {
      newErrors.email = 'Invalid email address';
      valid = false;
    } else {
      newErrors.email = '';
    }
  if (!selectedphone) {
    newErrors.phone = 'Phone number is required';
    valid = false;
  } else if (!usPhoneRegex.test(selectedphone)) {
    newErrors.phone = 'Invalid US phone number';
    valid = false;
  } else {
    newErrors.phone = '';
  }
    if (!selectedmessage.trim()) {
      newErrors.message = 'Message is required';
      valid = false;
    } else {
      newErrors.message = '';
    }
  
    // You can add more validation rules for email, phone, etc.
  
    setErrors(newErrors);
    return valid;
  };
  const handleInsertion = () => {
    if (validateForm()) {
      setIsLoading(true);
    const formData = new FormData();
    // Add other form fields to formData
    formData.append("name", Selectedfirstname);
    formData.append("email", selectedemail);
    formData.append("message", selectedmessage);
    formData.append("phone", selectedphone);

    console.log(formData);
    // Make a POST request to your PHP insertion API
    axios
      .post("https://mdbill.io/websiteemail/testfile.php", formData)
      .then((response) => {
      })
      .catch((error) => {
        console.error(error);
        setfirstname('');
        setemail('');
        setcity('');
        setmessage('');
        setSuccessMessage("Form submitted successfully");
        setTimeout(clearSuccessMessage, 3000);
        setIsLoading(false);
      });
    }
};
  return (
    <MDBContainer fluid className="p-0">
      <section
        className="background-radial-gradient overflow-hidden"
        style={{
          backgroundImage: `url(${Background})`,
          minHeight: "500px",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
              <h1
                className="my-5 fw-bold ls-tight"
                style={{ color: "hsl(218, 81%, 95%)" }}
              >
                <span style={{ color: "#0072BB",fontFamily:'Lora, serif'}}>
                  LET'S DISCUSS HOW WE CAN HELP YOU STREAMLINE YOUR PROCESS
                </span>
              </h1>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div
                id="radius-shape-1"
                className="position-absolute rounded-circle shadow-5-strong"
              ></div>
              <div
                id="radius-shape-2"
                className="position-absolute shadow-5-strong"
              ></div>

              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                    <MDBInput
                      className="mb-3"
                      id="first2"
                      label="Name"
                      name="name"
                      onChange={handlefirstnamechange} value={Selectedfirstname}
                      autocomplete="off"
                    />
                    {errors.firstname && <div className="error-message"style={{color:'red'}}>{errors.firstname}</div>}
                    <MDBInput
                      className="mb-3"
                      type="email"
                      id="email2"
                      label="Email ID"
                      name="email"
                      onChange={handlechangeemail} value={selectedemail}
                      autocomplete="off"
                    />
                     {errors.email && <div className="error-message"style={{color:'red'}}>{errors.email}</div>}
                    <MDBInput
                      className="mb-3"
                      type="phone"
                      id="phone"
                      label="Phone No."
                      name="phone"
                      onChange={handlechangephone} value={selectedphone}
                      autocomplete="off"
                    />
                    {errors.phone && <div className="error-message"style={{color:'red'}}>{errors.phone}</div>}
                    <MDBTextArea
                      className="mb-3"
                      label="Message"
                      id="textAreaExample"
                      rows={4}
                      name="messagefield"
                      onChange={handlechangemessage} value={selectedmessage}
                      autocomplete="off"
                    />
                     {errors.message && <div className="error-message"style={{color:'red'}}>{errors.message}</div>}
                    <MDBRow className="mb-4 justify-content-center">
                      <MDBCol md="12" className="d-flex justify-content-center">
                        <MDBCheckbox
                          className=" mb-3 mb-md-0"
                          defaultChecked
                          label="On submiting I authorize AcerHealth to contact me on above details."
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBBtn
                      type="submit"
                      className="mb-4 d-grid gap-2 col-5 mx-auto"
                      style={{ backgroundColor: "#3B0075" }}
                      onClick={handleInsertion}
                    >
                  {isLoading ? (
              <div className="d-flex align-items-center">
                <strong>Sending...</strong>
                <MDBSpinner className="ms-auto" size='sm' role="status" />
              </div>
            ) : (
              "Submit"
            )}
                    </MDBBtn>
                </div>
                {successMessage && (
  <div className="alert alert-success">{successMessage}</div>
)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </MDBContainer>
  );
}

export default App;
