import React, { useState, useEffect } from "react";
import {
    MDBContainer,
    MDBBtn, 
    MDBIcon
} from "mdb-react-ui-kit";
import ContactModal from '../modals/modal_contact';
const DownContact = ({DownContactTitle}) => {
    const [basicModal, setBasicModal] = useState(false);
    const toggleShow = () => setBasicModal(!basicModal);
      const handleCloseModal = () => {
      setBasicModal(false);
    };
    return (
      <MDBContainer fluid className='p-0 bg-warning'>
      <div className="container py-5">
        <section id="services-section3">
            <div class="container">
                <div class="row justify-content-around align-items-center">
                    <div class="col-md-3 mb-3">
                        <div class="action-btn">
                        <MDBBtn style={{backgroundColor: "#3B0075",  float:'left'}} >
                        <MDBIcon fas icon="hands-helping" className='me-2' /> <span className="ml-5" onClick={toggleShow}> Schedule a 1:1 Meeting</span>
                        </MDBBtn>
                        <ContactModal show={basicModal} onClose={handleCloseModal} />
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="subscribe-content">
                            <h5 className='AcerhealthTextPurple' style={{fontSize:'20px',fontFamily:'Alegreya Sans',fontWeight:'500'}}>
                               {DownContactTitle.title}
                            </h5>
                            <p class="mb-lg-0 mb-md-0 text-white">
                              {DownContactTitle.subtitle}
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </div>

    </MDBContainer>
    );
};
export default DownContact;


