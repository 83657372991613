import React from 'react';
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer, MDBNavbar } from 'mdb-react-ui-kit';

export default function Breadcrumb({Breadcrumb}) {

  return (
    <MDBNavbar expand='lg' >
      <MDBContainer>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <span className='display-7 AcerhealthTextPurple'>{Breadcrumb}</span>
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </MDBContainer>
    </MDBNavbar>
  );
}