import React, { useState } from 'react';
import {
  MDBFooter,
} from "mdb-react-ui-kit";
import CCHITimg from "../img/certificates/cchitimg.png";
import teprawards from "../img/certificates/teperawards.png";
import surescripts from "../img/certificates/surescripts.png";
import ACgroupimg from "../img/certificates/acgroupimage.png";
import drtimg from "../img/certificates/drtimg.png";
import "../css/certificate.css";

export default function App() {
  return (
    <MDBFooter className="text-center text-lg-start">
      <div className="container" style={{ position: 'relative' }}>
        <div className="certificate-wrapper" width="100%" style={{ position: 'absolute', top: '-60px', left: '50%', transform: 'translateX(-50%)', width: '80%', zIndex: '1' }}>
          <div className="certificate-card rounde border-0 shadow" style={{ backgroundColor: '#FFF   ', padding: '20px', width: '100%', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', position: 'relative' }}>
            <div className="certificate-logo "><img className="cer1" src={CCHITimg} width="100%" alt="Surescripts Logo" /></div>
            <div className="certificate-logo "><img className="cer1" src={teprawards} width="100%" alt="Surescripts Logo" /></div>
            <div className="certificate-logo "><img className="cer1" src={surescripts} width="100%" alt="Surescripts Logo" /></div>
            <div className="certificate-logo "><img className="cer1" src={ACgroupimg} width="100%" alt="Surescripts Logo" /></div>
            <div className="certificate-logo "><img className="cer1" src={drtimg} width="100%" alt="Surescripts Logo" /></div>
            <div style={{ position: 'absolute', width: 0, height: 0, top: '-0px', borderLeft: '60px solid transparent', borderRight: '60px solid transparent', borderBottom: '60px solid #FFF', left: '0', transform: 'translateX(-50%)', zIndex: -1 }} />
            <div style={{ position: 'absolute', width: 0, height: 0, borderLeft: '60px solid transparent', borderRight: '60px solid transparent', borderBottom: '60px solid #FFF ', right: '0', top: '-0px', transform: 'translateX(50%)', zIndex: -1 }} />
          </div>
        </div>
      </div>
    </MDBFooter>
  );
}