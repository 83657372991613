import React,{useRef} from "react";
import { MDBBtn } from 'mdb-react-ui-kit';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBRipple,
} from "mdb-react-ui-kit";
import   "../css/triangle.css";
 import smartreportBanner from "../img/technologies/Infographic/practice analytics template (1).png";
 import smartreportbanner1 from "../img/technologies/Infographic/Final - practice analytics.png";
 import trends from "../img/graphs and trends.svg";
 import performence from "../img/performance report.svg";
 import procedurecode from "../img/procedure code aging.svg";
  import insurance from "../img/insurance reimbursement.svg";
  import indepth from "../img/in depth analysis.svg";
 import benchmarking from "../img/benchmarking.svg";
 import "../css/servicesfont.css";
const Home = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick1 = () => {
    ref1.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick2 = () => {
    ref2.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick3 = () => {
    ref3.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick4 = () => {
    ref4.current?.scrollIntoView({behavior: 'smooth'});
  };
  const handleClick5 = () => {
    ref5.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <MDBContainer fluid className="bg-light p-0">
      <div className="container py-5">

<div class="button-overlay mt-4 d-none d-lg-block" style={{paddingLeft:'270px'}}>
<img
                className="img-fluid"
                src={smartreportBanner}
                style={{ borderRadius: "10px",width:'850px'}}
                alt="Practice"

                
              />
 <MDBBtn color='link' rippleColor='dark' onClick={handleClick} style={{ fontSize: '18px',color:'#1A0434',fontFamily:'Alegreya Sans',marginLeft:'10px', backgroundColor: 'transparent'}} className="button-overlay">
      <b>Benchmarking</b>
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick1} style={{ fontSize: '18px',color:'#1A0434' ,marginTop:'75px',width:'350px',marginLeft:'20px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}} className="button-overlay">
      <b>Procedure Code Aging</b>
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick2} style={{ fontSize: '18px',color:'#1A0434' ,marginTop:'155px',width:'324px',marginLeft:'25px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}} className="button-overlay">
      <b>Insurance Reimbursement</b>
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick3} style={{ fontSize: '18px',color:'#1A0434' ,marginTop:'235px',width:'328px',marginLeft:'20px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}} className="button-overlay">
     <b> Performance Measurment Report</b>
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick4} style={{ fontSize: '18px',color:'#100221' ,marginTop:'310px',width:'260px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}} className="button-overlay">
      <b>In-depth Analysis</b>
    </MDBBtn>
    <MDBBtn color='link' rippleColor='dark' onClick={handleClick5} style={{ fontSize: '18px',color:'#100221' ,marginTop:'390px',width:'180px',marginLeft:'5px',fontFamily:'Alegreya Sans', backgroundColor: 'transparent'}} className="button-overlay">
      <b>Graph and Trends</b>
    </MDBBtn>
</div>

<div class="d-lg-none">
<img
                className="img-fluid"
                src={smartreportbanner1}
                style={{ borderRadius: "10px",width:'100%' }}
                alt="Practice"             
              />
</div>

      <div ref={ref5} className="card mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5 p-4 d-md-none">
            <div className="row">
            <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Graphs and Trends</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                A graph, which is believed to be worth a thousand words, can help the audience understand information graphically. To improve your user experience, we will provide you with meaningful graphs for all of the data that is available.

                </p>
              </div>
              <div class="col-md-4 text-center mt-4">
              <img src={trends} className="img-fluid" width={550} height={450} alt="Graphs and Trends"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 p-4 d-none d-md-block">
            <div className="row">
              <div class="col-md-4 text-center mt-4">
              <img src={trends} className="img-fluid" width={550} height={450} alt="Graphs and Trends"/>
              </div>
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Graphs and Trends</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                A graph, which is believed to be worth a thousand words, can help the audience understand information graphically. To improve your user experience, we will provide you with meaningful graphs for all of the data that is available.

                </p>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div ref={ref4} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>In-depth Analysis </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                For any report, you can find the complete information from practice to provider level details in a few clicks in an effortless way.
                </p>
              </div>
              <div class="col-md-4 text-center ">
              <img src={indepth} className="img-fluid" width={350} height={250} alt="Best practices in medical billing"/>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div ref={ref3} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5 d-md-none">
            <div className="row">
            <div class="col-md-8" style={{marginTop:'130px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Performance Measurement Reports</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                Compare your practice performance yearly or monthly basis. Find out which provider has an increased performance and revenue volume.
                </p>
              </div>
            <div class="col-md-4 text-center" style={{marginTop:'100px'}}>
              <img src={performence} className="img-fluid" width={350} height={250} alt="Performance Measurement report"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 d-none d-md-block">
            <div className="row">
            <div class="col-md-4 text-center" style={{marginTop:'100px'}}>
              <img src={performence} className="img-fluid" width={350} height={250} alt="Performance Measurement report"/>
              </div>
              <div class="col-md-8" style={{marginTop:'130px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Performance Measurement Reports</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                Compare your practice performance yearly or monthly basis. Find out which provider has an increased performance and revenue volume.
                </p>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div ref={ref2} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Insurance Reimbursements</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                Compare Insurances on how they are paying currently for any given CPT code. At a click of a button, you can determine any underpaying insurances.
                </p>
              </div>
              <div class="col-md-4 text-center ">
              <img src={insurance} className="img-fluid" width={350} height={250} alt="Insurance Reimbursement"/>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div ref={ref1} className="card p-4 mt-4">
      <div className="row p-4">
          <div className="col-lg-12 mb-5 d-md-none">
            <div className="row">
            <div class="col-md-8" style={{marginTop:'50px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Procedure Code Aging</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                Most softwares only provides ability to run aging by insurance. In Acer Smart reports, you can run aging by procedure code. This will provide you an immediate insight on problematic procedure codes.

                </p>
              </div>  
            <div class="col-md-4 text-center ">
              <img src={procedurecode} className="img-fluid" width={350} height={250} alt="Medical billing software solutions"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-5 d-none d-md-block">
            <div className="row">
            <div class="col-md-4 text-center ">
              <img src={procedurecode} className="img-fluid" width={350} height={250} alt="Medical billing software solutions"/>
              </div>
              <div class="col-md-8" style={{marginTop:'50px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Procedure Code Aging</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                Most softwares only provides ability to run aging by insurance. In Acer Smart reports, you can run aging by procedure code. This will provide you an immediate insight on problematic procedure codes.

                </p>
              </div>  
            </div>
          </div>
          </div>
      </div>
      <div ref={ref} className="card p-4 mt-4">
      <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-8" style={{marginTop:'100px'}}>
                <h4 className="title AcerhealthTextPurple  text-center fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>Benchmarking</h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>
                Compare your performance, cash flow information significantly and identify the opportunities for improvement.
                </p>
              </div>
              <div class="col-md-4 text-center ">
              <img src={benchmarking} className="img-fluid" width={350} height={250} alt="Benchmarking"/>
              </div>
            </div>
          </div>
          </div>
      </div>
      

      {/* <div style={{height: '60rem'}} /> */}
      {/* <div ref={ref1} style={{backgroundColor: 'red'}}>
        bobbyhadz.com
      </div> */}
    </div>
    </MDBContainer>
  );
};

export default Home;
