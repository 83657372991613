import React, { useEffect , useState } from 'react';
import {MDBContainer,} from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/img_banner';
import Info from '../components/services_startpractice_info';
import Desc from '../components/services_startpractice_desc';

import DownContact from '../components/downcontact';
import RCMcy from '../img/starting-practice-banner2.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const StartPracticeService = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Setup Medical Practice | AcerHealth';
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Start A Practice');

  // Set Banner Title
   const [BannerTitle, setBannerTitle] = useState({title: "Set Up a Practice", subtitle: "We help Doctors to Set Up their Practice.", img:`url(${RCMcy})`});

   // Set DownContact Heading
   const [DownContactTitle, setDownContactTitle] = useState({title: "We help doctors to setup their practice. Our dedicated team can assist you in several medical processes."});

 
 return (
    <>
     <Helmet>
        <link rel="canonical" href="https://acerhealth.com/services/set-up-practice" />
        <meta name="description" content="Choose our reliable service to boost your medical practice, allowing you to concentrate on caring for your patients. Begin stress-free with us." />
        <meta name="keywords" content="Setup Practice" />
      </Helmet>
        {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
        <Banner BannerTitle ={BannerTitle} />
        <Info />
        <Desc />
        <DownContact DownContactTitle ={DownContactTitle}  />
    </>
  );
};
  
export default StartPracticeService;