import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import Carousel from "react-bootstrap/Carousel";
import testimonial2 from "../img/testimonial images/kochuraani.png";
import testimonial5 from "../img/testimonial images/praveen.png";
import testimonial8 from "../img/testimonial images/zarina.png";
import testimonial7 from "../img/testimonial images/Omair.png";
import "../css/testomonial.css";

export default function App() {
  return (
    <MDBContainer fluid className="p-0" style={{backgroundColor:'#F8F8F8'}}>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="section-title mb-8 testomonial1">
              {/* <div
                className="title mb-4 display-heading AcerhealthTextPurple text-center"
                style={{
                  fontSize: "45px",
                  fontFamily: "EBGarmond semi bold",
                  fontWeight: "bold",
                }}> */}
                <h2 className="title mb-4 display-heading AcerhealthTextPurple text-center testomonial"
                style={{
                  fontFamily:'Lora, serif',
                  fontWeight: "bold",
                }}>Our Clients Tell Us What They Think</h2>
                
              {/* </div> */}
              <p
                className="para-desc mx-auto mb-0 display-9 justify-content-left testomonial1"
                style={{
                  fontWeight: "normal",
                  fontFamily: "Alegreya Sans",fontWeight:'500',
                }}
              >
               The following feedback has been provided by a few of our most
                cherished clients. Thanks for the kind words about our services,
                and we are proud to have helped them achieve their business
                goals.
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center row">
          <div className="col-md-8">
            <Carousel interval={8000} pause={false} indicators={false}>
              <Carousel.Item>
                <div className="row" style={{backgroundColor:'#E6E6FA'}}>
                  <div className="col-md-4 p-0 d-flex">
                    <img
                      className="img-fluid profile"
                      src={testimonial8}
                      alt="zarina" style={{ width: '350px', height: '350px' }}
                    />
                  </div>
                  <div className="col-md-8 p-4 d-flex flex-column" style={{backgroundColor:'#E6E6FA'}}>
                    <div className="my-auto">
                      <p
                        className="justify AcerhealthTextPurple h5"
                        style={{
                          fontFamily: "Alegreya Sans",
                          fontWeight: "bold",
                        }}
                      >
                        Zarina, Executive Director
                      </p>
                      <p
                        className="justify AcerhealthTextPurple h6"
                        style={{
                          fontFamily: "Alegreya Sans",
                          fontWeight: "bold",
                        }}
                      >
                        Premier Dermatology Ashburn, VA
                      </p>
                      <p className="justify-content-left testomonial2" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>
                      Our collaboration with AcerHealth is a source of satisfaction due to their attentive approach. When I communicate our specific needs and requirements, I am confident that they genuinely listen and take action accordingly. AcerHealth consistently demonstrates a high level of flexibility and a genuine commitment to ensuring that our expectations are met. Their customer service is characterized by a tangible dedication to meeting our needs, not just verbal assurances.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="row" style={{backgroundColor:'#E6E6FA'}}>
                  <div className="col-md-4 p-0 d-flex">
                    <img
                      className="img-fluid profile"
                      src={testimonial2}
                      alt="kochurani" style={{ width: '350px', height: '350px'}}
                    />
                  </div>
                  <div className="col-md-8 p-4 d-flex flex-column" style={{backgroundColor:'#E6E6FA'}}>
                    <div className="my-auto">
                      <p
                        className="justify AcerhealthTextPurple h5"
                        style={{
                          fontFamily: "Alegreya Sans",
                          fontWeight: "bold",
                        }}
                      >
                        Kochurani Puthumana, MD
                      </p>
                      <p
                        className="justify AcerhealthTextPurple h6"
                        style={{
                          fontFamily: "Alegreya Sans",
                          fontWeight: "bold",
                        }}
                      >
                        Colonial Heights, VA
                      </p>
                      <p className="justify-content-left testomonial2" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>
                        As a doctor in these times, I understand the importance
                        of dependable service. Acerhealth has helped me un-focus
                        from the billing side of my practice and focus on taking
                        care of my patients. Since we started with Acer, our
                        collections have increased by 30% without adding any
                        additional providers or services to our practice.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="row" style={{backgroundColor:'#E6E6FA'}}>
                  <div className="col-md-4 p-0 d-flex">
                    <img
                      className="img-fluid profile"
                      src={testimonial7}
                      alt="omair" style={{ width: '350px', height: '350px' }}
                    />
                  </div>
                  <div className="col-md-8 p-4 d-flex flex-column" style={{backgroundColor:'#E6E6FA'}}>
                    <div className="my-auto">
                      <p
                        className="justify AcerhealthTextPurple h5"
                        style={{
                          fontFamily: "Alegreya Sans",
                          fontWeight: "bold",
                        }}
                      >
                        Omair Siraj, CEO
                      </p>
                      <p
                        className="justify AcerhealthTextPurple h6"
                        style={{
                          fontFamily: "Alegreya Sans",
                          fontWeight: "bold",
                        }}
                      >
                        Chestnut Health, IL
                      </p>
                      <p className="justify-content-left testomonial2" style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}>
                      We requested comments on our AR and ideas for enhancements. Our practice's financial and operational health was thoroughly assessed by Acer. We asked for criticism on our AR as well as ideas for enhancement. Information about our assertion was given to AcerHealth.
                      AcerHealth provided us with a comprehensive analysis of our financial and operational health. Acer's online reporting platform has an easy-to-use user interface and helpful dashboards. As a result of this study, we are able to identify and isolate problematic areas and operational silos in order to increase efficiency and maximize revenue. We are using this data to identify and unproductive services in order to increase productivity and revenue. 
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
}
