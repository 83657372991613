import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBNavbarLink,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import Specialities from "../img/specialities/cardiology.jpg";
import Pediatrics from "../img/specialities/pediatrics.jpg";
import Neurology from "../img/specialities/neurology.jpg";
import Dermatology from "../img/specialities/dermatology.jpg";
import InternalMedicine from "../img/specialities/internal medicine.jpg";
// import psychology from "../img/specialities/psychology.jpg";
import ContactModal from "../modals/modal_contact";
// import ContactModal1 from "../modals/modal_contact";
import plasticsurgery from "../img/specialities/plastic surgery.jpg";
import surgery from "../img/specialities/general surgery.jpg";
import rheumtology from "../img/specialities/rheumatology.jpg";
import infectious from "../img/specialities/infectious disease.jpg";
import gynacology from "../img/specialities/gynaecology.jpg";
import Urology from "../img/specialities/urology.jpg";
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const Medicalbillingsys = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const handleCloseModal = () => {
    setBasicModal(false);
  };

  const [basicModal1, setBasicModal1] = useState(false);
  const toggleShow1 = () => setBasicModal1(!basicModal1);
  const handleCloseModal1 = () => {
    setBasicModal1(false);
  };

  const [basicModal2, setBasicModal2] = useState(false);
  const toggleShow2 = () => setBasicModal2(!basicModal2);
  const handleCloseModal2 = () => {
    setBasicModal2(false);
  };

  const [basicModal3, setBasicModal3] = useState(false);
  const toggleShow3 = () => setBasicModal3(!basicModal3);
  const handleCloseModal3 = () => {
    setBasicModal3(false);
  };

  const [basicModal4, setBasicModal4] = useState(false);
  const toggleShow4 = () => setBasicModal4(!basicModal4);
  const handleCloseModal4 = () => {
    setBasicModal4(false);
  };

  const [basicModal5, setBasicModal5] = useState(false);
  const toggleShow5 = () => setBasicModal5(!basicModal5);
  const handleCloseModal5 = () => {
    setBasicModal5(false);
  };
  const [basicModal6, setBasicModal6] = useState(false);
  const toggleShow6 = () => setBasicModal6(!basicModal6);
  const handleCloseModal6 = () => {
    setBasicModal6(false);
  };
  const [basicModal7, setBasicModal7] = useState(false);
  const toggleShow7 = () => setBasicModal7(!basicModal7);
  const handleCloseModal7 = () => {
    setBasicModal7(false);
  };
  const [basicModal8, setBasicModal8] = useState(false);
  const toggleShow8 = () => setBasicModal8(!basicModal8);
  const handleCloseModal8 = () => {
    setBasicModal8(false);
  };
  const [basicModal9, setBasicModal9] = useState(false);
  const toggleShow9 = () => setBasicModal9(!basicModal9);
  const handleCloseModal9 = () => {
    setBasicModal5(false);
  };
  const [basicModal10, setBasicModal10] = useState(false);
  const toggleShow10 = () => setBasicModal10(!basicModal10);
  const handleCloseModal10 = () => {
    setBasicModal10(false);
  };
  const [basicModal11, setBasicModal11] = useState(false);
  const toggleShow11 = () => setBasicModal11(!basicModal11);
  const handleCloseModal11 = () => {
    setBasicModal11(false);
  };
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12 mb-5 p-4">
            <div className="row">
              <div class="col-md-4 text-center mt-4">
                <img
                  src={Specialities}
                  className="img-fluid"
                  alt="Specialities"
                  width={350}
                  height={250}
                />
              </div>
              <div class="col-md-8 " style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                 Cardiology and Interventional Cardiology
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  AcerHealth provides comprehensive cardiology billing services to practices of various sizes. 
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow2}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal2}
                    onClose={handleCloseModal2}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-8" style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Pediatrics
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  Join AcerHealth Pediatric Billing to ensure that you are aware about compliance with pediatric billing standards.
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow1}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal1}
                    onClose={handleCloseModal1}
                  />
                </span>
              </div>
              <div class="col-md-4 text-center ">
                <img
                  src={Pediatrics}
                  alt="Pediatrics"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-5 p-4">
            <div className="row">
              <div class="col-md-4 text-center mt-4">
                <img
                  src={Neurology}
                  alt="Neurology"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
              <div class="col-md-8 " style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                 Psychiatry
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  Our billing processes is meant to increase revenue and streamline your operations so you can focus on developing improving patient care.
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow2}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal2}
                    onClose={handleCloseModal2}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

         <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-8" style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Dermatology
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  You may efficiently maximize your practice financial potential with AcerHealth while streamlining your operations
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow3}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal3}
                    onClose={handleCloseModal3}
                  />
                </span>
              </div>
              <div class="col-md-4 text-center ">
                <img
                  src={Dermatology}
                  alt="Dermatology"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
            </div>
          </div>
        </div>
       <div className="row">
          <div className="col-lg-12 mb-5 p-4">
            <div className="row">
              <div class="col-md-4 text-center mt-4">
                <img
                  src={InternalMedicine}
                  alt="InternalMedicine"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
              <div class="col-md-8 " style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Internal Medicine
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  Improve your billing process's efficiency for better output and revenue. AcerHealth's internal medicine billing services keep your medical practice operating smoothly.
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow4}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal4}
                    onClose={handleCloseModal4}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-5 p-4">
            <div className="row">
              <div class="col-md-8 " style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Plastic Surgery
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  Plastic surgery typically necessitates thorough documentation of treatments and procedures and getting preauthorization from insurances. AcerHealth aids in ensuring getting appropriate pre authorization and attending to frequent request for medical records from insurances, thus ensuring a seamless cash flow to your practice.
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow6}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal6}
                    onClose={handleCloseModal6}
                  />
                </span>
              </div>
              <div class="col-md-4 text-center mt-4">
                <img
                  src={plasticsurgery}
                  alt="plasticsurgery"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
            <div class="col-md-4 text-center ">
                <img
                  src={surgery}
                  alt="surgery"
                  className="img-fluid"
                  width={350}
                  height={500}
                />
              </div>
              <div class="col-md-8" style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Surgery
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  AcerHealth enhances the efficiency of surgery billing processes, alleviating the administrative load on healthcare providers. This empowers doctors to prioritize patient care.
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow7}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal7}
                    onClose={handleCloseModal7}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-5 p-4">
            <div className="row">
              <div class="col-md-8 " style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Rheumatology
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  Rhuematology typically necessitates thorough documentation of treatments and procedures and getting preauthorization from insurances. AcerHealth aids in ensuring getting appropriate pre authorization and attending to frequent request for medical records from insurances, thus ensuring a seamless cash flow to your practice.
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow8}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal8}
                    onClose={handleCloseModal8}
                  />
                </span>
              </div>
              <div class="col-md-4 text-center mt-4">
                <img
                  src={rheumtology}
                  alt="rheumtology"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
            <div class="col-md-4 text-center ">
                <img
                  src={infectious}
                  alt="infectious"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
              <div class="col-md-8" style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Infection Diseases
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  AcerHealth offers assistance to both patients and doctors engaged in the billing process for Infectious Diseases.
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow9}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal9}
                    onClose={handleCloseModal9}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-5 p-4">
            <div className="row">
              <div class="col-md-8 " style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Gynecology
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                  AcerHealth's medical billing services in gynecology prioritize efficient coding and billing processes, aiming to provide clients and practices with a seamless experience.
                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow10}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal10}
                    onClose={handleCloseModal10}
                  />
                </span>
              </div>
              <div class="col-md-4 text-center mt-4">
                <img
                  src={gynacology}
                  alt="gynacology"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
            </div>
          </div>
        </div>
       <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
            <div class="col-md-4 text-center ">
                <img
                  src={Urology}
                  alt="Urology"
                  className="img-fluid"
                  width={350}
                  height={250}
                />
              </div>
              <div class="col-md-8" style={{ marginTop: "20px" }}>
                <h4
                  className="title AcerhealthTextPurple text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "Alegreya Sans",
                    fontWeight: "bold",
                  }}
                >
                  Urology
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p
                  className="mt-3 mb-0 justify-content-left"
                  style={{fontFamily: "Alegreya Sans",fontWeight:'500',fontSize:'18px'}}
                >
                 
                 AcerHealth empowers practices to optimize their financial potential by streamlining urology billing processes, and thereby driving practice revenue growth.

                </p>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <MDBBtn
                    onClick={toggleShow11}
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      background: "#582C83",
                      fontWeight: "bold",
                    }}
                  >
                    Connect with our experts
                  </MDBBtn>
                  <ContactModal
                    show={basicModal11}
                    onClose={handleCloseModal11}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </MDBContainer>
  );
};
export default Medicalbillingsys;
