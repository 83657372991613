import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import Imageabout from "../img/rcm2.gif";
import { BrowserRouter, Link } from "react-router-dom";
import "../css/servicesfont.css";
function App() {
  return (
    <MDBContainer fluid className="p-0">
    <div className="container py-5">
        <div className="row">
          <div className="col-12">
              <div className="section-title mb-4 pb-2">
                  <div className="title mb-4 display-heading AcerhealthTextPurple text-center fomtsizetest" style={{fontFamily:'Lora, serif'}}> Revenue Cycle Management </div>
                  <p className="para-desc mx-auto mb-0" style={{fontSize:'20px',fontFamily: 'Alegreya Sans',fontWeight:'500' }}>Increasing sales revenue and reducing the amount of time it takes to complete reimbursements are the two ways that a company can become more lucrative. AcerHealth offer our practical knowledge in revenue cycle management to help your entire business improve performance and increase its financial health and profitability, whether it be claims processing, streamlining operations in surgery centers, or generating revenue for solo experts.</p>
              </div>
          </div>
      </div>
    </div>    
    </MDBContainer>
  );
}

export default App;
