import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import contactcomprahence from '../img/contractandcomp/credential services.jpg'
import globalcontracts from '../img/contractandcomp/global contracts.jpg';
import statewisecomploance from '../img/contractandcomp/statewise compliance.jpg';
import databasemaintain from '../img/contractandcomp/CAQH database maintenance.jpg'
import independentaudit from '../img/Conseltancyservices/internal audit consultancy.jpg'; 
import "../css/servicesfont.css";
export default function CredentialingDesc() {
  return (
    <MDBContainer fluid className="p-0 bg-light">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 mb-2 text-center ">
                <img src={contactcomprahence} width={350} height={250} alt="Contractor compliance requirements"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Credential Services
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
                From submitting application forms to set up contracts to insurances, our skilled and dedicated team can assist you to get credentialed with insurances. Behalf of you we identify location-based high carriers and also the sort of services you provide. This ensures predictability and gains from day one.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 mb-2 text-center ">
                <img src={globalcontracts} width={350} height={250} alt="global contract with TPAs"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Global Contracts with TPAs
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                We coordinate and negotiate with Third Party administrators on setting international contracts for services provided. Establishing international contracts lead to higher reimbursement rates and provide flexibility with payment patterns.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 mb-2 text-center p-0">
                <img src={statewisecomploance} width={350} height={250} alt="Statewise compliance"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  State-wise Compliance
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Reporting service quality data to state health registries is a measure for centers to demonstrate quality and it's a mandate in some states. For example, in New York State, it's obligatory for patient surgery centers to submit quality information to SPARCS and HCRA reportage systems. We have a machine-driven process for gathering, consolidating and submitting information in SPARCS compliant format for centers and that we facilitate ASCs to report the HCRA element to any state. We will work together with your center to grasp your state’s quality requirements and do it on your behalf, thus you can concentrate on your patients.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 mb-2 text-center ">
                <img src={databasemaintain} width={350} height={250} alt="CAQH Database maintenance"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  CAQH Database Maintenance
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500'}}>
                Physicians need to re-attest and frequently update their CAQH profile every 3 months. We review, send reminders and maintain the info on your behalf whenever needed.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-5">
            <div className="row">
              <div class="col-md-4 mb-2 text-center ">
                <img src={independentaudit} width={350} height={250} alt="Internal Audit"/>
              </div>
              <div class="col-md-8">
                <h4 className="title AcerhealthTextPurple fontsizeset2" style={{fontFamily:'Alegreya Sans',fontWeight:'bold'}}>
                  Internal Audits
                </h4>
                <hr style={{ border: "2px Solid #582C83" }}></hr>
                <p className="mt-3 mb-0 justify-content-left" style={{fontSize:'18px',fontFamily: "Alegreya Sans",fontWeight:'500' }}>
                A small practice is often a busy place, and frequently there simply aren’t enough hours in the day or weekend to attend to the task of a medical charge audit. To beat this downside we operate with all sizes of healthcare practices like undertaking internal audits and developing customized quality protocols. Just in case you’re audited, we tend to aid you through the audit method and assist you to meet regulative needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
}
