import React, { useEffect, useState } from 'react';
import { MDBContainer, } from 'mdb-react-ui-kit';
import BreadCrumbs from '../components/breadcrumbs';
import Banner from '../components/banner';
import DownContact from '../components/downcontact';
import Preauthorization from '../components/tech_preauthorization_info';
import Info from '../components/tectpreautho';
import RCMcy from '../img/technologies/patienteligiblity.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-7WQFXCQFVP');

const RCM = () => {
  useEffect(() => {
    // change title of page
    document.title = 'Patient Eligibility Verification Benefits | AcerHealth';

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7WQFXCQFVP';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-7WQFXCQFVP');
  }, []);

  // Set breadcrumb
  const [Breadcrumb, setBreadcrumb] = useState('Pre Authorization');

  // Set Banner Title
  const [BannerTitle, setBannerTitle] = useState({ title: "AcerHealth Medical billing services across America", subtitle: "Maximize Your Revenue while Minimizing  the billing Woes ,No Matter Where You Are with AcerHealth Preferred Medical Billing Services", img: `url(${RCMcy})` });

  // Set DownContact Heading
  const [DownContactTitle, setDownContactTitle] = useState({ title: "WE HELP DOCTORS COLLECT MORE MONEY FASTER AND EASIER", subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." });


  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://acerhealth.com/technologies/patient-eligibility-verification" />
        <meta name="description" content="Simplify your claim process with AcerHealth right now. We handle patient eligibility, pre-authorization, and financial verification promptly." />
        <meta name="keywords" content="Patient Eligibility Verification " />
      </Helmet>
      {/* <BreadCrumbs Breadcrumb ={Breadcrumb} /> */}
      <Banner BannerTitle={BannerTitle} />
      <Info />
      <Preauthorization />
      {/* <DownContact DownContactTitle = {DownContactTitle}  /> */}
    </>
  );
};

export default RCM;