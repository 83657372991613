import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBCheckbox,
  MDBRadio,
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt} from "react-icons/fa";
import { useState,useEffect } from "react";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Space, TimePicker } from "antd";
import axios from "axios";

const FreeConsultancy = ({ show, onClose }) => {
  const backcolor = {
    color: "Black",
  };
  // const [startDate, setStartDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [checkedValue, setCheckedValue] = useState("");
  const [Selectedfirstname, setSelectedfirstname] = useState("");
  const [selectedemail, setselectedemail] = useState("");
  const [selectedpracticename, setselectedpracticename] = useState("");
  const [selectedphonenumber, setselectedphonenumber] = useState("");
  const [selectedcity, setselectedcity] = useState("");
  const [selectedzipcode, setselectedzipcode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defaultTime, setDefaultTime] = useState(null);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const usPhoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
  };
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDatePickerBlur = () => {
    setShowDatePicker(false);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleCheckboxChange = (event) => {
    setCheckedValue(event.target.value);
  };

  const handlefirstnamechange = (event) => {
    setSelectedfirstname(event.target.value);
  };

  const handleemail = (event) => {
    setselectedemail(event.target.value);
  };

  const handlepracticename = (event) => {
    setselectedpracticename(event.target.value);
  };

  const handlephonenumber = (event) => {
    setselectedphonenumber(event.target.value);
  };

  const handlecity = (event) => {
    setselectedcity(event.target.value);
  };

  const handlezipcode = (event) => {
    setselectedzipcode(event.target.value);
  };

  const [errors, setErrors] = useState({
    firstname: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    practice: '',
    city: '',
    zipcode: '',
    provider: '',


  });

  const clearSuccessMessage = () => {
    setSuccessMessage("");
  };
  
  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };
  
    if (!Selectedfirstname.trim()) {
      newErrors.firstname = 'Name is required';
      valid = false;
    } else {
      newErrors.firstname = '';
    }
    if (!selectedemail.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!emailRegex.test(selectedemail)) {
      newErrors.email = 'Invalid email address';
      valid = false;
    } else {
      newErrors.email = '';
    }
    if (!selectedphonenumber) {
      newErrors.phone = 'Phone number is required';
      valid = false;
    } else if (!usPhoneRegex.test(selectedphonenumber)) {
      newErrors.phone = 'Invalid US phone number';
      valid = false;
    } else {
      newErrors.phone = '';
    }
  
    if (!selectedDate) {
      newErrors.date = 'Date is required';
      valid = false;
    } else {
      newErrors.date = '';
    }
    if (!selectedTime) {
      newErrors.time = 'Time is required';
      valid = false;
    } else {
      newErrors.time = '';
    }
    if (!selectedpracticename.trim()) {
      newErrors.practice = 'practiceName is required';
      valid = false;
    } else {
      newErrors.practice = '';
    }
    if (!selectedcity.trim()) {
      newErrors.city = 'City is required';
      valid = false;
    } else {
      newErrors.city = '';
    }
    if (!selectedzipcode.trim()) {
      newErrors.zipcode = 'Zipcode is required';
      valid = false;
    } else {
      newErrors.zipcode = '';
    }
    if (!checkedValue.trim()) {
      newErrors.provider = 'Providers is required';
      valid = false;
    } else {
      newErrors.provider = '';
    }
  
    // You can add more validation rules for email, phone, etc.
  
    setErrors(newErrors);
    return valid;
  };
  const handleInsertion = () => {
    if (validateForm()) {
      setIsLoading(true);
    const formData = new FormData();
    // Add other form fields to formData
    formData.append("name", Selectedfirstname);
    formData.append("email", selectedemail);
    formData.append("practiceName", selectedpracticename);
    formData.append("phone", selectedphonenumber);
    formData.append("city", selectedcity);
    formData.append("zipcode", selectedzipcode);
    formData.append("noofprovider", checkedValue);
    formData.append("Meetingtime", selectedTime);
    formData.append("Meetingtdate", selectedDate);

    console.log(formData);
    // Make a POST request to your PHP insertion API
    axios
      .post("https://mdbill.io/websiteemail/testfile.php", formData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        setSelectedDate(null);
        setSelectedTime(null);
        setCheckedValue('');
        setSelectedfirstname('');
        setselectedemail('');
        setselectedpracticename('');
        setselectedphonenumber('');
        setselectedcity('');
        setselectedzipcode('');
        setSuccessMessage("Form submitted successfully");
        setTimeout(clearSuccessMessage, 3000);
        setIsLoading(false);
        console.error(error);
      });
    }
};
return (
      <MDBModal tabIndex="-1" show={show} onHide={onClose}>
<MDBModalDialog style={{ maxWidth: '900px' }}>
  <MDBModalContent>
    <MDBModalBody>
      {/* Close button */}
      <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
              style={{color:'red'}}
            />
    <MDBCard>
            <MDBCardBody>
              <MDBCardTitle className="AcerhealthTextPurple text-center mb-3" style={{fontFamily: "Alegreya Sans",fontWeight:'bold',fontSize:'22px' }}>
              Is Your Practice Struggling with Cash Flow – Connect with Us for
                Free Consultation
          </MDBCardTitle>
              <br></br>
                  <MDBRow>
                  <MDBCol size="md" className="mb-4">
                    {errors.date && <div className="error-message"style={{color:'red'}}>{errors.date}</div>}
                      <div className="input-group">
                        <DatePicker
                          className="form-control"
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="MMMM d, yyyy"
                          open={showDatePicker}
                          onBlur={handleDatePickerBlur}
                          readOnly
                          onClickOutside={handleDatePickerBlur}
                          placeholderText="Select Date"
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={toggleDatePicker}
                          >
                            <FaCalendarAlt />
                          </span>
                        </div>
                      </div>
                    </MDBCol>
                    <MDBCol size="md" className="mb-3">
                    {errors.time && <div className="error-message"style={{color:'red'}}>{errors.time}</div>}
                      <div className="input-group">
                      <TimePicker
                          use12Hours
                          format="h:mm A"
                          style={{ width: "250px", height: "35px", border: "1px solid #B0B0B0" }} 
                          popupStyle={{ zIndex: 10000 }}
                          onChange={handleTimeChange}
                          value={selectedTime || defaultTime} // Set the value of TimePicker
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                <MDBCol></MDBCol>
              <MDBRow>
              <MDBCol size="md" className="mb-3">
                {errors.firstname && <div className="error-message"style={{color:'red'}}>{errors.firstname}</div>}
                  <MDBInput label="Your Name" id="form1" type="text" name="name" onChange={handlefirstnamechange} value={Selectedfirstname} autocomplete="off"/>
                </MDBCol>
                <MDBCol size="md" className="mb-3">
                {errors.email && <div className="error-message"style={{color:'red'}}>{errors.email}</div>}
                  <MDBInput label="Your Email ID" id="form1" type="text" name="email" onChange={handleemail} value={selectedemail} autocomplete="off"/>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="md" className="mb-3">
                {errors.practice && <div className="error-message"style={{color:'red'}}>{errors.practice}</div>}
                  <MDBInput label="Practice Name" id="form1" type="text"  name="practicename" onChange={handlepracticename} value={selectedpracticename} autocomplete="off"/>
                </MDBCol>
                <MDBCol size="md" className="mb-3">
                {errors.phone && <div className="error-message"style={{color:'red'}}>{errors.phone}</div>}
                  <MDBInput label="Phone No." id="form1" type="text"  name="phonenumber" onChange={handlephonenumber} value={selectedphonenumber} autocomplete="off"/>
                </MDBCol>
              </MDBRow>
              <MDBRow>
              <MDBCol size="md" className="mb-3">
                {errors.city && <div className="error-message"style={{color:'red'}}>{errors.city}</div>}
                  <MDBInput label="City" id="form1" type="text" name="city" onChange={handlecity} value={selectedcity} autocomplete="off"/>
                </MDBCol>
                <MDBCol size="md" className="mb-3">
                {errors.zipcode && <div className="error-message"style={{color:'red'}}>{errors.zipcode}</div>}
                  <MDBInput label="Zipcode" id="form1" type="text"  name="zipcode" onChange={handlezipcode} value={selectedzipcode} autocomplete="off"/>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBRow className="container-fluid w-100 mb-3 p-0">
            <MDBCol>
            <span className="mr-2 pr-3 text-muted">No of Provider(s) : </span>
            {errors.provider && <div className="error-message"style={{color:'red'}}>{errors.provider}</div>}
            <span className="text-muted">1-5</span> &nbsp;
              <MDBRadio
                name="inlineCheck"
                id="inlineCheckbox1"
                value="1-5"
                inline
                checked={checkedValue == "1-5"}
                onChange={handleCheckboxChange}
              />
               <span className="text-muted">6-10</span> &nbsp;
              <MDBRadio
                name="inlineCheck"
                id="inlineCheckbox2"
                value="6-10"
                inline
                checked={checkedValue == "6-10"}
                onChange={handleCheckboxChange}
              />
              <span className="text-muted">10+</span> &nbsp;
              <MDBRadio
                name="inlineCheck"
                id="inlineCheckbox4"
                value="10+"
                label="10+"
                inline
                checked={checkedValue == "10+"}
                onChange={handleCheckboxChange}
              />
              </MDBCol>
          </MDBRow>
              </MDBRow>
              <MDBRow>
                <MDBCol></MDBCol>
              </MDBRow>
              <br></br>
              <MDBRow className="justify-content-center">
              <MDBBtn
                      type="submit"
                      className="mb-4 d-grid gap-2 col-5 mx-auto"
                      style={{ backgroundColor: "#3B0075" }}
                      onClick={handleInsertion}
                    >
                   {isLoading ? (
              <div className="d-flex align-items-center">
                <strong>Sending...</strong>
                <MDBSpinner className="ms-auto" size='sm' role="status" />
              </div>
            ) : (
              "Submit"
            )}
                    </MDBBtn>
              </MDBRow>

              <br></br>
              {/* <MDBRow>
            
            </MDBRow> */}
              {/* </MDBCol> */}
            </MDBCardBody>
            {successMessage && (
  <div className="alert alert-success">{successMessage}</div>
)}
          </MDBCard>
          
</MDBModalBody>
</MDBModalContent>
</MDBModalDialog>
</MDBModal>
        );
};

 export default FreeConsultancy;


